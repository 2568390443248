import { useMemo } from "react"
import flags from "dict/flags.json"
import countries from "dict/countries.json"

export function getCountryNameWithFlag(country) {
    if (!country) {
        return null;
    }
    const code = typeof country === "string" ? country.toUpperCase() : country?.code.toUpperCase();
    const name = typeof country === "string" ?
        countries.find(c => c.code === code)?.name :
        country?.name;
    const flag = flags.find(f => f.code === code)?.emoji;
    return flag ? `${flag} ${name}` : name;
}

function CountryName({ country }) {

    const result = useMemo(
        () => getCountryNameWithFlag(country),
        [country]
    )

    return result;
}

export default CountryName