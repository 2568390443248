

const periodOptions = [
    {
        value: "last7",
        longLabel: "Last 7 days",
        label: "7d"
    },
    {
        value: "last30",
        longLabel: "Last 30 days",
        label: "30d"
    },
    {
        value: "thisMonth",
        longLabel: "This month",
        label: "M"
    },
    {
        value: "lastMonth",
        longLabel: "Last month",
        label: "LM"
    }
];

export const periodOptionsWithAll = [
    {
        value: "",
        longLabel: "All time",
        label: "All"
    },
    ...periodOptions
]

export default periodOptions