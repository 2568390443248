import { Input, Modal } from "antd"
import { useCallback, useMemo, useState } from "react"
import supabase from "lib/supabase/main"
import useUpdateEffect from "hooks/useUpdateEffect"

function EditDescriptionDialog({ open = false, onCancel, onSuccess, item }) {

    const [description, setDescription] = useState(item?.description || "");
    const [submiting, setSubmiting] = useState(false);

    const onInputChange = useCallback(
        (e) => setDescription(e.target.value),
        []
    );

    const onSubmit = useCallback(
        async () => {
            setSubmiting(true);
            const table = item.ip ?
                "watchlist_ip" :
                item.query ?
                    "watchlist_tarpit_query" :
                    "watchlist_cidr";
            await supabase.from(table)
                .update({ description })
                .eq("id", item.id);
            setSubmiting(false);
            onSuccess();
        },
        [onSuccess, item, description]
    );

    const okButtonProps = useMemo(
        () => ({
            disabled: submiting,
            loading: submiting
        }),
        [submiting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submiting
        }),
        [submiting]
    );

    useUpdateEffect(
        () => setDescription(item?.description || ""),
        [item]
    );

    return (
        <Modal
            open={open}
            title="Edit description"
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}
            onCancel={onCancel}
            onOk={onSubmit}>
            <Input
                disabled={submiting}
                onChange={onInputChange}
                onPressEnter={onSubmit}
                value={description} />
        </Modal>
    )
}

export default EditDescriptionDialog