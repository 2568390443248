import {
    BellOutlined, CheckCircleOutlined,
    SyncOutlined, ExclamationCircleFilled,
    SafetyOutlined, StopOutlined, GlobalOutlined
} from "@ant-design/icons"
import moment from "moment"
import { Badge, Flex, Popover } from "antd"
import { useCallback, useContext, useMemo, useState } from "react"
import { Link } from "react-router-dom"

import AppContext from "context/AppContext"

import useKeys from "hooks/useKeys"
import useSettings from "hooks/useSettings"
import supabase from "lib/supabase/main"
import app from "appContext"
import useQuery from "hooks/useQuery"
import { getOptionName } from "components/selector/TakedownStatus"
import renderGMT from "lib/renderGMT"

async function loadUserNotifications() {
    const currentUserId = app.get("userId");
    const graph = `
        id,
        type,
        created_at,
        message
    `;
    return supabase.from("user_notification")
        .select(graph)
        .eq("active", true)
        .eq("user_id", currentUserId)
        .order('created_at', { ascending: false });
}

async function loadWatchlistNotifications() {
    const currentUserId = app.get("userId");
    const graph = `
        id,
        created_at,
        watchlist_id,
        watchlist(id, name),
        watchlist_cidr(cidr),
        watchlist_ip(ip),
        watchlist_domain(domain),
        watchlist_company(
            entity(name)
        )
    `;
    return supabase.from("watchlist_notification")
        .select(graph)
        .eq("active", true)
        .eq("disabled", false)
        .eq("user_id", currentUserId);
}

function prepareUserNotification(un) {

    const { id, type, created_at } = un;
    let icon = null, title = "", description = "", link = null;
    const date = created_at ? renderGMT(created_at) : null;

    switch (type) {
        case "similar_domain_takedown_requested": {
            icon = <StopOutlined />;
            title = "Potential phishing domain takedown requested";
            description = `${un.message.domain}: ${un.message.similar_domain}`;
            link = `/company/${un.message.company_id}`;
            break;
        }
        case "similar_domain_takedown_status_change": {
            const statusName = getOptionName(un.message.status);
            icon = <StopOutlined />;
            title = "Potential phishing domain takedown status change";
            description = `${un.message.domain}: ${un.message.similar_domain}: ${statusName}`;
            link = `/company/${un.message.company_id}`;
            break;
        }
        case "domain_expired": {
            icon = <GlobalOutlined />;
            title = "Domain expired";
            description = un.message.domain;
            link = "/hunt/expiring?expiration=expired";
            break;
        }
        default: {
            return null;
        }
    }

    return {
        key: id,
        id,
        type,
        icon,
        title,
        description,
        link,
        date
    }
}

function NotificationItem({ id, type, icon, title, description, link, date, onRead }) {

    const [dismissing, setDismissing] = useState(false);
    const onReadClick = useCallback(
        async (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDismissing(true);
            await onRead(id);
            setDismissing(false);
        },
        [onRead, id]
    );

    const cls = useMemo(
        () => ["notification-item", `notification-item-${type}`].join(" "),
        [type]
    );

    return (
        <Flex
            vertical={false}
            gap="1rem"
            align="center"
            className={cls}>
            <div className="notification-icon">
                {icon}
            </div>
            <div className="notification-info">
                <p>{link ? <Link to={link} children={title} /> : title}</p>
                {date && <p>{date}</p>}
                <p>{description}</p>
            </div>
            <div className="notification-state">
                <a href="/#" onClick={onReadClick}>
                    {dismissing ?
                        <SyncOutlined spin /> :
                        <CheckCircleOutlined />}
                </a>
            </div>
        </Flex>
    )
}

export function NotificationsDialog() {
    const app = useContext(AppContext);

    const { updateSettings } = useSettings();
    const { userSettings,
        watchlistNotificationsCount,
        userNotificationsCount } = useKeys(
            ["userSettings",
                "watchlistNotificationsCount",
                "userNotificationsCount"],
            app
        );
    const huntReportCount = userSettings?.registrar_hunt_counter || 0;

    const resetHuntReport = useCallback(
        async () => {
            await updateSettings({
                registrar_hunt_since_gmt: moment().utc().toISOString(),
                registrar_hunt_counter: 0
            });
        },
        [updateSettings]
    );

    const { data: userNotifications,
        refetch: refetchUserNotifications,
        isLoading: userNotificationsLoading } = useQuery(
            loadUserNotifications,
            [userNotificationsCount],
            {
                map: prepareUserNotification
            }
        );

    const { data: watchlistNotifications,
        refetch: refetchWatchlists,
        isLoading: watchlistsLoading } = useQuery(
            loadWatchlistNotifications,
            [watchlistNotificationsCount],
            {
                prepare: rows => {
                    const grouped = {};
                    rows.forEach(row => {
                        const wid = row.watchlist_id;
                        if (!grouped[wid]) {
                            grouped[wid] = {
                                id: row.watchlist_id,
                                name: row.watchlist.name,
                                created_at: row.created_at,
                                ip: [],
                                cidr: [],
                                domain: [],
                                company: []
                            };
                        }
                        if (row.watchlist_ip) {
                            grouped[row.watchlist_id].ip.push(row.watchlist_ip.ip);
                        }
                        if (row.watchlist_cidr) {
                            grouped[row.watchlist_id].cidr.push(row.watchlist_cidr.cidr);
                        }
                        if (row.watchlist_domain) {
                            grouped[row.watchlist_id].domain.push(row.watchlist_domain.domain);
                        }
                        if (row.watchlist_company) {
                            grouped[row.watchlist_id].company.push(
                                row.watchlist_company.entity.name
                            );
                        }
                    });

                    return Object.values(grouped);
                }
            }
        );

    const resetWatchlistNotification = useCallback(
        async (wid) => {
            await supabase
                .from("watchlist_notification")
                .update({ active: false })
                .eq("watchlist_id", wid);
            await refetchWatchlists();
        },
        [refetchWatchlists]
    );

    const resetUserNotification = useCallback(
        async (id) => {
            await supabase
                .from("user_notification")
                .update({ active: false })
                .eq("id", id);
            await refetchUserNotifications();
        },
        [refetchUserNotifications]
    );

    const notifications = useMemo(
        () => {
            const ns = [];

            if (huntReportCount > 0) {
                ns.push({
                    key: "hunt-preview",
                    type: "hunt-preview",
                    icon: <SafetyOutlined />,
                    title: "Automated Hunt Preview",
                    description: huntReportCount === 1 ?
                        `1 new item in hunt preview` :
                        `${huntReportCount} new items in hunt preview`,
                    onRead: resetHuntReport,
                    link: `/hunt/preview`
                });
            }

            watchlistNotifications.forEach(
                w => {
                    let matchesStr = "";
                    const matches = [w.ip, w.cidr, w.domain, w.company].flat();
                    if (matches.length > 5) {
                        const more = matches.length - 5;
                        matchesStr = matches.slice(0, 5).join(", ") + ` (+${more} more)`
                    }
                    else {
                        matchesStr = matches.join(", ");
                    }
                    ns.push({
                        key: w.id,
                        id: w.id,
                        date: renderGMT(w.created_at),
                        type: "watchlist",
                        icon: <ExclamationCircleFilled />,
                        title: `Watchlist: ${w.name}`,
                        description: `New matches: ${matchesStr}`,
                        link: `/watchlists`,
                        onRead: resetWatchlistNotification
                    });
                }
            )

            userNotifications.forEach(un => {
                ns.push({
                    ...un,
                    onRead: resetUserNotification
                });
            });

            return ns.filter(n => !!n);
        },
        [huntReportCount, resetHuntReport,
            watchlistNotifications, resetWatchlistNotification,
            userNotifications, resetUserNotification]
    );

    const loading = watchlistsLoading || userNotificationsLoading;

    return (
        <div>
            <Flex vertical gap="1.5rem">
                {(notifications.length === 0 && !loading) &&
                    <p>Nothing to report at the moment</p>}
                {(notifications.length === 0 && loading) && <SyncOutlined spin />}
                {notifications.map(n => (
                    <NotificationItem {...n} />
                ))}
            </Flex>
        </div>
    )
}

export function NotificationsIcon() {

    const [open, setOpen] = useState(false);
    const app = useContext(AppContext);

    const { userSettings,
        watchlistNotificationsCount = 0,
        userNotificationsCount = 0 } = useKeys(
            ["userSettings",
                "watchlistNotificationsCount",
                "userNotificationsCount"], app);
    const huntReportCount = userSettings?.registrar_hunt_counter || 0;
    const totalCount = watchlistNotificationsCount +
        huntReportCount +
        userNotificationsCount;

    return (
        <Popover
            rootClassName="notifications-dialog"
            arrow={false}
            destroyTooltipOnHide
            placement="bottomRight"
            content={<NotificationsDialog />}
            trigger="click"
            open={open}
            onOpenChange={setOpen}>
            <Badge
                className="header-top-badge"
                count={totalCount}>
                <BellOutlined />
            </Badge>
        </Popover>
    )
}