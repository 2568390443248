import { Select } from "antd"

const options = [
    {
        value: "both",
        label: "Dns + Tarpit"
    },
    {
        value: "dns",
        label: "Dns log"
    },
    {
        value: "tarpit",
        label: "Tarpit log"
    },
]

function DnsOrTarpitSelect(props) {

    return (
        <Select options={options} {...props} />
    )
}

export default DnsOrTarpitSelect