import { Tree } from "antd"
import { useCallback } from "react"


function AccessTree({ accessTree, value, onChange }) {

    const onCheck = useCallback(
        (_, { checkedNodes }) => {
            const accessKeys = checkedNodes.filter(n => n.leaf).map(n => n.key);
            onChange && onChange(accessKeys);
        },
        [onChange]
    );

    return (
        <Tree
            checkable
            treeData={accessTree}
            onCheck={onCheck}
            checkedKeys={value} />
    )
}

export default AccessTree