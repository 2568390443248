const { createClient } = require("@supabase/supabase-js");

const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_SECRET,
    {
        auth: {
            storage: window.AsyncStorage,
            autoRefreshToken: true,
            persistSession: true,
            detectSessionInUrl: true,
        },
    }
)

window.supabase = supabase

export default supabase