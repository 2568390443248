import { useCallback, useEffect, useState } from "react"
import { Alert, Button, Input, Typography } from "antd"
import Page from "components/Page"
import supabase from "lib/supabase/main";


function VerifyMFA() {

    const [code, setCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [factorId, setFactorId] = useState(null);
    const [error, setError] = useState(null);

    const loadFactors = useCallback(
        async () => {
            setLoading(true);
            const { data } = await supabase.auth.mfa.listFactors();
            const factorId = data?.all.find(f => f.status === "verified")?.id;
            setFactorId(factorId);
            setLoading(false);
        },
        []
    );

    const handleInputChange = useCallback(
        (e) => setCode(e.target.value.trim()),
        []
    );

    const onSubmitClick = useCallback(
        async () => {
            setLoading(true);
            const challenge = await supabase.auth.mfa.challenge({ factorId })
            if (challenge.error) {
                setError(challenge.error.message);
                setLoading(false);
                return;
            }

            const challengeId = challenge.data.id;

            const verify = await supabase.auth.mfa.verify({
                factorId,
                challengeId,
                code,
            });

            if (verify.error) {
                setError(verify.error.message)
                setLoading(false);
                return;
            }

            await supabase.auth.refreshSession();
            setLoading(false);
        },
        [factorId, code]
    );

    useEffect(
        () => {
            loadFactors();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Page className="page-mfa-enroll">
            <Typography.Title level={1}>Authenticator code</Typography.Title>
            {error && <Alert message={error} type="error" showIcon />}
            <br /><br />
            <Input
                className="page-mfa-enroll-code"
                disabled={loading}
                size="large"
                value={code}
                onChange={handleInputChange}
                onPressEnter={onSubmitClick} />
            <br /><br />
            <Button
                loading={loading}
                disabled={loading}
                size="large"
                children="Submit"
                onClick={onSubmitClick} />
        </Page>
    )
}

export default VerifyMFA