import { useMemo } from "react"
import deepmerge from "lodash-deepmerge"
import theme from "theme/config"
import formatNumber from "lib/format/number"

export const DEFAULT_HEIGHT = 400;

export const defaultColors = ['#c7c1e0', '#D7263D', '#1B998B', '#F46036', '#E2C044'];
export const defaultChartOptions = {
    fontFamily: theme.token.fontFamily,
    background: "transparent",
    toolbar: {
        show: false,
    },
};

export const defaultStroke = {
    width: 2,
    curve: 'smooth'
};

export const defaultTheme = {
    mode: "dark",
};

export const defaultDataLabels = {
    enabled: false
};

export const defaultGrid = {
    show: true,
    borderColor: 'rgba(255,255,255,0.05)',
    strokeDashArray: 0,
    position: 'back',
    xaxis: {
        lines: {
            show: true
        }
    },
    yaxis: {
        lines: {
            show: true
        }
    },
    row: {
        opacity: 0
    },
    column: {
        opacity: 0
    }
};

export const defaultFill = {
    type: "gradient",
    gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [0, 100]
    }
};

export const defaultAxisBorder = {
    show: true,
    color: 'rgba(255,255,255,0.1)',
    height: 1,
    width: '100%',
    offsetX: 0,
    offsetY: 0
};

export const defaultAxisTicks = {
    show: true,
    borderType: 'solid',
    color: 'rgba(255,255,255,0.1)',
    height: 6,
    offsetX: 0,
    offsetY: 0
};

export const defaultYAxisLabels = {
    formatter: function (value, timestamp, opts) {
        return formatNumber(value)
    },
    style: {
        cssClass: 'apexcharts-yaxis-label',
    },
}

export const defaultXAxisLabels = {
    formatter: function (value, timestamp, opts) {
        return opts.dateFormatter(new Date(timestamp), 'MMM dd')
    },
    style: {
        cssClass: 'apexcharts-xaxis-label',
    },
};

export function getDefaultOptions(data, axes) {
    const dateField = axes?.x?.field || "date";
    return {
        colors: defaultColors,
        chart: defaultChartOptions,
        stroke: defaultStroke,
        theme: defaultTheme,
        dataLabels: defaultDataLabels,
        grid: defaultGrid,
        fill: defaultFill,
        xaxis: {
            type: axes?.x?.type || 'datetime',
            categories: data.map(r => r[dateField]),
            tickAmount: data.length > 20 ? parseInt(data.length / 2) : data.length - 1,
            labels: defaultXAxisLabels,
            axisBorder: defaultAxisBorder,
            axisTicks: defaultAxisTicks,
        },
        yaxis: {
            labels: defaultYAxisLabels
        }
    }
}

export function getDefaultSeries(data, axes) {
    const field = axes?.y?.field || "cnt";
    return {
        name: axes?.y?.name || "",
        data: data.map(r => r[field])
    }
}

function useApexChart({
    data,
    type,
    axes,
    height = DEFAULT_HEIGHT,
    options = null,
    series = null }) {

    const chartOptions = useMemo(
        () => {
            const def = data ? getDefaultOptions(data, axes) : {};
            if (options) {
                return deepmerge.merge(def, options);
            }
            return def;
        },
        [data, axes, options]
    );

    const chartSeries = useMemo(
        () => series || [getDefaultSeries(data, axes)],
        [data, axes, series]
    );

    return {
        options: chartOptions,
        series: chartSeries,
        type,
        height
    }
}

export default useApexChart