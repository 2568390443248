
import { useCallback } from "react";
import SelectorDialog from "./SelectorDialog"

import supabase from "lib/supabase/main"

const columns = [
    {
        key: "id",
        dataIndex: "id",
        title: "ID"
    },
    {
        key: "name",
        dataIndex: "name",
        title: "Name"
    }
];



function MalwareDialog(props) {

    const loadMalware = useCallback(
        async ({ query }) => {
            const req = supabase.from("threat")
                .select("*")
                .order("name", { ascending: true });

            if (query) {
                req.ilike("name", `%${query}%`);
            }
            const { data, error } = await req;
            return { data, error };
        },
        []
    );

    return (
        <SelectorDialog
            title="Malware"
            {...props}
            autoload
            columns={columns}
            loaderFn={loadMalware} />
    )
}

export default MalwareDialog