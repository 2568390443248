import Context from "lib/Context";
import { createContext } from "react"

const AppContext = createContext();

export class App extends Context {


    hasAccessTo(key) {

        if (this.get("isAdmin")) {
            return true;
        }

        const access = this.get("userAccess");
        key = key.split("/");

        if (access[key[0]] && access[key[0]][key[1]]) {
            return true;
        }

        return false;
    }

}

export default AppContext
