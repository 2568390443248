
import { Typography, Flex } from "antd"

import useWebCheck from "hooks/useWebCheck"
import { useCallback, useState } from "react"
import RandomData from "components/RandomData"
import docs from "lib/webCheck"
import useUpdateEffect from "hooks/useUpdateEffect"


function Section({ result, report }) {

    if (!result.data) {
        return null;
    }

    const doc = docs.find(d => d.id === result.params?.job);
    let props = {};

    if (report !== "quality") {
        props.textMaxLength = 0;
        props.titleLevel = 5;
        props.usePopups = false;
        props.skipRootTabs = true;
        props.popupAtLevel = 5;
    }

    return (
        <div>
            <Typography.Title level={2}>{doc?.title}</Typography.Title>
            <RandomData
                {...props}
                data={result.data}
                loading={result.isLoading} />
        </div>
    )
}


function ReportWebCheck({ domain, onStatusUpdate }) {
    const [statuses, setStatuses] = useState([]);

    const setJobStatus = useCallback(
        (job, status) => {
            setStatuses(prev => {
                const inx = prev.findIndex(s => s.job === job);
                if (inx === -1) {
                    return [...prev, { job, status }];
                }
                else {
                    const next = [...prev];
                    next[inx].status = status;
                    return next;
                }
            })
        },
        []
    );

    const onLoad = useCallback(
        (data, { job }) => setJobStatus(job, "success"),
        [setJobStatus]
    );

    const onBeforeLoad = useCallback(
        ({ job }) => setJobStatus(job, "loading"),
        [setJobStatus]
    );

    const onLoadFailed = useCallback(
        ({ job }) => setJobStatus(job, "failed"),
        [setJobStatus]
    );

    const options = { onLoad, onBeforeLoad, onLoadFailed };

    //const ip = useWebCheck({ job: "get-ip", domain }, options);
    const whois = useWebCheck({ job: "whois", domain }, options);
    const txt = useWebCheck({ job: "txt-records", domain }, options);
    const tracert = useWebCheck({ job: "trace-route", domain }, options);
    const threats = useWebCheck({ job: "threats", domain }, options);
    const status = useWebCheck({ job: "status", domain }, options);
    const ssl = useWebCheck({ job: "ssl", domain }, options);
    const social = useWebCheck({ job: "social-tags", domain }, options);
    const security = useWebCheck({ job: "security-txt", domain }, options);
    const robots = useWebCheck({ job: "robots-txt", domain }, options);
    const redirects = useWebCheck({ job: "redirects", domain }, options);
    const rank = useWebCheck({ job: "rank", domain }, options);
    const quality = useWebCheck({ job: "quality", domain }, options);
    const ports = useWebCheck({ job: "ports", domain }, options);
    const linked = useWebCheck({ job: "linked-pages", domain }, options);
    const hsts = useWebCheck({ job: "hsts", domain }, options);
    const httpSecurity = useWebCheck({ job: "http-security", domain }, options);
    const headers = useWebCheck({ job: "headers", domain }, options);
    const firewall = useWebCheck({ job: "firewall", domain }, options);
    const dnssec = useWebCheck({ job: "dnssec", domain }, options);
    const dns = useWebCheck({ job: "dns", domain }, options);
    const dnsServer = useWebCheck({ job: "dns-server", domain }, options);
    const cookies = useWebCheck({ job: "cookies", domain }, options);
    const carbon = useWebCheck({ job: "carbon", domain }, options);
    const blockLists = useWebCheck({ job: "block-lists", domain }, options);
    const archives = useWebCheck({ job: "archives", domain }, options);
    const features = useWebCheck({ job: "features", domain }, options);

    useUpdateEffect(
        () => {
            onStatusUpdate && onStatusUpdate(statuses);
        },
        [statuses, onStatusUpdate]
    );

    return (
        <Flex vertical gap="2rem">
            <Section result={dns} />
            <Section result={whois} />
            <Section result={txt} />
            <Section result={threats} />
            <Section result={features} />
            <Section result={status} />
            <Section result={ssl} />
            <Section result={social} />
            <Section result={security} />
            <Section result={robots} />
            <Section result={redirects} />
            <Section result={rank} />
            <Section result={quality} report="quality" />
            <Section result={ports} />
            <Section result={linked} />
            <Section result={hsts} />
            <Section result={httpSecurity} />
            <Section result={headers} />
            <Section result={firewall} />
            <Section result={dnssec} />
            <Section result={dnsServer} />
            <Section result={cookies} />
            <Section result={carbon} />
            <Section result={blockLists} />
            <Section result={archives} />
            <Section result={tracert} />
        </Flex>
    )
}

export default ReportWebCheck