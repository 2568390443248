import { useEffect } from "react";
import { useLocation } from "react-router-dom"


function ScrollToTop() {

    const { pathname } = useLocation();

    useEffect(
        () => {
            document.documentElement.scrollTo({ top: 0 });
        },
        [pathname]
    )
}

export default ScrollToTop