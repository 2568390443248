import supabase from "lib/supabase/main"

import { data2csv } from "lib/csv"

export const columns = [
    {
        key: "cidr",
        dataIndex: "cidr",
        title: "CIDR"
    },
    {
        key: "asn",
        dataIndex: "asn",
        title: "ASN"
    }
]

export async function loadIpRegistryCidr(options = {}) {

    const {
        queryBy,
        query,
        withCount = false,
        entityId = null,
        cidr,
        ip,
        page = 0,
        pageSize = 20,
        csv = false,
        single = false
    } = options;

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit: csv ? 1000 : ((page + 1) * pageSize),
        queryBy,
        query,
        withCount,
        entityId,
        cidr,
        ip
    };

    const { data: { rows, columns, count = 0 }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "entity_cidr",
            options: params
        },
        method: "POST"
    });

    if (csv) {
        const text = data2csv(columns, rows);
        return text;
    }

    if (single) {
        return rows[0];
    }
    return { data: rows, columns, count, error };
}