import { theme } from 'antd';

function useAntdTheme() {

    const { token } = theme.useToken();

    //console.log(token)

    return token;
}

export default useAntdTheme