import { useContext, useEffect, useState } from "react"
import { useOn } from "@kuindji/observable-react"

import AppContext from "context/AppContext"

export function Portal({ host, children }) {

    const app = useContext(AppContext);

    useEffect(
        () => void app.trigger(`portal-${host}`, children),
        [host, children, app]
    );

    if (!host) {
        return children;
    }

    return null;
}

export function PortalHost({ name }) {
    const app = useContext(AppContext);
    const [children, setChildren] = useState(null);

    useEffect(
        () => void app.createEvent(`portal-${name}`, { autoTrigger: true }),
        // eslint-disable-next-line
        []
    );

    useOn(app, `portal-${name}`, setChildren);

    return children;
}