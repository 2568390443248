import { useCallback, useContext, useMemo } from "react"
import { Typography, Spin, Button, Flex } from "antd"
import { useMatch, useNavigate } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import HuntReportLogTable from "components/table/HuntReportLog"
import IpRegistryCompanyCidrTable from "components/table/IpRegistryCompanyCidr"
import TarpitLogTable from "components/table/TarpitLog"
import CompanyByDate from "components/report/CompanyByDate"
import IpTarpitPort from "components/table/IpTarpitPort"

import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"
import AppContext from "context/AppContext"
import { Portal } from "components/Portal"

const { Title } = Typography;


function Company() {

    const navigate = useNavigate();
    const { params: { id } } = useMatch("/reports/entity/:id");
    const app = useContext(AppContext);

    const { data: company, isLoading: isCompanyLoading } = useQuery(
        () => supabase.from("entity").select().eq("id", id),
        [id],
        {
            initialData: {},
            prepare: data => data[0] || {}
        }
    )

    const cidrParams = useMemo(
        () => ({ entityId: id, withCount: true }),
        [id]
    );

    const huntReportParams = useMemo(
        () => ({ entityId: id, distinct: true }),
        [id]
    );

    const tarpitParams = useMemo(
        () => ({
            entityId: id,
            withPayload: true,
            withCompany: false,
            withCidr: false,
            withThreat: true,
            pageSize: 10
        }),
        [id]
    );

    const dnsLogParams = useMemo(
        () => ({
            entityId: id,
            withCompany: false,
            withCidr: false,
            withThreat: true,
            pageSize: 10
        }),
        [id]
    );

    const companyByDateParams = useMemo(
        () => ({ entityId: id }),
        [id]
    );

    const ipPortParams = useMemo(
        () => ({ entityId: id, withCountry: true }),
        [id]
    );

    const onAddToWatchlist = useCallback(
        () => app.trigger("add-companies-to-watchlist", [{ id: company.id, name: company.name }]),
        [app, company]
    );

    const onLoadMoreTarpit = useCallback(
        () => {
            navigate(`/logs/tarpit?entityId=${id}`)
        },
        [id, navigate]
    );

    const onLoadMoreDns = useCallback(
        () => {
            navigate(`/logs/dns?entityId=${id}`)
        },
        [id, navigate]
    );

    return (
        <Page className="page-company" htmlClassName="html-page-company">
            <Portal host="header">
                <Spin spinning={isCompanyLoading}>
                    <Flex justify="space-between" className="header-single-row">
                        <Title level={1}>{company.name} ({company.domain}) {company.type}</Title>
                        <Button
                            size="large"
                            type="primary"
                            children="Add to watchlist"
                            onClick={onAddToWatchlist} />
                    </Flex>
                </Spin>
                <CompanyByDate
                    chartType="bar"
                    showToolbar={false}
                    title="Requests by date"
                    params={companyByDateParams} />
            </Portal>

            <section>
                <Title level={3}>Threats</Title>
                <HuntReportLogTable
                    initialParams={huntReportParams} />
            </section>

            <section>
                <Title level={3}>Tarpit log</Title>
                <TarpitLogTable
                    ipLink
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    onLoadMore={onLoadMoreTarpit}
                    initialParams={tarpitParams} />
            </section>

            <section>
                <Title level={3}>Dns log</Title>
                <DnsLogTable
                    ipLink
                    excludeQueryOptions={["companyName", "companyDomain"]}
                    initialParams={dnsLogParams}
                    onLoadMore={onLoadMoreDns} />
            </section>

            <section className="gradient-section">
                <Title level={3}>Tarpit IPs</Title>
                <IpTarpitPort
                    initialParams={ipPortParams} />
            </section>

            <IpRegistryCompanyCidrTable initialParams={cidrParams} />
        </Page>
    )
}

export default Company