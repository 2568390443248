import { Typography } from "antd"

const { Text, Link } = Typography;


function Footer() {

    return (
        <div className="footer">
            <Text>&copy; Copyright 2024 Vigilocity. All rights reserved.</Text>
            <br />
            <Text>
                <Link target="_blank" rel="noreferrer"
                    href="https://www.vigilocity.com/privacy-policy">Privacy Policy</Link>
                &nbsp;.&nbsp;
                <Link target="_blank" rel="noreferrer"
                    href="https://www.vigilocity.com/terms-conditions">Terms & Conditions</Link>
            </Text>
        </div>
    )
}

export default Footer