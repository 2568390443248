import { useCallback, useMemo, useState } from "react"
import { Input, Table, Tooltip, Button, Select } from "antd"
import { SearchOutlined, DownloadOutlined, SyncOutlined } from "@ant-design/icons"
import ipaddr from "ipaddr.js"

import domainNameRegex from "lib/domainNameRegex"
import useQuery from "hooks/useQuery"
import { loadIpRegistryLog, columns } from "api/ipRegistry"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { downloadCsv } from "lib/csv"
import useQueryOptions from "hooks/useQueryOptions"
import useDualState from "hooks/useDualState"
import useUpdateEffect from "hooks/useUpdateEffect"
import async from "lib/async"
import CountrySelector from "components/selector/CountrySelect"
import { Portal } from "components/Portal"

const PER_PAGE = 20;

const defaultQueryOptions = [
    { value: 'name', label: 'Company name' },
    { value: 'domain', label: 'Company domain' },
    { value: 'ip', label: 'IP' },
    { value: 'cidr', label: 'CIDR' },
];


const params = [
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    },
    {
        name: "country",
        default: null
    }
];

function IpRegistryCompany({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    //showPagination = true,
    allowMore = true,
    allowSearch = true,
    allowCsv = true,
    excludeQueryOptions,
    initialParams = {} }) {

    const [page, setPage] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const queryOptions = useQueryOptions(defaultQueryOptions, excludeQueryOptions);

    const {
        query, setQuery,
        queryBy, setQueryBy,
        country, setCountry,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });


    const { data, isLoading, reset, extraData = {} } = useQuery(
        loadIpRegistryLog,
        [page, all],
        {
            rowIdKey: "id",
            params: { ...initialParams, page, pageSize: PER_PAGE, ...all },
            initialData: [],
            append: true
        }
    );
    const { hasMore } = extraData;
    const fetchedColumns = extraData?.columns || [];

    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    const loadNextPage = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        [setPage]
    );

    /*const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );*/

    const onQueryChange = useCallback(
        (e) => {
            const query = e.target.value.trim();

            if (query) {
                if (ipaddr.isValid(query)) {
                    setQueryBy("ip");
                }
                else if (query.indexOf("/") !== -1 && ipaddr.isValid(query.split("/")[0])) {
                    setQueryBy("cidr");
                }
                else if (query.match(domainNameRegex)) {
                    setQueryBy("domain");
                }
                else {
                    setQueryBy("name");
                }
            }
            else {
                setQueryBy(undefined);
            }

            setQuery(e.target.value);
        },
        [setQuery, setQueryBy]
    );

    const onSearchClick = useCallback(
        () => {
            reset()
            setPage(0);
            async(apply);
        },
        [apply, reset, setPage]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadIpRegistryLog({ ...initialParams, queryBy, query, page, csv: true });
            downloadCsv(csv, "entity.csv");
            setDownloading(false);
        },
        [page, query, queryBy, initialParams]
    );

    const renderExpanded = useCallback(
        (row) => row.cidrs.map(c => c.cidr).join(", "),
        []
    );

    /*const pagination = useMemo(
        () => {
            if (!showPagination || count <= PER_PAGE) {
                return false;
            }
            return { 
                position: [ "bottomCenter" ],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [ count, onPaginationChange, showPagination, pageSize ]
    );*/

    const expandable = useMemo(
        () => {
            if (!initialParams.withCidrs) {
                return null;
            }
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: (row) => row.cidrs && row.cidrs.length > 1,
            }
        },
        [initialParams.withCidrs, renderExpanded]
    );

    return (
        <div className="table-ip-registry-company">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowSearch &&
                            <>
                                <CountrySelector
                                    allowClear
                                    showSearch
                                    size={toolbarSize}
                                    placeholder="Country"
                                    value={country}
                                    onChange={setCountry} />
                                <Input
                                    addonBefore={
                                        <Select
                                            placeholder="Query by"
                                            size={toolbarSize}
                                            value={queryBy}
                                            onChange={setQueryBy}
                                            options={queryOptions} />
                                    }
                                    allowClear
                                    onKeyDown={onKeyDown}
                                    size={toolbarSize}
                                    prefix={<SearchOutlined />}
                                    onChange={onQueryChange}
                                    value={query} />
                                <Button
                                    loading={isLoading}
                                    size={toolbarSize}
                                    disabled={isLoading}
                                    onClick={onSearchClick}
                                    children="Search" />
                            </>}
                        {allowCsv &&
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={downloading ? <SyncOutlined spin /> : <DownloadOutlined />} />
                            </Tooltip>}
                    </div>
                </Portal>}
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1)}
                rowKey="id"
                expandable={expandable}
                pagination={false} />
            {(allowMore && hasMore) &&
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </>}
        </div>
    )
}

export default IpRegistryCompany