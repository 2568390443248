import { useState } from "react"
import { Typography, Table, Segmented } from "antd"
import { Link } from "react-router-dom"

//import ButtonSelect from "components/ButtonSelect"

//import { loadTopDomains } from "api/dnsLog"
import useQuery from "hooks/useQuery"
import { periodOptionsWithAll } from "lib/periodOptions"

import supabase from "lib/supabase/main"
import formatNumber from "lib/format/number"
import ThreatName from "components/ThreatName"

const { Title } = Typography;


const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: domain => <Link to={`/reports/domain/${domain}`}>{domain}</Link>
    },
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: (threat, row) => {
            return (
                <ThreatName id={row.threat_id} name={threat} />
            )
        }
    },
    {
        dataIndex: "cnt",
        key: "cnt",
        title: "Dns log entries",
        render: formatNumber
    },
    {
        dataIndex: "tarpit_cnt",
        key: "tarpit_cnt",
        title: "Tarpit log entries",
        render: formatNumber
    },
]

async function loadTopDomains(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_threat_domains",
            options,
            replica: true,
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}

function TopDomains({ title = "Top threat domains" }) {

    const [period, setPeriod] = useState("last7");

    const { data, isLoading } = useQuery(
        loadTopDomains,
        [period],
        {
            params: {
                limit: 10,
                period
            }
        }
    );

    return (
        <div className="small-report report-top-threat-domains">
            <div className="toolbar">
                {title && <Title level={4}>{title}</Title>}
                <Segmented
                    className="toolbar-right"
                    onChange={setPeriod}
                    options={periodOptionsWithAll}
                    value={period} />
                {/*<Select
                    className="toolbar-right" 
                    value={ period } 
                    onChange={ setPeriod } 
                    options={ periodOptionsWithAll } />*/}
            </div>
            <Table
                className="table-transparent-header"
                sticky
                size="small"
                //bordered
                loading={isLoading}
                dataSource={data}
                columns={columns}
                rowKey="domain"
                pagination={false} />
        </div>
    )

}

export default TopDomains