import { Typography, Alert, Form, Input, Button, App, Flex } from "antd"
import { useUser } from "@supabase/auth-helpers-react"
import { useCallback, useContext, useState } from "react";
import { useOn } from "@kuindji/observable-react";

import Page from "components/Page"
import { Portal } from "components/Portal";

import useAppKey from "hooks/useAppKey";
import supabase from "lib/supabase/main";
import AppContext from "context/AppContext";
import useHtmlClass from "hooks/useHtmlClass";

const { Title } = Typography;

function Profile() {

    useHtmlClass("page-profile");
    const { notification } = App.useApp();
    const app = useContext(AppContext);
    const isApproved = useAppKey("isApproved");
    const userProfile = useAppKey("userProfile") || {};
    const user = useUser();
    const [saving, setSaving] = useState(false);
    const [signingOut, setSigningOut] = useState(false);
    const [form] = Form.useForm();

    const onSignout = useCallback(
        async () => {
            setSigningOut(true);
            await supabase.auth.signOut();
            app.set("loggedIn", false);
            setSigningOut(false);
        },
        [app]
    );

    const onSubmitClick = useCallback(
        async () => {
            setSaving(true);
            try {
                await form.validateFields();
                const values = form.getFieldsValue();

                const content = {
                    last_name: values.lastName,
                    first_name: values.firstName
                }
                await supabase.from("user_profile").update(content).eq("id", user.id);

                if (values.newPassword) {
                    await supabase.functions.invoke("backend", {
                        method: "post",
                        body: {
                            controller: "me/setPassword",
                            payload: {
                                password: values.newPassword
                            }
                        }
                    });
                }

                notification.success({ message: "Profile updated", duration: 2 });
                app.trigger("profile/update");
            }
            catch (err) {
                console.error(err);
                notification.error({ message: err.message || "Failed to update profile" });
            }
            setSaving(false);
        },
        [app, form, user, notification]
    );

    const onUserProfileUpdated = useCallback(
        (profile) => {
            form.resetFields();
            form.setFieldValue("firstName", profile.first_name);
            form.setFieldValue("lastName", profile.last_name);
        },
        [form]
    )

    useOn(app, "profile/loaded", onUserProfileUpdated)

    return (
        <Page className="page-profile">
            <Portal host="header">
                <div className="header-single-row">
                    <Title level={1}>Profile</Title>
                </div>
            </Portal>
            {!isApproved &&
                <Alert
                    message="Your account is not approved yet"
                    description="Once you get approved, you will have access to app's features."
                    type="info"
                    showIcon />}
            <br />
            {!!userProfile?.id &&
                <Form
                    form={form}
                    name="profile"
                    labelWrap
                    labelCol={{ span: 8 }}
                    initialValues={{
                        firstName: userProfile.first_name || "",
                        lastName: userProfile.last_name || ""
                    }}
                    autoComplete="off">

                    <Form.Item label="Email" size="large">
                        <Input disabled value={user.email} size="large" />
                    </Form.Item>
                    <Form.Item label="First name" name="firstName" size="large">
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item label="Last name" name="lastName" size="large">
                        <Input size="large" />
                    </Form.Item>

                    <Title level={5} style={{ textAlign: "center" }}>Change password</Title>
                    <br />

                    <Form.Item label="New password" name="newPassword" size="large">
                        <Input.Password size="large" />
                    </Form.Item>
                    <Form.Item
                        size="large"
                        label="Repeat new password"
                        name="newPassword2"
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if ((getFieldValue('newPassword') || "") === (value || "")) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error("Passwords don't match")
                                    );
                                },
                            })
                        ]}>
                        <Input.Password size="large" />
                    </Form.Item>


                    <Flex justify="end" gap="1rem">
                        {!isApproved &&
                            <Button
                                loading={signingOut}
                                disabled={signingOut}
                                onClick={onSignout}
                                size="large">
                                Sign out
                            </Button>}
                        <Button
                            onClick={onSubmitClick}
                            loading={saving}
                            disabled={saving}
                            type="primary"
                            size="large">
                            Submit
                        </Button>


                    </Flex>

                </Form>}
        </Page>
    )
}

export default Profile