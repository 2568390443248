import { useCallback, useMemo, useState } from "react"
import { Input, Table, /*Tooltip,*/ Button, Select } from "antd"
import { SearchOutlined/*, DownloadOutlined, SyncOutlined*/ } from "@ant-design/icons"

import RandomData from "components/RandomData"

import useDualState from "hooks/useDualState"
//import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useUpdateEffect from "hooks/useUpdateEffect"
import useQuery from "hooks/useQuery"

import async from "lib/async"
//import downloadCsv from "lib/downloadCsv"

import { loadThreat, columns } from "api/threat"
import { Portal } from "components/Portal"

const DEFAULT_PAGE_SIZE = 50;

const queryOptions = [
    { value: "name", label: "Name" },
    { value: "description", label: "Description" },
];

const params = [
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    }
];



function ThreatFoxIoc({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowSearch = true,
    initialParams = {}
}) {

    const {
        query, setQuery,
        queryBy, setQueryBy,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

    const { data, count, isLoading, reset, extraData = {} } = useQuery(
        loadThreat,
        [all, page, pageSize],
        {
            rowIdKey: "id",
            params: { ...initialParams, page, pageSize, ...all },
            initialData: []
        }
    );
    const fetchedColumns = extraData.columns || [];

    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    const onSearchClick = useCallback(
        () => {
            reset();
            setPage(0);
            async(apply);
        },
        [reset, apply, setPage]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onQueryChange = useCallback(
        (e) => {
            setQuery(e.target.value);
            if (!queryBy) {
                setQueryBy("name");
            }
        },
        [setQuery, queryBy, setQueryBy]
    );

    const renderExpanded = useCallback(
        (row) => {
            return (
                <RandomData
                    size="small"
                    data={row}
                    skipRootTabs />
            );
        },
        []
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [pageSize, count, onPaginationChange]
    );

    return (
        <div className="threatfox-ioc">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowSearch &&
                            <Input
                                addonBefore={
                                    <Select
                                        size={toolbarSize}
                                        allowClear
                                        placeholder="Query by"
                                        value={queryBy}
                                        onChange={setQueryBy}
                                        options={queryOptions} />
                                }
                                allowClear
                                size={toolbarSize}
                                onKeyDown={onKeyDown}
                                prefix={<SearchOutlined />}
                                onChange={onQueryChange}
                                value={query} />}
                        {(allowSearch) &&
                            <Button
                                size={toolbarSize}
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search" />}
                    </div>
                </Portal>}
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1)}
                rowKey="id"
                pagination={pagination}
                expandable={{
                    expandedRowRender: renderExpanded,
                    rowExpandable: () => true,
                }} />
        </div>
    )
}

export default ThreatFoxIoc