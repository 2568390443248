import { useState, useCallback } from "react"
import { Input, Button, Typography } from "antd"

import Page from "components/Page"
import { Portal } from "components/Portal"
import ReportWebCheck from "components/report/WebCheck"

import { SearchOutlined } from "@ant-design/icons"

import useDictRef from "hooks/useDictRef"
import useDualState from "hooks/useDualState"


const params = [
    {
        name: "query"
    }
];


function WebCheck() {

    const [domain, setDomain] = useState(null);
    const { query, setQuery, apply } = useDualState({ params, mode: "both" })
    const [statuses, setStatuses] = useState([]);
    const loadingCount = statuses.filter(s => s.status === "loading").length;
    const ref = useDictRef({ query });

    const check = useCallback(
        async () => {
            setDomain(ref.query);
        },
        //eslint-disable-next-line
        []
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onCheckClick = useCallback(
        () => {
            apply();
            check();
        },
        [check, apply]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onCheckClick();
            }
        },
        [onCheckClick]
    );

    return (
        <Page className="page-web-check">
            <Portal host="header">
                <Typography.Title level={1}>Mythic Scan</Typography.Title>
                <div className="toolbar">
                    <Input
                        size="large"
                        placeholder="domain.xyz"
                        allowClear
                        onKeyDown={onKeyDown}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size="large"
                        loading={loadingCount > 0}
                        disabled={loadingCount > 0 || domain === query}
                        onClick={onCheckClick}
                        children="Check" />
                </div>
            </Portal>

            {domain &&
                <ReportWebCheck
                    domain={domain}
                    onStatusUpdate={setStatuses} />}
        </Page>
    )
}

export default WebCheck