import { Select } from "antd"
import supabase from "lib/supabase/main";
import { useMemo, useState } from "react"

import useQuery from "hooks/useQuery";

async function getDomains() {
    const { data: hunt } = await supabase.from("hunt_report_log").select("domain");
    const { data: queue } = await supabase.from("hunt_report_queue").select("domain");
    let domains = hunt.map(r => r.domain);
    queue.forEach(r => domains.push(r.domain));

    domains = domains.filter((d, inx, self) => self.indexOf(d) === inx);
    return { data: domains.sort() };
}


function DomainSelect(props) {
    const [query, setQuery] = useState("");
    const { data: domains, isLoading } = useQuery(getDomains, []);
    const options = useMemo(
        () => {
            return domains
                .filter(d => !query ? true : d.indexOf(query) !== -1)
                .map(d => ({
                    key: d,
                    value: d,
                    label: d
                }));
        },
        [domains, query]
    );

    return (
        <Select
            showSearch
            loading={isLoading}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            options={options}
            onSearch={setQuery}
            {...props} />
    )
}

export default DomainSelect