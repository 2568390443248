import { useCallback, useContext, useMemo, useState } from "react"
import { Button, Typography, Flex } from "antd"
import { useMatch } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import HuntReportLogTable from "components/table/HuntReportLog"
import IpByDate from "components/report/IpByDate"
import IpGeoTable from "components/table/IpGeo"
import TarpitLogTable from "components/table/TarpitLog"
import MapPoint from "components/MapPoint"
import AppContext from "context/AppContext"
import { Portal } from "components/Portal"
import IpLink from "components/link/Ip"

const { Title } = Typography

function Ip() {

    const { params: { ip, id } } = useMatch("/company/:id/ip/:ip");
    const [geoData, setGeoData] = useState(null);
    const app = useContext(AppContext);

    const onGeoDataLoaded = useCallback(
        (data) => {
            if (data[0]) {
                const latitude = data[0]?.geo_json.coordinates[1] || 0;
                const longitude = data[0]?.geo_json.coordinates[0] || 0;
                setGeoData({ longitude, latitude });
            }
            else {
                setGeoData(null);
            }
        },
        []
    );

    const geoParams = useMemo(() => ({ ip }), [ip]);

    const huntReportParams = useMemo(
        () => ({ ip, withCount: false, distinct: true }),
        [ip]
    );

    const tarpitParams = useMemo(
        () => ({
            ip,
            withPayload: true,
            withHeaders: true,
            withThreat: true,
            withCompany: false,
            pageSize: 10
        }),
        [ip]
    );

    const dnsLogParams = useMemo(
        () => ({
            ip,
            pageSize: 10,
            withCompany: false
        }),
        [ip]
    );

    const ipByDateParams = useMemo(
        () => ({ ip }),
        [ip]
    );

    const onAddToWatchlist = useCallback(
        () => app.trigger("add-ips-to-watchlist", [ip]),
        [app, ip]
    );

    const ipLink = useCallback(
        (ip) => (
            <IpLink ip={ip} companyId={id} />
        ),
        [id]
    );

    return (
        <Page className="page-ip" htmlClassName="html-page-ip">
            <Portal host="header">
                <MapPoint {...geoData} />
            </Portal>

            <section>
                <Flex justify="space-between" className="header-single-row">
                    <Title level={1}>{ip}</Title>
                    <Button
                        size="large"
                        type="primary"
                        children="Add to watchlist"
                        onClick={onAddToWatchlist} />
                </Flex>
            </section>
            <section>
                <IpGeoTable initialParams={geoParams} onLoad={onGeoDataLoaded} />
            </section>

            <section>
                <Title level={3}>Threats</Title>
                <HuntReportLogTable
                    domainExpandable={false}
                    domainLink={false}
                    showPagination={false}
                    showToolbar={false}
                    initialParams={huntReportParams} />
            </section>

            <section className="gradient-section">
                <IpByDate params={ipByDateParams} />
            </section>

            <section>
                <Title level={3}>Tarpit log</Title>
                <TarpitLogTable
                    ipLink={ipLink}
                    domainLink={false}
                    excludeQueryOptions={["ip", "companyName", "companyDomain"]}
                    initialParams={tarpitParams} />
            </section>

            <section>
                <Title level={3}>Dns log</Title>
                <DnsLogTable
                    ipLink={ipLink}
                    domainLink={false}

                    excludeQueryOptions={["ip", "companyName", "companyDomain"]}
                    initialParams={dnsLogParams} />
            </section>
        </Page>
    )
}

export default Ip