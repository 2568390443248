import { useMemo, useState } from "react"
import { Segmented, Spin, Typography } from "antd"
import moment from "moment"
import Chart from "react-apexcharts"

import { loadRegistrarLog } from "api/registrarLog"
import useQuery from "hooks/useQuery"
import fillDates from "lib/fillDates"
import periodOptions from "lib/periodOptions"
import useApexChart from "hooks/useApexChart"

const { Title } = Typography;


function RegistrationsByDate({
    title = "Domain registrations by date",
    showToolbar = true,
    chartType = "area",
    chartOptions = null,
    height,
    country
}) {

    const [period, setPeriod] = useState("last30");

    const { data, isLoading } = useQuery(
        loadRegistrarLog,
        [period, country],
        {
            prepare: data => {
                return fillDates(period, data, "date", (d) => d.format("YYYY-MM-DD"), { cnt: 0 });
            },
            map: (row => ({
                date: moment(row.date).utc().toDate().getTime(),
                cnt: parseInt(row.cnt)
            })),
            params: {
                withCount: false,
                replica: true,
                report: "countByDate",
                period,
                country
            }
        }
    );

    const chart = useApexChart({
        data,
        type: chartType,
        options: chartOptions,
        height,
        axes: {
            y: {
                name: "Registrations"
            }
        }
    });

    const wrapperStyle = useMemo(
        () => ({ height }),
        [height]
    );

    return (
        <div className="small-report report-domain-registrations-by-date">
            {showToolbar &&
                <div className="toolbar">
                    {title && <Title level={4}>{title}</Title>}
                    <Segmented
                        className="toolbar-right"
                        value={period}
                        onChange={setPeriod}
                        options={periodOptions} />
                </div>}
            <Spin spinning={isLoading}>
                <div className="chart-wrapper" style={wrapperStyle}>
                    {data.length > 0 &&
                        <Chart {...chart} />}
                </div>
            </Spin>
        </div>
    )

}

export default RegistrationsByDate