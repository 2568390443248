import { useCallback, useEffect, useState } from "react"
import supabase from "lib/supabase/main"
import useDictRef from "./useDictRef";
import useUpdateEffect from "./useUpdateEffect";

function useSubscription({ table, filter, callback, enabled, event = "*" }) {

    const channelName = table + filter;
    const [channel, setChannel] = useState(null);
    const ref = useDictRef({ callback, channel, table, filter, enabled, channelName });

    const subscribe = useCallback(
        () => {
            const channel = supabase
                .channel(ref.channelName)
                .on(
                    "postgres_changes",
                    {
                        event,
                        schema: "public",
                        table: ref.table,
                        filter: ref.filter
                    },
                    onSubscriptionEvent
                )
                .subscribe();
            setChannel(channel);
        },
        // eslint-disable-next-line
        []
    );

    const unsubscribe = useCallback(
        () => {
            ref.channel && supabase.removeChannel(ref.channel);
            setChannel(null);
        },
        // eslint-disable-next-line
        []
    );

    const onSubscriptionEvent = useCallback(
        (...args) => {
            ref.callback.apply(args);
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {

            if (ref.enabled) {
                subscribe();
            }

            return () => {
                unsubscribe();
            }
        },
        // eslint-disable-next-line
        []
    );

    useUpdateEffect(
        () => {
            unsubscribe();
            subscribe();
        },
        [table, filter, enabled]
    )
}

export default useSubscription