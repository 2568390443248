import { useMemo } from "react"


function useQueryOptions(defaultOptions, exclude) {

    const options = useMemo(
        () => {
            if (!exclude) {
                return defaultOptions;
            }
            return defaultOptions.filter(o => exclude.indexOf(o.value) === -1);
        },
        [defaultOptions, exclude]
    );

    return options;
}

export default useQueryOptions