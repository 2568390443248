import { useCallback, useMemo } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Typography } from "antd"

import Page from "components/Page"
import useQuery from "hooks/useQuery"
import renderGMT from "lib/renderGMT"

import supabase from "lib/supabase/main"
import { Portal } from "components/Portal";


async function loadFactors() {
    const { data, error } = await supabase.auth.mfa.listFactors();
    return { data: data.all, count: data.all.length, error };
}

function MFAFactors() {

    const { data, isLoading, refetch } = useQuery(
        loadFactors
    );

    const onUnenroll = useCallback(
        async (f) => {
            await supabase.auth.mfa.unenroll({ factorId: f.id });
            refetch();
            supabase.auth.refreshSession();
        },
        [refetch]
    );

    const columns = useMemo(
        () => {
            return [
                {
                    key: "created_at",
                    dataIndex: "created_at",
                    title: "Created at (GMT)",
                    render: renderGMT
                },
                {
                    key: "updated_at",
                    dataIndex: "updated_at",
                    title: "Updated at (GMT)",
                    render: renderGMT
                },
                {
                    key: "status",
                    dataIndex: "status",
                    title: "Status"
                },
                {
                    key: "action",
                    dataIndex: "status",
                    className: "table-cell-collapse",
                    render: (_, row) => {
                        return (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => onUnenroll(row)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        )
                    }
                }
            ];
        },
        [onUnenroll]
    );

    return (
        <Page className="page-mfa-list">
            <Portal host="header">
                <div className="header-single-row">
                    <Typography.Title level={1}>MFA factors</Typography.Title>
                </div>
            </Portal>
            <Table
                bordered
                size="small"
                rowKey="id"
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={columns}
                pagination={false} />
        </Page>
    )
}

export default MFAFactors