
import { Typography } from "antd"
import Page from "components/Page"

import HuntReportLogTable from "components/table/HuntReportLog";

const { Title } = Typography;

const initialParams = {
    withCounter: true
}

function HuntReportLog() {


    return (
        <Page className="page-hunt-report-log">
            <HuntReportLogTable
                before={<Title level={1}>Hunt report log</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                initialParams={initialParams}
                stateMode="both" />
        </Page>
    )
}

export default HuntReportLog