import { Button, Flex, Popconfirm, Table, Tag, Tooltip } from "antd"
import { useMemo, useCallback, useState } from "react"
import { Link } from "react-router-dom"

import useAppKey from "hooks/useAppKey"
import useArrayToggle from "hooks/useArrayToggle"
import supabase from "lib/supabase/main"
import useDictRef from "hooks/useDictRef"
import TakedownStatus, { getOptionName } from "components/selector/TakedownStatus"
import renderGMT from "lib/renderGMT"
import useQuery from "hooks/useQuery"
import CountryName from "components/CountryName"


function fetchDomainInfo({ domains }) {
    if (!domains || !domains.length) {
        return [];
    }
    return supabase.from("domain")
        .select("domain, country")
        .in("domain", domains);
}

function PotentialPhishingDomains({
    data,
    onStatusChange
}) {

    const isAdmin = useAppKey("isAdmin");
    const isRegistrar = useAppKey("isRegistrar");
    const [loading, setLoading] = useArrayToggle();
    const [dataWInfo, setDataWInfo] = useState(data);
    const ref = useDictRef({ loading });

    useQuery(
        fetchDomainInfo,
        [data],
        {
            enabled: !!data && data.length > 0,
            params: {
                domains: data.map(row => row.similar_domain)
            },
            onLoad: (domains) => {
                if (domains) {
                    const rows = data.map(row => ({ ...row }));
                    rows.forEach(row => {
                        const d = domains.find(dm => dm.domain === row.similar_domain);
                        if (d) {
                            row.domain_info = d;
                        }
                    });
                    setDataWInfo(rows);
                }
            }
        }
    );

    const updateTakedown = useCallback(
        async (row, updates) => {
            const key = `${row.company_id}-${row.domain}-${row.similar_domain}`;
            setLoading(key, true);
            await supabase.from("company_domain_similar")
                .update(updates)
                .eq("company_id", row.company_id)
                .eq("domain", row.domain)
                .eq("similar_domain", row.similar_domain);
            if (onStatusChange) {
                await onStatusChange(row);
            }
            setLoading(key, false);
        },
        [setLoading, onStatusChange]
    );

    const onRequestTakedownClick = useCallback(
        (row) => {
            updateTakedown(row, {
                takedown_requested: true,
                request_status: "requested",
                status_changed_at: (new Date()).toISOString()
            });
        },
        [updateTakedown]
    );

    const handleStatusChange = useCallback(
        (row, status) => {
            updateTakedown(row, {
                request_status: status,
                status_changed_at: (new Date()).toISOString()
            });
        },
        [updateTakedown]
    );

    const resetTakedownRequest = useCallback(
        (row) => {
            return updateTakedown(row, {
                takedown_requested: false
            });
        },
        [updateTakedown]
    );

    const columns = useMemo(
        () => {
            const columns = [
                {
                    dataIndex: "domain",
                    key: "domain",
                    title: "Domain"
                },
                {
                    dataIndex: "similar_domain",
                    key: "similar_domain",
                    title: "Similar domain",
                    render: d => <Link to={`/reports/domain/${d}`}>{d}</Link>
                },
                {
                    dataIndex: "domain_info",
                    key: "country",
                    title: "Country",
                    render: (di) => <CountryName country={di?.country} />
                },
                {
                    // className: "table-cell-collapse table-cell-nowrap",
                    dataIndex: "request_status",
                    key: "request_status",
                    title: "Status",
                    width: 200,
                    render: (s, row) => {
                        const key = `${row.company_id}-${row.domain}-${row.similar_domain}`;
                        const isLoading = ref.loading.indexOf(key) !== -1;
                        if (isRegistrar) {
                            return (

                                <Tooltip title={renderGMT(row.status_changed_at)}>
                                    <TakedownStatus
                                        value={s}
                                        onChange={(s) => handleStatusChange(row, s)}
                                        loading={isLoading}
                                        disabled={isLoading} />
                                </Tooltip>

                            )
                        }
                        else if (isAdmin) {
                            if (!row.takedown_requested) {
                                return null;
                            }
                            if (s) {
                                return (
                                    <Flex gap="0.25rem">
                                        <Tooltip title={renderGMT(row.status_changed_at)}>
                                            <TakedownStatus
                                                value={s}
                                                onChange={(s) => handleStatusChange(row, s)}
                                                loading={isLoading}
                                                disabled={isLoading} />
                                        </Tooltip>
                                        <Popconfirm
                                            title="Are you sure?"
                                            onConfirm={
                                                () => resetTakedownRequest(row)
                                            }>
                                            <Button children="Reset" type="text" />
                                        </Popconfirm>
                                    </Flex>
                                )
                            }
                        }
                        else {
                            if (!row.takedown_requested) {
                                return (
                                    <Button
                                        children="Request takedown"
                                        onClick={() => onRequestTakedownClick(row)}
                                        loading={isLoading}
                                        disabled={isLoading} />
                                )
                            }
                            else {
                                return (
                                    <Tag
                                        children={
                                            getOptionName(s) + " (" + renderGMT(row.status_changed_at) + ")"
                                        } />
                                )
                            }
                        }
                    }
                }
            ];
            return columns;
        },
        [isAdmin, isRegistrar, onRequestTakedownClick, ref,
            handleStatusChange, resetTakedownRequest]
    );

    return (
        <Table
            className="table-full-width"
            size="small"
            rowKey={row => `${row.domain}:${row.similar_domain}`}
            bordered
            dataSource={dataWInfo}
            columns={columns}
            pagination={{ position: ["bottomLeft"], hideOnSinglePage: true }} />
    )
}

export default PotentialPhishingDomains