import { useCallback, useState, useMemo } from "react"
import { Table, Button } from "antd"

import useQuery from "hooks/useQuery"
import { loadIpTarpitPort, columns } from "api/ipTarpitPort"

const PER_PAGE = 20;

function IpTarpitPort({
    allowMore = true,
    ipLink = true,
    onLoad,
    initialParams = {} }) {

    const [page, setPage] = useState(0);


    const rowKey = useCallback(
        (row) => `${row.ip}-${row.port}`,
        []
    );

    const { data, isLoading, extraData } = useQuery(
        loadIpTarpitPort,
        [page, ...(Object.values(initialParams) || [])],
        {
            rowIdKey: rowKey,
            params: { page, pageSize: PER_PAGE, ...initialParams },
            initialData: [],
            onLoad,
            append: true
        },
        Object.values(initialParams) || [false]
    );
    const { hasMore } = extraData;

    const tableColumns = useMemo(
        () => {
            const fetchedColumns = extraData.columns || [];
            let tableColumns = columns.filter(c => fetchedColumns.indexOf(c.dataIndex) !== -1);

            if (ipLink === false) {
                const inx = tableColumns.findIndex(c => c.dataIndex === "ip");
                if (inx !== -1) {
                    delete tableColumns[inx].render;
                }
            }
            else if (typeof ipLink === "function") {
                const inx = tableColumns.findIndex(c => c.dataIndex === "ip");
                if (inx !== -1) {
                    tableColumns[inx].render = ipLink;
                }
            }

            return tableColumns;
        },
        [extraData?.columns, ipLink]
    );

    const loadNextPage = useCallback(
        () => setPage(prev => prev + 1),
        [setPage]
    );

    return (
        <div className="table-ip-tarpit-payload">
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey={rowKey}
                pagination={false} />
            {(allowMore && hasMore) &&
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </>}
        </div>
    )
}

export default IpTarpitPort