import { theme } from "antd"

export const breakpoints = {
    mobile: 0,
    tablet: 800,
    desktop: 1024,
    max: 1440
}

const config = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: "#3666d6",
        fontFamily: 'Titillium Web'
    }
}

export default config;