// import { useCallback } from "react"
import useQuery from "./useQuery"

export const API_URL = "https://2lb1u2zikl.execute-api.us-east-1.amazonaws.com/live";

export async function fetchWebcheckData({ job, domain }) {
    const url = `${API_URL}/${job}?url=${domain}`;
    try {

        const resp = await fetch(url).then(resp => resp.json())

        if (!resp) {
            return {
                data: null,
                error: "No response",
                status: "error"
            }
        }
        else if (resp.error) {
            return {
                data: null,
                error: resp.error,
                status: "error"
            }
        }
        else if (resp.skipped) {
            return {
                data: null,
                error: null,
                status: "skipped"
            }
        }

        return {
            data: resp,
            error: null,
            status: "success"
        }
    }
    catch (err) {
        return {
            data: null,
            error: err.message,
            status: "error"
        }
    }
}

function useWebCheck({ job, domain }, options = {}) {

    return useQuery(
        fetchWebcheckData,
        [job, domain],
        {
            initialData: null,
            params: {
                job,
                domain
            },
            ...options
        }
    );
}

export default useWebCheck