import objectFilter from "lib/objectFilter"
import isDeepEmpty from "lib/isDeepEmpty"
import aws from "lib/aws"

function otxDataFilter(path, value, replace) {
    if (path.indexOf("*") !== -1) {
        return true;
    }
    if (path === "/general/sections") {
        return false;
    }
    if (path === "/http_scans") {
        if (value.Error) {
            return false;
        }
    }
    if (path.indexOf("httpStatusCode") !== -1) {
        return false;
    }
    if (path === "/url_list") {
        replace(value.url_list);
        return true;
    }
    if (path === "/passive_dns") {
        replace(value.passive_dns);
        return true;
    }
    const last = path.split("/").pop();
    if (last === "count" || last === "size") {
        return false;
    }
    if (path.split("/").length === 2) {
        if (isDeepEmpty(value)) {
            return false;
        }
    }
    return true;
}

export default async function loadOtxData(query) {
    const resp = await aws.backend.get("/otx/indicators", {
        searchParams: { query }
    });

    const pulses = resp.data.general?.pulse_info?.pulses;
    if (pulses && pulses.length > 0) {
        resp.data.general.pulses = pulses;
        delete resp.data.general?.pulse_info?.pulses;
    }

    const filtered = objectFilter(resp.data, otxDataFilter);

    return filtered;
}


export const keyNames = {
    url_list: "URL list",
    passive_dns: "Passive DNS",
    http_scans: "HTTP Scans",
    attack_ids: "Attack IDs",
    ioc: "IOC"
};