import { Button } from "antd"
import { useCallback } from "react"

function UrlButton({ url, ...rest }) {

    const onClick = useCallback(
        () => {
            window.open(url);
        },
        [url]
    );

    return (
        <Button onClick={onClick} {...rest} />
    )
}

export default UrlButton