import { Select } from "antd"
import countries from "dict/countries.json"
import { useCallback } from "react"

const options = countries.map(c => {
    return {
        value: c.code,
        label: c.name
    }
})

function CountrySelect(props) {

    const optionsFilter = useCallback(
        (query, { label, value }) => {
            return label?.toLowerCase().includes(query.toLowerCase()) ||
                value?.toLowerCase() === query.toLowerCase();
        },
        []
    );

    return (
        <Select
            filterOption={optionsFilter}
            options={options}
            {...props} />
    )
}

export default CountrySelect