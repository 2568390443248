import { useCallback, useEffect, useState } from "react"
import { Typography, Button, Spin } from "antd"
import Chart from "react-apexcharts"
import moment from "moment"

import Page from "components/Page"
import { Portal } from "components/Portal"
import CompanySelect from "components/selector/CompanySelect"

import { getDefaultSeries, getDefaultOptions, DEFAULT_HEIGHT } from "hooks/useApexChart"
import supabase from "lib/supabase/main"
import async from "lib/async"
import useDictRef from "hooks/useDictRef"

const chartConfigs = [
    {
        field: "dns_log_counter",
        title: "Dns requests"
    },
    {
        field: "tarpit_log_counter",
        title: "Tarpit requests"
    }
];

window.moment = moment

function generateDates() {
    const dates = [];
    let d = moment().utc().startOf("day");
    for (let i = 0; i < 30; i++) {
        dates.push(d.toDate().toISOString());
        d = d.subtract(1, "day");
    }
    return dates.reverse();
}


function getCharts(rows, companies, selectedCompanies) {

    rows.forEach(row => {
        row.dns_log_counter = parseInt(row.dns_log_counter);
        row.tarpit_log_counter = parseInt(row.tarpit_log_counter);
    });

    const dates = generateDates();

    return chartConfigs.map(cc => {
        const series = selectedCompanies.map(cid => {
            const c = companies.find(c => c.id === cid);
            const stats = rows.filter(row => row.company_id === c.id);

            return getDefaultSeries(
                dates.map(date => {
                    return stats.find(row => row.date === date) || {
                        company_id: c.id,
                        dns_log_counter: 0,
                        tarpit_log_counter: 0
                    }
                }),
                {
                    y: {
                        field: cc.field,
                        name: c.name
                    }
                }
            )
        });

        const options = getDefaultOptions(
            dates.map(date => ({ date: (new Date(date)).getTime() }))
        );

        const chart = {
            title: cc.title,
            config: {
                options,
                series,
                height: DEFAULT_HEIGHT,
                type: "area"
            }
        };
        return chart;
    })

}

async function fetchDefaultCompanies() {
    const startDate = moment().utc().startOf("day").subtract(30, "days").toISOString();
    const { data } = await supabase.from("company_report_date")
        .select("company_id")
        .gte("date", startDate);
    data.forEach(row => {
        row.cnt = parseInt(row.dns_log_counter) + parseInt(row.tarpit_log_counter)
    })
    let rows = data.sort((a, b) => {
        if (a.cnt === b.cnt) {
            return 0;
        }
        return a.cnt < b.cnt ? -1 : 1;
    });
    rows = rows.filter((row, inx, self) => self.findIndex(r => r.company_id === row.company_id) === inx);
    return rows.map(row => row.company_id);
}

async function fetchReport(options) {
    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "dns_by_date",
            options,
            replica: true
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}




function ReportCompanies() {

    const [companies, setCompanies] = useState([]);
    const [companyIds, setCompanyIds] = useState([]);
    const [charts, setCharts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initial, setInitial] = useState(true);

    const loadDnsReport = useCallback(
        async () => {
            if (ref?.companyIds.length === 0) {
                return;
            }
            setLoading(true);
            const { data } = await fetchReport({
                customerCompanyId: [...companyIds],
                period: "last30",
                includeTarpit: true
            });
            setCharts(getCharts(data, companies, companyIds));
            setLoading(false);
        },
        // eslint-disable-next-line
        [companyIds, companies]
    );

    const ref = useDictRef({ companies, companyIds, loadDnsReport, initial });

    const loadDefault = useCallback(
        async () => {
            if (!ref.initial) {
                return;
            }
            const cids = await fetchDefaultCompanies();
            setCompanyIds(cids);
            async(() => ref.loadDnsReport(), 500);
        },
        [ref]
    );

    const onViewClick = useCallback(
        async () => {
            if (companyIds.length > 0) {
                await loadDnsReport();
            }
            else {
                setCharts([]);
            }
        },
        [companyIds, loadDnsReport]
    );

    useEffect(
        () => {
            if (companies.length > 0 && ref.companyIds.length === 0) {
                void loadDefault();
                setInitial(false);
            }
        },
        [companies, loadDefault, ref.companyIds.length]
    );

    return (
        <Page className="page-report-companies">
            <Portal host="header">
                <Typography.Title level={1}>Companies</Typography.Title>
                <div className="toolbar">
                    <CompanySelect
                        className="page-report-companies-selector"
                        size="large"
                        placeholder="Select companies"
                        mode="multiple"
                        value={companyIds}
                        onChange={setCompanyIds}
                        disabled={loading}
                        onLoad={setCompanies}
                        allowClear />
                    <Button
                        onClick={onViewClick}
                        loading={loading}
                        disabled={loading}
                        size="large"
                        children="View report" />
                </div>
            </Portal>

            {charts.length > 0 &&
                charts.map((chart, inx) => (
                    <section key={inx}>
                        <Spin spinning={loading}>
                            <Typography.Title level={2}>{chart.title}</Typography.Title>
                            <div className="chart-wrapper">
                                <Chart {...chart.config} />
                            </div>
                        </Spin>
                    </section>
                ))}
        </Page>
    )
}

export default ReportCompanies