
//import Header from "components/Header"
import Footer from "components/Footer"
import ScrollToTop from "components/ScrollToTop"

import PageMFAEnroll from "pages/mfa/Enroll"
import PageMFAVerify from "pages/mfa/Verify"

import useKey from "hooks/useKey"
import { useContext } from "react"
import AppContext from "context/AppContext"
import useHtmlClass from "hooks/useHtmlClass"

function MFAView() {

    useHtmlClass("html-mfa-view")
    const app = useContext(AppContext);
    const hasVerifiedFactors = useKey("hasVerifiedFactors", app);
    const loggedInMfa = useKey("loggedInMfa", app);

    return (
        <>
            <ScrollToTop />
            {!hasVerifiedFactors && <PageMFAEnroll />}
            {(hasVerifiedFactors && !loggedInMfa) && <PageMFAVerify />}
            <Footer />
        </>
    )
}

export default MFAView