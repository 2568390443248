import { Route, Routes, Navigate } from "react-router-dom"
import { Fragment } from "react"

import PageProfile from "pages/user/Profile"

import ScrollToTop from "components/ScrollToTop"
import Footer from "components/Footer"


function UnapprovedView() {

    return (
        <Fragment>
            <ScrollToTop />
            <Routes>
                <Route path="/profile" element={<PageProfile />} />
                <Route path="*" element={<Navigate to="/profile" />} />
            </Routes>
            <Footer />
        </Fragment>
    )
}

export default UnapprovedView