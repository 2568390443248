import Page from "components/Page"
import TarpitLogTable from "components/table/TarpitLog"
import { Typography } from "antd"
import { useMemo } from "react";
import useSearchParam from "hooks/useSearchParam";

const { Title } = Typography;

const defaultParams = {
    withPayload: true,
    withHeaders: true,
    withCompany: true,
    withThreat: true
}

function TarpitLog() {

    const [headerId] = useSearchParam("header_id");
    const [payloadId] = useSearchParam("payload_id");

    const initialParams = useMemo(
        () => ({
            ...defaultParams,
            headersId: headerId,
            payloadId
        }),
        [headerId, payloadId]
    );

    return (
        <Page className="page-dns-log">
            <TarpitLogTable
                before={<Title level={1}>Tarpit log</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                initialParams={initialParams}
                stateMode="both" />
        </Page>
    )

}

export default TarpitLog