import { useEffect } from "react"

function useHtmlClass(className) {

    useEffect(
        () => {
            if (className) {
                const clss = className.split(" ").map(c => c.trim()).filter(c => !!c);
                clss.forEach(cls => document.documentElement.classList.add(cls));
                return () => {
                    clss.forEach(cls => document.documentElement.classList.remove(cls));
                }
            }
        },
        // eslint-disable-next-line
        []
    );
}

export default useHtmlClass