import { LoadingOutlined } from "@ant-design/icons";


function InputLoadingIndicator({ loading }) {

    if (!loading) {
        return null;
    }

    return <LoadingOutlined />
}

export default InputLoadingIndicator