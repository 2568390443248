

import SelectorDialog from "./SelectorDialog"

import supabase from "lib/supabase/main"


function loadUsers(params) {
    return supabase.functions.invoke("backend", {
        method: "POST",
        body: {
            controller: "users/list",
            payload: params
        }
    }).then(response => {
        const { data: ctlData } = response;
        const { data, error } = ctlData || {};
        return { data: data.rows, count: data.count || 0, error };
    })
};


const columns = [
    {
        key: "email",
        dataIndex: "email",
        title: "Email"
    },
    {
        key: "name",
        dataIndex: "first_name",
        title: "Name",
        className: "table-fill",
        render: (_, row) => `${row.first_name} ${row.last_name}`
    },
];



function UserDialog(props) {

    return (
        <SelectorDialog
            title="Users"
            {...props}
            autoload
            columns={columns}
            loaderFn={loadUsers} />
    )
}

export default UserDialog