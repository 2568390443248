import { Select } from "antd"
import { useMemo } from "react"
import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"

async function getIps({ onlyAvailable = false }) {
    const resp = await supabase.from("tarpit_server_ip")
        .select("ip, tarpit_server(description, type), domain_settings(domain)");

    if (onlyAvailable) {
        resp.data = resp.data.filter(ip => {
            if (ip.tarpit_server.type === "iptrap") {
                return true;
            }
            return ip.domain_settings.length === 0
        });
        return resp;
    }
    return resp;
}

function IpSelect({ showDefault = false, onlyAvailable = false, ...rest }) {
    const { data: ips, isLoading } = useQuery(
        getIps,
        [onlyAvailable],
        {
            params: {
                onlyAvailable
            }
        }
    );
    const options = useMemo(
        () => {
            const options = ips.map(ip => ({
                key: ip.ip,
                value: ip.ip,
                label: `${ip.tarpit_server.description} (${ip.ip})`
            }));

            if (showDefault) {
                options.unshift({
                    key: "default",
                    value: null,
                    label: `Default Iptrap`
                })
            }

            return options;
        },
        [ips, showDefault]
    );

    return (
        <Select
            loading={isLoading}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            options={options}
            {...rest} />
    )
}

export default IpSelect