
import DomainExpandable from "components/DomainExpandable";
import supabase from "lib/supabase/main";
import useQuery from "hooks/useQuery";
import renderGMT from "lib/renderGMT";
import Page from "components/Page";
import { Table, Typography, Input, Button, Select } from "antd";
import { Portal } from "components/Portal";
import { useMemo, useCallback, useState } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import async from "lib/async";
import useDualState from "hooks/useDualState";
import useArrayToggle from "hooks/useArrayToggle";
import { downloadCsv } from "lib/csv"
import { mkConfig, generateCsv } from "export-to-csv";
import formatNumber from "lib/format/number";


const params = [
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "expiration",
        default: "expired",
        autoApply: true
    }
];

const expirationOptions = [
    {
        key: "expiring",
        value: "expiring",
        label: "Expiring"
    },
    {
        key: "expired",
        value: "expired",
        label: "Expired"
    },
];

const statusOptions = [
    {
        key: "",
        value: "",
        label: ""
    },
    {
        key: "registered",
        value: "registered",
        label: "Registered"
    },
    {
        key: "declined",
        value: "declined",
        label: "Declined"
    },
]

const columns = [
    {
        key: "domain",
        dataIndex: "domain",
        title: "Domain"
    },
    {
        key: "expiration_date",
        dataIndex: "expiration_date",
        title: "Expiration date",
        render: renderGMT
    },
    {
        key: "dns_log_counter",
        dataIndex: "dns_log_counter",
        title: "DNS requests",
        render: formatNumber
    },
    {
        key: "tarpit_log_counter",
        dataIndex: "tarpit_log_counter",
        title: "Data requests",
        render: formatNumber
    }
]


async function loadExpiringDomains({ page, pageSize, query, expiration, csv = false }) {
    const req = supabase.from("hunt_expiring_queue")
        .select("*", { count: "exact" })
        .order("expiration_date", { ascending: expiration !== "expired" })

    if (!csv) {
        req.range(page * pageSize, (page * pageSize) + pageSize - 1);
    }

    if (query) {
        req.ilike("domain", `%${query}%`);
    }
    if (expiration) {
        if (expiration === "expired") {
            req.lte("expiration_date", (new Date()).toISOString());
        }
        else if (expiration === "expiring") {
            req.gt("expiration_date", (new Date()).toISOString());
        }
    }

    const resp = await req;
    const domains = resp.data.map(row => row.domain);
    const sub = await supabase.from("threat_domain").select("*").in("domain", domains);
    sub.data.forEach(row => {
        const inx = resp.data.findIndex(r => r.domain === row.domain);
        if (inx !== -1) {
            resp.data[inx].dns_log_counter = row.dns_log_counter;
            resp.data[inx].tarpit_log_counter = row.tarpit_log_counter;
        }
    });

    return resp;
}

function ExpiringDomains() {

    const {
        expiration, setExpiration,
        query, setQuery, all,
        apply
    } = useDualState({ params, mode: "both" });

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [updating, setUpdating] = useArrayToggle();
    const [downloading, setDownloading] = useState(false);

    const { data, count, isLoading, reset, refetch } = useQuery(
        loadExpiringDomains,
        [page, pageSize, all],
        {
            params: { page, pageSize, query, expiration }
        }
    );

    const onDownloadClick = useCallback(
        async () => {
            setDownloading(true);
            const { data } = await loadExpiringDomains({
                page, pageSize, query, expiration,
                csv: true
            });
            const csvRows = data.map(row => ({
                domain: row.domain,
                expiration_date: row.expiration_date
            }));
            const csvConfig = mkConfig({ useKeysAsHeaders: true });
            const csv = generateCsv(csvConfig)(csvRows);
            downloadCsv(csv, "expiring_domains.csv");
            setDownloading(false);
        },
        [page, pageSize, query, expiration]
    );

    const renderExpanded = useCallback(
        (row) => (
            <div style={{ padding: 10, paddingTop: 0 }}>
                <DomainExpandable
                    domain={row.domain} />
            </div>
        ),
        []
    );

    const expandable = useMemo(
        () => ({
            expandedRowRender: renderExpanded,
            rowExpandable: () => true,
        }),
        [renderExpanded]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: 50,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [onPaginationChange, count, pageSize]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onSearchClick = useCallback(
        () => {
            reset();
            setPage(0);
            async(apply);
        },
        [setPage, apply, reset]
    );


    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const updateDomainStatus = useCallback(
        async (domain, status) => {
            setUpdating(domain, true);
            await supabase.from("hunt_expiring_queue")
                .update({ status: status || null })
                .eq("domain", domain);
            refetch();
            setUpdating(domain, false);
        },
        [refetch, setUpdating]
    );


    const tableColumns = useMemo(
        () => {
            const tableColumns = [
                ...columns
            ];

            if (expiration === "expired") {
                tableColumns.push({
                    className: "table-cell-collapse",
                    key: "status",
                    dataIndex: "status",
                    title: "Status",
                    render: (status, row) => {
                        const busy = updating.includes(row.domain);
                        return (
                            <Select
                                loading={busy}
                                disabled={busy}
                                value={status}
                                options={statusOptions}
                                onChange={(value) => updateDomainStatus(row.domain, value)} />
                        )
                    }
                })
            }

            return tableColumns;
        },
        [expiration, updating, updateDomainStatus]
    );

    return (
        <Page>
            <Portal host="header">
                <Typography.Title level={1}>Expiring domains</Typography.Title>
                <div className="toolbar">
                    <Select
                        options={expirationOptions}
                        value={expiration}
                        onChange={setExpiration}
                        size="large" />
                    <Input
                        allowClear
                        size="large"
                        onKeyDown={onKeyDown}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSearchClick}
                        children="Search" />
                    <Button
                        size="large"
                        type="text"
                        loading={downloading}
                        disabled={downloading}
                        icon={<DownloadOutlined />}
                        onClick={onDownloadClick}
                        children="Download csv" />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                tableLayout="auto"
                bordered
                rowKey="domain"
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                expandable={expandable}
                pagination={pagination} />
        </Page>
    )
}

export default ExpiringDomains