import { createContext, useCallback, useContext, useMemo } from "react"
import Context from "../Context"
import { default as validateValue } from "./validate"
import useKeys from "hooks/useKeys"

export const FormContext = createContext();


export class Form extends Context {

    fields = null

    constructor(fields = [], defaults = {}) {
        const data = {};

        fields = fields.map(f => {
            if (typeof f === "string") {
                return {
                    name: f
                }
            }
            return f;
        });

        fields.forEach(f => {
            const defaultsValue = typeof defaults[f.name] === "undefined" ? "" : defaults[f.name];
            const defaultValue = typeof f.default === "function" ? f.default() : f.default;
            data[f.name] = typeof defaultValue === "undefined" ? defaultsValue : defaultValue;
            data[f.name + "Error"] = null;
        });

        data["errorMode"] = false;

        super(data);
        this.fields = fields;
    }

    getField(name) {
        return this.fields.find(f => f.name === name);
    }

    getFieldNames() {
        return this.fields.map(f => f.name);
    }

    getErrors() {
        const errors = {};
        this.fields.forEach(f => {
            const err = this.get(f.name + 'Error');
            if (err) {
                errors[f.name] = err;
            }
        });
        return errors;
    }

    async validate(name, dryRun = false) {
        const field = typeof name === "string" ? this.getField(name) : name;
        if (!field) {
            return false;
        }
        let valid = true;
        if (field.validator) {
            const res = await validateValue(
                field.name,
                this.data[field.name],
                field.validator,
                { ...this.data }
            );
            if (!dryRun) {
                this.set(field.name + "Error", res);
            }

            if (res !== null) {
                valid = false;
            }
        }
        return valid;
    }

    async validateAll(dryRun = false) {
        let valid = true;
        for (const f of this.fields) {
            const res = await this.validate(f, dryRun);
            if (res !== true) {
                valid = false;
            }
        }

        if (!valid && !dryRun) {
            this.set("errorMode", true);
        }

        return valid;
    }

    resetAll(resetData = {}) {
        this.set("errorMode", false);
        this.fields.forEach(f => {
            this.set(f.name, f.default || resetData[f.name] || "");
            this.set(f.name + "Error", null);
        });
    }

    async set(name, value) {
        if (value && typeof value === "object" && value.target) {
            if (value.target.type === "checkbox") {
                value = value.target.checked;
            }
            else value = value.target.value;
        }
        const field = this.getField(name);

        super.set(name, value);

        if (field && this.data["errorMode"]) {
            if (field.validator) {
                const res = await validateValue(
                    field.name,
                    value,
                    field.validator,
                    { ...this.data }
                );
                this.set(name + "Error", res);
            }
        }
    }
}

function useFieldSetCallback(name, form) {
    return useCallback(
        (value) => form.set(name, value),
        [name, form]
    );
}
function useErrorSetCallback(name, form) {
    return useCallback(
        (error) => form.set(name + "Error", error),
        [name, form]
    );
}

export function useFormFields(incomingFields = [], ...rest) {

    const form = useContext(FormContext) ||
        (incomingFields instanceof Form ? incomingFields : null) ||
        (rest[0] instanceof Form ? rest[0] : null);

    const fields = useMemo(
        () => {
            if (typeof incomingFields === "string") {
                return [incomingFields, ...rest];
            }
            else if (Array.isArray(incomingFields) && incomingFields.length > 0) {
                return incomingFields;
            }
            else {
                return form.getFieldNames();
            }
        },
        // eslint-disable-next-line
        [incomingFields, form]
    );

    const keys = useMemo(
        () => [...fields, ...fields.map(name => name + "Error")],
        [fields]
    );

    const values = useKeys(keys, form);

    for (const name of fields) {
        // eslint-disable-next-line
        values[name + "Change"] = useFieldSetCallback(name, form);
        // eslint-disable-next-line
        values[name + "SetError"] = useErrorSetCallback(name, form);
    }

    return values;
}

export function useForm() {
    return useContext(FormContext);
}