import { Button, Popover } from "antd"
import AppContext from "context/AppContext";
import { useCallback, useContext } from "react"
import { Link } from "react-router-dom"


function CompanyLink({ id, name }) {

    const app = useContext(AppContext);

    const onAddToWatchlist = useCallback(
        () => {
            app.trigger("add-companies-to-watchlist", [{ id, name }]);
        },
        [app, id, name]
    );

    return (
        <Popover
            mouseEnterDelay={0.5}
            content={<Button children="Add to watchlist" onClick={onAddToWatchlist} />}>
            <Link to={`/reports/entity/${id}`}>{name}</Link>
        </Popover>
    )
}

export default CompanyLink