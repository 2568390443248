import Observable from "@kuindji/observable"

class Context extends Observable {

    data = {}

    constructor(data) {
        super();
        Object.assign(this.data, data);
    }

    batch(fn) {

        const log = {};
        let change = [];
        const originalTrigger = this.trigger;
        this.trigger = (key, value) => {
            if (key === "change") {
                change = [...change, ...value];
                change = change.filter((key, inx, self) => self.indexOf(key) === inx);
            }
            else {
                log[key] = value;
            }
        }

        fn(this);

        this.trigger = originalTrigger;

        Object.keys(log).forEach(key => this.trigger(key, log[key]));
        if (change.length > 0) {
            this.trigger("change", change);
        }
    }

    set(key, value) {

        if (typeof key === "string") {
            if (this.data[key] !== value) {
                this.data[key] = value;
                this.trigger(key, value);
                this.trigger("change", [key]);
            }
        }
        else {
            const values = key;
            const keys = [];
            let hasChanges = false;
            Object.keys(values).forEach(key => {
                const value = values[key];
                if (this.data[key] !== value) {
                    this.data[key] = value;
                    keys.push(key);
                    hasChanges = true;
                    this.trigger(key, value);
                }
            })
            hasChanges && this.trigger("change", keys);
        }
    }

    get(key) {
        if (typeof key === "string") {
            return this.data[key];
        }
        else {
            const slice = {};
            key.forEach(k => {
                slice[k] = this.data[k]
            })
            return slice;
        }
    }

    getData() {
        return { ...this.data };
    }

    isEmpty() {
        const keys = Object.keys(this.data);
        if (keys.length === 0) {
            return true;
        }
        return keys.map(k => this.data[k]).filter(v => v !== null).length === 0;
    }
}

export default Context