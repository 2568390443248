import { Select } from "antd"

import { useCallback, useMemo } from "react"

import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery";

async function fetchCompanies() {
    const graph = `id, name`;
    return supabase.from("company").select(graph);
}


function CompanySelect({ onLoad, ...rest }) {

    const { data: companies } = useQuery(
        fetchCompanies,
        [],
        {
            onLoad
        }
    );

    const options = useMemo(
        () => companies.map(c => ({ label: c.name, value: c.id })),
        [companies]
    );

    const optionsFilter = useCallback(
        (query, { label, value }) => {
            return label?.toLowerCase().includes(query.toLowerCase()) ||
                value?.toLowerCase() === query.toLowerCase();
        },
        []
    );

    return (
        <Select
            filterOption={optionsFilter}
            options={options}
            {...rest} />
    )
}

export default CompanySelect