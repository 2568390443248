import { useCallback, useState } from "react"
import { useOn } from "@kuindji/observable-react"


function useKeys(keys, ctx) {
    const [value, setValue] = useState(() => ctx ? ctx.get(keys) : {});

    const onChange = useCallback(
        (changedKeys) => {
            const found = keys.find(k => changedKeys.indexOf(k) !== -1);
            if (found) {
                setValue(ctx ? ctx.get(keys) : {})
            }
        },
        [keys, ctx]
    );

    useOn(ctx, "change", onChange);

    return value;
}

export default useKeys