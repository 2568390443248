import { Fragment, useCallback, useMemo, useState } from "react";
import { Button, Table, Flex, Popconfirm, Modal, Tag, Checkbox, Tooltip, Typography } from "antd";
import { EditOutlined, DeleteOutlined, SyncOutlined } from "@ant-design/icons"
import moment from "moment";

import Page from "components/Page"
import IpSelect from "components/selector/IpSelect";
import DomainSelect from "components/selector/DomainSelect"
import InputContainer from "components/form/InputContainer"

import { Form, useFormFields } from "lib/form/Form"
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main"
import required from "lib/form/validator/required"
import renderGMT from "lib/renderGMT"
import { Portal } from "components/Portal";


const fields = [
    {
        name: "domain",
        validator: [
            [required, "Please select domain"]
        ]
    },
    {
        name: "target",
        validator: [[required, "Please select ip"]]
    },
    {
        name: "collectHeaders"
    },
    {
        name: "collectPayloads"
    },
    {
        name: "collectOnlyAccepted"
    },
    {
        name: "collectGzippedPayloads"
    },
    {
        name: "collectHttpsPayloads"
    },
    {
        name: "enabled"
    },
]

async function getServers() {
    return await supabase.from("tarpit_server").select();
}

async function getIps() {
    return await supabase.from("tarpit_server_ip").select();
}

async function getDomains() {
    return await supabase.from("domain_settings")
        .select(`domain, target, cert_expires_at, cert_generation_started_at, 
                                collect_headers, collect_payloads, collect_only_accepted, 
                                collect_gzipped_payloads, collect_https_payloads, enabled`);
}

async function deleteDomain(d) {
    return supabase.from("domain_settings").delete().eq("domain", d);
}

function DomainForm({ onClose, onSave, record }) {
    const form = useMemo(
        () => new Form(fields, {
            domain: record?.domain,
            target: record?.target,
            enabled: record ? record.enabled : true,
            collectHeaders: record ? record.collect_headers : true,
            collectPayloads: record ? record.collect_payloads : true,
            collectOnlyAccepted: record ? record.collect_only_accepted : true,
            collectGzippedPayloads: record ? record.collect_gzipped_payloads : true,
            collectHttpsPayloads: record ? record.collect_https_payloads : true
        }),
        // eslint-disable-next-line
        []
    );
    const [saving, setSaving] = useState(false);

    const {
        domain, domainChange, domainError,
        target, targetChange, targetError,
        collectHeaders, collectHeadersChange, collectHeadersError,
        collectPayloads, collectPayloadsChange, collectPayloadsError,
        collectOnlyAccepted, collectOnlyAcceptedChange, collectOnlyAcceptedError,
        collectGzippedPayloads, collectGzippedPayloadsChange, collectGzippedPayloadsError,
        collectHttpsPayloads, collectHttpsPayloadsChange, collectHttpsPayloadsError,
        enabled, enabledChange
    } = useFormFields(form);

    const onCollectPayloadsChange = useCallback(
        (e) => {
            const value = e.target.checked;
            collectPayloadsChange(value);
            collectGzippedPayloadsChange(value);
            collectHttpsPayloadsChange(value);
        },
        [collectPayloadsChange, collectGzippedPayloadsChange, collectHttpsPayloadsChange]
    );

    const onSubmit = useCallback(
        async () => {

            const valid = await form.validateAll();
            if (!valid) {
                return;
            }

            setSaving(true);

            if (record) {
                await supabase.from("domain_settings")
                    .update({
                        target,
                        collect_headers: collectHeaders,
                        collect_payloads: collectPayloads,
                        collect_only_accepted: collectOnlyAccepted,
                        collect_gzipped_payloads: collectGzippedPayloads,
                        collect_https_payloads: collectHttpsPayloads,
                        enabled
                    })
                    .eq("domain", record.domain);
            }
            else {
                await supabase.from("domain_settings").insert({
                    domain,
                    target,
                    collect_headers: collectHeaders,
                    collect_payloads: collectPayloads,
                    collect_only_accepted: collectOnlyAccepted,
                    collect_gzipped_payloads: collectGzippedPayloads,
                    collect_https_payloads: collectHttpsPayloads,
                    enabled
                });
            }

            setSaving(false);
            onSave();
            onClose();
        },
        [form, onSave, onClose, record, target, domain,
            collectHeaders, collectPayloads, collectOnlyAccepted,
            collectGzippedPayloads, collectHttpsPayloads, enabled]
    );

    return (
        <Flex vertical gap="0.5rem">
            <InputContainer error={domainError}>
                <DomainSelect
                    disabled={!!record || saving}
                    value={domain}
                    onChange={domainChange}
                    placeholder="Domain"
                    style={{ width: "100%" }} />
            </InputContainer>
            <InputContainer error={targetError}>
                <IpSelect
                    showDefault
                    onlyAvailable
                    disabled={saving}
                    value={target}
                    onChange={targetChange}
                    placeholder="Server"
                    style={{ width: "100%" }} />
            </InputContainer>
            <InputContainer>
                <Tooltip title="Enable this domain" placement="left">
                    <Checkbox
                        checked={enabled}
                        onChange={enabledChange}
                        children="Domain enabled" />
                </Tooltip>
            </InputContainer>
            {target !== null &&
                <Fragment>
                    <InputContainer error={collectHeadersError}>
                        <Tooltip title="Collect both http and https headers" placement="left">
                            <Checkbox
                                checked={collectHeaders}
                                onChange={collectHeadersChange}
                                children="Collect headers" />
                        </Tooltip>
                    </InputContainer>
                    <InputContainer error={collectPayloadsError}>
                        <Tooltip
                            title="Collect both http and https payloads, gzipped or not"
                            placement="left">
                            <Checkbox
                                checked={collectPayloads}
                                onChange={onCollectPayloadsChange}
                                children="Collect payloads" />
                        </Tooltip>
                    </InputContainer>
                    {collectPayloads &&
                        <Flex vertical gap="0.5rem" style={{ marginLeft: '1rem' }}>
                            <InputContainer error={collectGzippedPayloadsError}>
                                <Tooltip title="Uncheck to skip gzipped payloads" placement="left">
                                    <Checkbox
                                        checked={collectGzippedPayloads}
                                        onChange={collectGzippedPayloadsChange}
                                        children="Collect gzipped payloads" />
                                </Tooltip>
                            </InputContainer>
                            <InputContainer error={collectHttpsPayloadsError}>
                                <Tooltip title="Uncheck to skip https payloads" placement="left">
                                    <Checkbox
                                        checked={collectHttpsPayloads}
                                        onChange={collectHttpsPayloadsChange}
                                        children="Collect https payloads" />
                                </Tooltip>
                            </InputContainer>
                        </Flex>}
                    <InputContainer error={collectOnlyAcceptedError}>
                        <Tooltip
                            title="Enables logging of requests which did not send any data"
                            placement="left">
                            <Checkbox
                                checked={collectOnlyAccepted}
                                onChange={collectOnlyAcceptedChange}
                                children="Collect only 'accepted' requests" />
                        </Tooltip>
                    </InputContainer>
                </Fragment>}

            <Button
                type="primary"
                loading={saving}
                disabled={saving}
                onClick={onSubmit}
                children="Save" />
        </Flex>
    )
}

function DomainModal({ open = false, onClose, onSave, record }) {


    return (
        <Modal
            title={!record ? "Add domain" : "Edit domain"}
            centered
            footer={null}
            open={open}
            onCancel={onClose}
            destroyOnClose>
            {open && <DomainForm onClose={onClose} onSave={onSave} record={record} />}
        </Modal>
    )
}

function PageDomains() {

    const [showForm, setShowForm] = useState(false);
    const [editRecord, setEditRecord] = useState(null);
    const { data: servers } = useQuery(getServers, []);
    const { data: ips } = useQuery(getIps, []);
    const { data: domains, isLoading, refetch } = useQuery(getDomains, []);

    const onDeleteDomain = useCallback(
        async (d) => {
            await deleteDomain(d);
            refetch();
        },
        [refetch]
    );


    const onEditClick = useCallback(
        (row) => {
            setEditRecord(row);
            setShowForm(true);
        },
        []
    );

    const columns = useMemo(
        () => {
            return [
                {
                    key: "domain",
                    dataIndex: "domain",
                    title: "Domain"
                },
                {
                    key: "target",
                    dataIndex: "target",
                    title: "Server",
                    render: (target) => {
                        if (!target) {
                            return "Default Iptrap"
                        }
                        const ip = ips.find(r => r.ip === target);
                        if (!ip) {
                            return target;
                        }
                        const serverId = ip.tarpit_server_id;
                        const server = servers.find(r => r.id === serverId);
                        if (!server) {
                            return target;
                        }
                        return server.description + ` (${target})`;
                    }
                },
                {
                    key: "certs",
                    dataIndex: "cert_expires_at",
                    title: "Certificates",
                    render: (expiresAt, row) => {
                        if (expiresAt) {
                            return (
                                <Tag color="green">Generated</Tag>
                            )
                        }
                        if (row.cert_generation_started_at) {
                            const startedAt = moment(row.cert_generation_started_at);
                            if (startedAt.isBefore(moment().subtract(15, "minutes"))) {
                                return (<Tag color="#f50">Failed</Tag>);
                            }
                            else {
                                return (<Tag color="blue">Generating</Tag>);
                            }
                        }
                    }
                },
                {
                    key: "cert_",
                    dataIndex: "cert_expires_at",
                    title: "Expires at",
                    render: renderGMT
                },
                {
                    key: "enabled",
                    dataIndex: "enabled",
                    title: "Enabled",
                    render: value => value ? 'Yes' : 'No'
                },
                {
                    key: "collect_headers",
                    dataIndex: "collect_headers",
                    title: "Headers",
                    render: value => value ? 'Yes' : 'No'
                },
                {
                    key: "collect_payloads",
                    dataIndex: "collect_payloads",
                    title: "Payloads",
                    render: value => value ? 'Yes' : 'No'
                },
                {
                    key: "collect_only_accepted",
                    dataIndex: "collect_only_accepted",
                    title: "Only accepted",
                    render: value => value ? 'Yes' : 'No'
                },
                {
                    key: "actions",
                    dataIndex: "domain",
                    title: "",
                    className: "table-cell-collapse",
                    render: (d, row) => {
                        return (
                            <Flex gap="small">
                                <Button
                                    onClick={() => onEditClick(row)}
                                    icon={<EditOutlined />}
                                    size="small" />
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => onDeleteDomain(d)}>
                                    <Button icon={<DeleteOutlined />} size="small" />
                                </Popconfirm>
                            </Flex>
                        )
                    }
                }
            ]
        },
        [ips, servers, onDeleteDomain, onEditClick]
    );

    const onAddClick = useCallback(
        () => {
            setShowForm(true);
        },
        []
    );


    const onFormClose = useCallback(
        () => {
            setShowForm(false);
            setEditRecord(null);
        },
        []
    )

    return (
        <Page>
            <Portal host="header">
                <div className="toolbar header-single-row">
                    <Typography.Title level={1}>Domains</Typography.Title>
                    <Button
                        className="toolbar-right"
                        size="large"
                        type="primary"
                        children="Add domain"
                        onClick={onAddClick} />
                    <Button
                        size="large"
                        type="text"
                        onClick={refetch}
                        disabled={isLoading}
                        icon={<SyncOutlined spin={isLoading} />} />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={domains.length === 0 && isLoading}
                dataSource={domains}
                columns={columns}
                rowKey="domain"
                pagination={false} />
            <DomainModal
                record={editRecord}
                open={showForm}
                onClose={onFormClose}
                onSave={refetch} />
        </Page>
    )
}

export default PageDomains