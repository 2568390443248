import { useCallback, useContext, useMemo, useState } from "react"
import { Button, Dropdown, Table, Tooltip, Typography } from "antd"
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons"


import useQuery from "hooks/useQuery"
import { loadIpRegistryCidr, columns } from "api/ipRegistryCidr"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { downloadCsv } from "lib/csv"
import AppContext from "context/AppContext"

const { Title } = Typography;

const PER_PAGE = 10;


function IpRegistryCompanyCidr({
    title = "CIDRs",
    showToolbar = true,
    showPagination = true,
    allowCsv = true,
    initialParams = {} }) {

    const app = useContext(AppContext);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PER_PAGE);
    const [downloading, setDownloading] = useState(false);

    const { data, isLoading, extraData, count } = useQuery(
        loadIpRegistryCidr,
        [page, pageSize, initialParams],
        {
            rowIdKey: "cidr",
            params: { page, pageSize, ...initialParams },
            initialData: []
        }
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const displayColumns = useMemo(
        () => {
            const fetchedColumns = extraData?.columns || [];
            const cs = columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1);
            cs.push({
                className: "table-cell-collapse",
                key: "add-to-watchlist",
                dataIndex: "cidr",
                title: "",
                render: (cidr) => {
                    const items = [
                        {
                            key: "btn-add-to-watchlist",
                            label: "Add to watchlist",
                            onClick: () => {
                                app.trigger("add-cidrs-to-watchlist", [cidr])
                            }
                        }
                    ]
                    return (
                        <Dropdown menu={{ items }} trigger="click">
                            <Button children="..." size="small" />
                        </Dropdown>
                    )
                }
            })
            return cs;
        },
        [app, extraData]
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadIpRegistryCidr({ ...initialParams, page, csv: true });
            downloadCsv(csv, "entity.csv");
            setDownloading(false);
        },
        [page, initialParams]
    );

    const pagination = useMemo(
        () => {
            if (!showPagination || count <= PER_PAGE) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [count, onPaginationChange, showPagination, pageSize]
    )

    return (
        <div className="table-ip-registry-company-cidr">
            {showToolbar &&
                <div className="toolbar">
                    {title && <Title level={4}>{title}</Title>}
                    {allowCsv &&
                        <Tooltip title="Export as CSV">
                            <a href="/#" onClick={onDownloadCsv} className="toolbar-right">
                                {downloading ? <SyncOutlined spin /> : <DownloadOutlined />}
                            </a>
                        </Tooltip>}
                </div>}
            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={displayColumns}
                rowKey="cidr"
                pagination={pagination} />
        </div>
    )
}

export default IpRegistryCompanyCidr