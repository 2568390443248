import { Typography, Spin } from "antd"
import Page from "components/Page"
import { Portal } from "components/Portal"

import RegistrationsByDate from "components/report/registrarLog/RegistrationsByDate"
import RegistrarLogTable from "components/table/RegistrarLog";
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main";
import formatNumber from "lib/format/number";
import useDualState from "hooks/useDualState";

const { Title } = Typography;

const params = [
    {
        name: "country",
        default: null
    },
];

async function loadGeneralStats() {
    const { data } = await supabase.from("general_stats").select();
    return { data: data[0] || null };
}

function RegistrarLog() {

    const { country } = useDualState({ params, mode: "query" });
    const { data, isLoading } = useQuery(
        loadGeneralStats,
        [country],
        {
            enabled: !country
        }
    );

    // const registrationsChartOptions = useMemo(
    //     () => ({
    //         title: {
    //             offsetX: 46,
    //             offsetY: 32,
    //             floating: true,
    //             text: "DOMAIN REGISTRATIONS",
    //             style: {
    //                 fontWeight: "300",
    //                 fontSize: "11px"
    //             }
    //         }
    //     }),
    //     []
    // );

    return (
        <Page className="page-registrar-log" htmlClassName="html-page-registrar-log">
            <Portal host="header">
                <Title level={1}>Domains</Title>
                {!country &&
                    <div className="page-general-stats dashboard-general-stats">
                        <Spin spinning={isLoading}>
                            <div className="dashboard-general-stats-item">
                                <div className="stats-card-front">
                                    <h4>{data ? formatNumber(data.active_registered_domain_count) : <>&nbsp;</>}</h4>
                                    <p>Total active domain registrations</p>
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={isLoading}>
                            <div className="dashboard-general-stats-item">
                                <div className="stats-card-front">
                                    <h4>{data ? formatNumber(data.registered_domain_count) : <>&nbsp;</>}</h4>
                                    <p>Total domain registrations</p>
                                </div>
                            </div>
                        </Spin>
                        <Spin spinning={isLoading}>
                            <div className="dashboard-general-stats-item">
                                <div className="stats-card-front">
                                    <h4>{data ? formatNumber(data.registrant_email_count) : <>&nbsp;</>}</h4>
                                    <p>Total registrant emails</p>
                                </div>
                            </div>
                        </Spin>
                    </div>}
                <RegistrationsByDate
                    title={null}
                    showToolbar={false}
                    chartType="bar"
                    height={250}
                    country={country}
                // chartOptions={registrationsChartOptions} 
                />
            </Portal>
            <RegistrarLogTable
                //before={<Title level={1}>Domains</Title>}
                //toolbarPortal="header"
                stateMode="both"
                toolbarSize="large" />
        </Page>
    )
}

export default RegistrarLog