
export default function dom_isAttached(node) {

    if (node === window) {
        return true;
    }
    if (node.nodeType === window.document.TEXT_NODE) {
        if (node.parentElement) {
            return dom_isAttached(node.parentElement);
        }
        else {
            return true;
        }
    }

    var html = window.document.documentElement;

    return node === html ? true : html.contains(node);
};
