import { Button } from "antd"
import { useCallback } from "react"
import { useNavigate } from "react-router-dom";

function ButtonLink({ to, ...rest }) {

    const navigate = useNavigate();

    const onClick = useCallback(
        () => {
            navigate(to);
        },
        [navigate, to]
    );

    return (
        <Button onClick={onClick} {...rest} />
    )
}

export default ButtonLink