
export function access2keys(user) {

    if (!user) {
        return [];
    }

    let keys = [];
    const accessKeys = ["hunt", "registry", "logs", "report", "registrar"];

    accessKeys.forEach(key => {
        if (!user[key]) {
            return;
        }
        const sub = Object.keys(user[key]);
        sub.forEach(subkey => {
            keys.push(`${key}/${subkey}`);
        })
    })

    return keys;
}

export function keys2access(keys) {
    const access = {};
    keys.forEach(key => {
        const parts = key.split("/");
        if (!access[parts[0]]) {
            access[parts[0]] = {};
        }
        access[parts[0]][parts[1]] = true;
    })
    return access;
}
