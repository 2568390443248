import { Select } from "antd"

export const options = [
    {
        key: "requested",
        value: "requested",
        label: "Requested"
    },
    {
        key: "review",
        value: "review",
        label: "Under review"
    },
    {
        key: "accepted",
        value: "accepted",
        label: "Accepted"
    },
    {
        key: "down",
        value: "down",
        label: "Taken down"
    },
    {
        key: "cancelled",
        value: "cancelled",
        label: "Cancelled"
    }
];

export function getOptionName(value) {
    return options.find(o => o.value === value)?.label;
}

function TakedownStatus({ ...rest }) {

    return (
        <Select
            options={options}
            {...rest} />
    )
}

export default TakedownStatus