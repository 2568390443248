import { useCallback, useState } from "react"


function useArrayToggle() {

    const [state, setState] = useState([]);
    const toggle = useCallback(
        (key, state) => {
            setState(prev => {
                const inx = prev.indexOf(key);
                const next = [...prev];
                if (state && inx === -1) {
                    next.push(key);
                }
                else if (!state && inx !== -1) {
                    next.splice(inx, 1);
                }
                return next;
            })
        },
        []
    );

    return [state, toggle];
}

export default useArrayToggle