import { useCallback, useMemo, useState } from "react"
import { Input, Table, Tooltip, Button, Select, Badge, Space } from "antd"
import { SearchOutlined, DownloadOutlined, SyncOutlined, CheckOutlined } from "@ant-design/icons"
import moment from "moment"

import { ThreatTypeSelector } from "./ThreatFoxIoc"
import CountrySelector from "components/selector/CountrySelect"
import DomainExpandable from "components/DomainExpandable"
import HuntReportFormDialog from "components/hunt/HuntReportForm"

import useDualState from "hooks/useDualState"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useUpdateEffect from "hooks/useUpdateEffect"
import useQuery from "hooks/useQuery"

import async from "lib/async"
import downloadCsv from "lib/downloadCsv"

import { loadRegistrarHunt, columns } from "api/registrarHunt"
import { Portal } from "components/Portal"
import useAppKey from "hooks/useAppKey"

const queryOptions = [
    { value: "domain", label: "Domain" },
    { value: "malware", label: "Malware" },
    { value: "tags", label: "Tags" }
];

const sourceOptions = [
    { value: "", label: "All sources" },
    { value: "endurance", label: "Endurance" },
    { value: "namesilo", label: "Namesilo" }
];


const params = [
    {
        name: "country",
        default: null
    },
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    },
    {
        name: "iocType",
        default: null
    },
    {
        name: "threatType",
        default: null
    },
    {
        name: "source",
        default: null
    }
];


function RegistrarHunt({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowCsv = true,
    allowMore = true,
    newItemsSince = null,
    onResetNewItems = null,
    initialParams = {}
}) {

    const {
        query, setQuery,
        queryBy, setQueryBy,
        threatType, setThreatType,
        country, setCountry,
        source, setSource,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });
    const [page, setPage] = useState(0);
    const [downloading, setDownloading] = useState(false);
    const [resettingsNewItems, setResettingNewItems] = useState(false);
    const [queueInitialData, setQueueInitialData] = useState(null);
    const isAdmin = useAppKey("isAdmin");
    const isRegistrar = useAppKey("isRegistrar");

    const { data, isLoading, reset, refetch, extraData = {} } = useQuery(
        loadRegistrarHunt,
        [all, page, newItemsSince],
        {
            rowIdKey: "domain",
            params: { ...initialParams, page, ...all },
            initialData: [],
            append: true
        }
    );
    const { columns: fetchedColumns = [], hasMore } = extraData;

    const onResetNewItemsClick = useSwallowEventCallback(
        () => {
            setResettingNewItems(true);
            onResetNewItems && onResetNewItems();
        },
        []
    );

    useUpdateEffect(
        () => setResettingNewItems(false),
        [newItemsSince]
    );

    const dataRows = useMemo(
        () => {
            if (newItemsSince) {
                const since = moment(newItemsSince).utc();
                let found = false;
                const dataRows = [...data];
                for (let i = 0, l = dataRows.length; i < l; i++) {
                    const ts = moment(dataRows[i].first_seen_at).utc();
                    if (ts.isAfter(since)) {
                        found = true;
                    }
                    else {
                        if (found) {
                            dataRows[i - 1] = {
                                ...dataRows[i - 1],
                                new_items_mark: true
                            }
                            break;
                        }
                    }
                }
                return dataRows;
            }
            return data;
        },
        [data, newItemsSince]
    );

    const onCreateReportClick = useCallback(
        (domain) => {
            setQueueInitialData({ domain });
        },
        []
    );

    const onReportFormClose = useCallback(
        () => {
            setQueueInitialData(null);
        },
        []
    );

    const onReportFormSave = useCallback(
        () => {
            setQueueInitialData(null);
            refetch();
        },
        [refetch]
    );

    const dataColumns = useMemo(
        () => {
            const dataColumns = columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1);

            dataColumns.push({
                key: "actions",
                dataIndex: "domain",
                title: "",
                className: "table-cell-collapse",
                render: (domain, row) => {
                    const action =
                        (isAdmin || isRegistrar) ?
                            (!row.exists_in_queue && !row.exists_in_reports) ?
                                (<Tooltip title="Submit to hunt queue">
                                    <Button
                                        size="small"
                                        icon={<CheckOutlined />}
                                        onClick={() => onCreateReportClick(domain)} />
                                </Tooltip>) :
                                null :
                            null;
                    if (row.new_items_mark) {
                        return (
                            <Badge.Ribbon
                                text={(
                                    <Space>
                                        {resettingsNewItems && <SyncOutlined spin={true} />}
                                        <a href="/#"
                                            onClick={onResetNewItemsClick}>↑ New</a>
                                    </Space>
                                )}
                                color="red"
                                style={{ right: -17, top: 0 }}>
                                {action}
                            </Badge.Ribbon>
                        )
                    }
                    else {
                        return action;
                    }
                }
            })

            return dataColumns;
        },
        [fetchedColumns, resettingsNewItems,
            onResetNewItemsClick, onCreateReportClick,
            isAdmin, isRegistrar]
    );

    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    const loadNextPage = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        [setPage]
    );

    const onSearchClick = useCallback(
        () => {
            reset();
            setPage(0);
            async(apply);
        },
        [reset, apply, setPage]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadRegistrarHunt({ ...all, page, csv: true });
            downloadCsv(csv, "registrar_hunt.csv");
            setDownloading(false);
        },
        [page, all]
    );

    const renderExpanded = useCallback(
        (row) => (
            <div style={{ padding: 10, paddingTop: 0 }}>
                <DomainExpandable
                    domain={row.domain} />
            </div>
            //<HuntDetails entry={ row }/>
        ),
        []
    );

    return (
        <div className="threatfox-ioc">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">

                        <CountrySelector
                            placeholder="Country"
                            size={toolbarSize}
                            allowClear
                            showSearch
                            value={country}
                            onChange={setCountry} />
                        <ThreatTypeSelector
                            allowClear
                            size={toolbarSize}
                            value={threatType}
                            onChange={setThreatType}
                            placeholder="Threat Type" />
                        <Select
                            allowClear
                            size={toolbarSize}
                            options={sourceOptions}
                            value={source}
                            onChange={setSource}
                            placeholder="Sources" />
                        <Input
                            addonBefore={
                                <Select
                                    placeholder="Query by"
                                    size={toolbarSize}
                                    value={queryBy}
                                    onChange={setQueryBy}
                                    options={queryOptions} />
                            }
                            allowClear
                            size={toolbarSize}
                            onKeyDown={onKeyDown}
                            prefix={<SearchOutlined />}
                            onChange={onQueryChange}
                            value={query} />

                        <Button
                            size={toolbarSize}
                            loading={isLoading}
                            disabled={isLoading}
                            onClick={onSearchClick}
                            children="Search" />
                        {allowCsv &&
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={downloading ? <SyncOutlined spin /> : <DownloadOutlined />} />
                            </Tooltip>}
                    </div>
                </Portal>}
            <Table
                className="table-header-nowrap"
                sticky
                size="small"
                tableLayout="auto"
                bordered
                loading={data.length === 0 && isLoading}
                dataSource={dataRows}
                columns={dataColumns}
                rowKey="tioc_id"
                pagination={false}
                expandable={{
                    expandedRowRender: renderExpanded,
                    rowExpandable: () => true,
                }} />
            {(allowMore && hasMore) &&
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </>}
            <HuntReportFormDialog
                open={!!queueInitialData}
                initialData={queueInitialData}
                onClose={onReportFormClose}
                onSave={onReportFormSave} />
        </div>
    )
}

export default RegistrarHunt