import { useMemo } from "react"
import { Typography, Spin } from "antd"
import { useMatch } from "react-router-dom"

import Page from "components/Page"
import DnsLogTable from "components/table/DnsLog"
import HuntReportLogTable from "components/table/HuntReportLog"
import TarpitLogTable from "components/table/TarpitLog"
import CountryByDate from "components/report/CountryByDate"
import TopVictims from "components/report/TopVictims"

import useQuery from "hooks/useQuery"
import supabase from "lib/supabase/main"
import formatNumber from "lib/format/number"
import { Portal } from "components/Portal"
import useHtmlClass from "hooks/useHtmlClass"
import { getCountryNameWithFlag } from "components/CountryName"

const { Title } = Typography;

async function loadGeneralStats({ country }) {
    const { data, error } = await supabase.from("country").select().eq("country", country);
    return { data, error };
}

function Country() {

    useHtmlClass("page-country")
    const { params: { country } } = useMatch("/reports/country/:country");
    //const app = useContext(AppContext);

    const { data: stats, isLoading: statsLoading } = useQuery(
        loadGeneralStats,
        [country],
        {
            params: {
                country
            },
            prepare: data => data[0]
        }
    );

    const countryName = useMemo(
        () => getCountryNameWithFlag(country),
        [country]
    );

    const huntReportParams = useMemo(
        () => ({ country, withCount: false, distinct: true }),
        [country]
    );

    const tarpitParams = useMemo(
        () => ({ country, withPayload: true, withHeaders: true, withThreat: true, pageSize: 10 }),
        [country]
    );

    const dnsLogParams = useMemo(
        () => ({ country, pageSize: 10 }),
        [country]
    );

    const countryByDateParams = useMemo(
        () => ({ country }),
        [country]
    );

    const topVictimParams = useMemo(
        () => ({ country }),
        [country]
    );

    return (
        <Page className="page-country" htmlClassName="html-page-country">

            <Portal host="header">
                <Title level={1}>{countryName}</Title>
                <div className="page-general-stats dashboard-general-stats">
                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.ip_count) : <>&nbsp;</>}</h4>
                                <p>Victim IPs registered</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.dns_log_count) : <>&nbsp;</>}</h4>
                                <p>DNS requests</p>
                            </div>
                        </div>
                    </Spin>

                    <Spin spinning={statsLoading}>
                        <div className="dashboard-general-stats-item">
                            <div className="stats-card-front">
                                <h4>{stats ? formatNumber(stats.tarpit_log_count) : <>&nbsp;</>}</h4>
                                <p>Payload requests</p>
                            </div>
                        </div>
                    </Spin>
                </div>
                <CountryByDate
                    showToolbar={false}
                    chartType="bar"
                    params={countryByDateParams} />
            </Portal>

            <section>
                <TopVictims params={topVictimParams} bordered showCountrySelect={false} />
            </section>

            <section>
                <Title level={3}>Tarpit log</Title>
                <TarpitLogTable
                    ipLink
                    excludeQueryOptions={["country", "companyName", "companyDomain"]}
                    initialParams={tarpitParams} />
            </section>

            <section>
                <Title level={3}>Dns log</Title>
                <DnsLogTable
                    ipLink
                    excludeQueryOptions={["country", "companyName", "companyDomain"]}
                    initialParams={dnsLogParams} />
            </section>

            <section>
                <Title level={3}>Threats</Title>
                <HuntReportLogTable
                    showPagination={false}
                    showToolbar={false}
                    initialParams={huntReportParams} />
            </section>
        </Page>
    )
}

export default Country