import { useState } from "react"
import { useMatch } from "react-router-dom"
import { Typography, Spin } from "antd"

import Page from "components/Page"
import { Portal } from "components/Portal"
import ReportWebCheck from "components/report/WebCheck"


function PageWebcheck() {
    const { params: { domain } } = useMatch("/reports/scan/:domain");
    const [statuses, setStatuses] = useState([]);
    const loadingCount = statuses.filter(s => s.status === "loading").length;

    return (
        <Page className="page-web-check">
            <Portal host="header">
                <div className="header-single-row">
                    <Typography.Title level={1}>
                        {domain} <Spin spinning={loadingCount > 0} />
                    </Typography.Title>
                </div>
            </Portal>

            <ReportWebCheck
                domain={domain}
                onStatusUpdate={setStatuses} />
        </Page>
    )
}

export default PageWebcheck