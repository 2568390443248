import { useMemo, useState, useCallback } from "react"
import { Modal, Flex, Button, Typography, Input, Switch, App } from "antd"

import { Form, FormContext, useFormFields } from "lib/form/Form"
import InputContainer from "components/form/InputContainer"
import NullForm from "components/NullForm"
import AccessTree from "components/AccessTree"

import supabase from "lib/supabase/main"
import required from "lib/form/validator/required"
import validatePasswordSync from "lib/form/validator/password"
import isEmail from "lib/form/validator/isEmail"
import useUpdateEffect from "hooks/useUpdateEffect"

import { access2keys, keys2access } from "lib/access"

function validateNewPassword(pwd) {
    if (!pwd) {
        return true;
    }
    return validatePasswordSync(pwd);
}

async function emailValidator(email) {

    if (email) {
        const res = await supabase.functions.invoke("backend", {
            body: {
                controller: "users/checkEmail",
                payload: {
                    email
                }
            }
        });
        const found = res?.data?.found;
        return !found;
    }

    return false;
}

const newUserFields = [
    {
        name: "email",
        validator: [
            [required, "Please enter user's email"],
            [isEmail, "Please enter a valid email"],
            [emailValidator, "This email is already taken"]
        ]
    },
    {
        name: "password",
        validator: [
            [required, "Please enter user's password"],
            [validatePasswordSync, "Password is not strong enough"]
        ]
    },
];

const editUserFields = [
    {
        name: "password",
        validator: [
            [validateNewPassword, "Password is not strong enough"]
        ]
    },
]

const commonFields = [
    {
        name: "firstName"
    },
    {
        name: "lastName"
    },
    {
        name: "isAdmin"
    },
    {
        name: "isApproved"
    },
    {
        name: "isRegistrar"
    },
    {
        name: "isCompany"
    }
];

const accessTemplates = {
    hunt: {
        key: "hunt",
        title: "Hunt",
        children: [
            {
                leaf: true,
                key: "hunt/view",
                title: "View"
            }
        ]
    },
    registry: {
        key: "registry",
        title: "Registry",
        children: [
            {
                leaf: true,
                key: "registry/view",
                title: "View"
            }
        ]
    },
    report: {
        key: "report",
        title: "Reports",
        children: [
            {
                leaf: true,
                key: "report/view",
                title: "View"
            }
        ]
    },
    logs: {
        key: "logs",
        title: "Logs",
        children: [
            {
                leaf: true,
                key: "logs/view",
                title: "View"
            }
        ]
    },
    registrar: {
        key: "registrar",
        title: "Registrar",
        children: [
            {
                leaf: true,
                key: "registrar/endurance",
                title: "Endurance"
            },
            {
                leaf: true,
                key: "registrar/namesilo",
                title: "Namesilo"
            }
        ]
    }
}

const accessTrees = {
    "approved": [
        accessTemplates.registry,
        accessTemplates.hunt,
        accessTemplates.logs,
        accessTemplates.report
    ],
    "company": [
        accessTemplates.registry,
        accessTemplates.hunt,
        accessTemplates.logs,
        accessTemplates.report
    ],
    "registrar": [
        accessTemplates.registrar
    ]
}

function setUserClaim(id, name, value) {
    return supabase.rpc("set_claim", {
        uid: id,
        claim: name,
        value
    });
}


async function saveUser(form, exUser, accessKeys) {
    const payload = form.get(["email", "password", "firstName", "lastName",
        "isAdmin", "isApproved", "isRegistrar", "isCompany"]);


    if (exUser) {
        const id = exUser.id;
        const { password, firstName, lastName,
            isAdmin, isApproved, isRegistrar, isCompany } = payload;
        const userData = {
            first_name: firstName,
            last_name: lastName
        }
        const { error: profileError } = await supabase.from("user_profile")
            .update(userData).eq("id", id);
        if (profileError) {
            return { error: profileError };
        }

        if (!!isAdmin !== exUser.isAdmin) {
            const { error: adminError } = await setUserClaim(id, "is_admin", !!isAdmin);
            if (adminError) {
                return { error: adminError }
            }
        }
        if (!!isApproved !== exUser.isApproved) {
            const { error: approvedError } = await setUserClaim(id, "is_approved", !!isApproved);
            if (approvedError) {
                return { error: approvedError }
            }
        }
        if (!!isRegistrar !== exUser.isRegistrar) {
            const { error: registrarError } = await setUserClaim(id, "is_registrar", !!isRegistrar);
            if (registrarError) {
                return { error: registrarError }
            }
        }
        if (!!isCompany !== exUser.isCompany) {
            const { error: companyError } = await setUserClaim(id, "is_company", !!isCompany);
            if (companyError) {
                return { error: companyError }
            }
        }

        if (password) {
            const { error: passwordError } = await supabase.functions.invoke("backend", {
                method: "POST",
                body: {
                    controller: "users/setPassword",
                    payload: { id, password }
                }
            });
            if (passwordError) {
                return { error: passwordError }
            }
        }

        const accessTree = isAdmin ?
            {} :
            keys2access(accessKeys);
        await supabase.from("user_access").update({
            hunt: null,
            registry: null,
            report: null,
            logs: null,
            registrar: null,
            ...accessTree
        }).eq("id", exUser.id);

        return { error: null };
    }
    else {
        const { isAdmin/*, isRegistrar*/ } = payload;
        const { data, error } = await supabase.functions.invoke("backend", {
            method: "POST",
            body: {
                controller: "users/create",
                payload
            }
        });
        if (data && data.id) {
            const accessTree = isAdmin ?
                {} :
                keys2access(accessKeys);
            await supabase.from("user_access").update({
                hunt: null,
                registry: null,
                report: null,
                logs: null,
                registrar: null,
                ...accessTree
            }).eq("id", data.id);
        }
        return { data, error };
    }
}


function UserForm({ onCancel, onSave, user }) {

    const { notification } = App.useApp();
    const editMode = useMemo(() => !!user?.id, [user]);
    const formFields = useMemo(
        () => {
            return [
                ...(editMode ? editUserFields : newUserFields),
                ...commonFields
            ]
        },
        [editMode]
    );
    // eslint-disable-next-line
    const form = useMemo(() => new Form(formFields, user), []);
    const {
        email, emailChange, emailError,
        password, passwordChange, passwordError,
        firstName, firstNameChange, firstNameError,
        lastName, lastNameChange, lastNameError,
        isApproved, isApprovedChange,
        isAdmin, isAdminChange,
        isRegistrar, isRegistrarChange,
        isCompany, isCompanyChange
    } = useFormFields(["email", "password", "firstName", "lastName",
        "isApproved", "isAdmin", "isRegistrar", "isCompany"], form)
    const [submitting, setSubmitting] = useState(false);

    const [access, setAccess] = useState(() => access2keys(user));
    const accessTree = useMemo(
        () => {
            if (isApproved && !isAdmin && !isRegistrar) {
                return [...(isCompany ? accessTrees.company : accessTrees.approved)]
            }
            if (isApproved && isRegistrar) {
                return [...accessTrees.registrar]
            }
            return null;
        },
        [isApproved, isAdmin, isRegistrar, isCompany]
    );

    const onSubmitClick = useCallback(
        async () => {

            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await form.validateAll();

            if (valid) {
                try {
                    const { data, error } = await saveUser(form, user, access);
                    onSave && onSave();

                    if (error) {
                        console.log(error)
                        notification.error({ message: error.message || "Failed to create a user" });
                    }
                    else if (data?.error) {
                        console.log(data.error)
                        notification.error({ message: data.error.message || "Failed to create a user" });
                    }
                    else {
                        onSave();
                        notification.success({ message: "User created", duration: 2 });
                    }
                }
                catch (err) {
                    console.error(err);
                    notification.error({ message: err.message || "Failed to create a user" });
                }
            }

            setSubmitting(false);
        },
        [onSave, form, submitting, user, notification, access]
    );


    const onCancelClick = useCallback(
        () => {
            form.resetAll();
            onCancel && onCancel();
        },
        [onCancel, form]
    );

    useUpdateEffect(
        () => {
            if ((isAdmin || isRegistrar) && !isApproved) {
                isApprovedChange(true);
            }
        },
        [isAdmin, isApproved, isRegistrar]
    );

    useUpdateEffect(
        () => {
            if (!isApproved || isAdmin || isRegistrar || isCompany) {
                setAccess(access2keys(user));
            }
        },
        [isAdmin, isApproved, isRegistrar, isCompany]
    );

    useUpdateEffect(
        () => {
            if (isRegistrar && isAdmin) {
                isAdminChange(false);
                isCompanyChange(false);
            }
        },
        [isRegistrar]
    );

    useUpdateEffect(
        () => {
            if (isAdmin && isRegistrar) {
                isRegistrarChange(false);
                isCompanyChange(false);
            }
        },
        [isAdmin]
    );

    useUpdateEffect(
        () => {
            if (isCompany) {
                isAdminChange(false);
                isRegistrarChange(false);
            }
        },
        [isCompany]
    );

    useUpdateEffect(
        () => setAccess(access2keys(user)),
        [user]
    );

    return (
        <FormContext.Provider value={form}>
            <NullForm useFlex vertical gap="middle">

                {!editMode &&
                    <InputContainer
                        error={emailError}
                        placeholder="Email"
                        value={email}
                        onChange={emailChange}
                        autoComplete="off" />}
                <InputContainer
                    InputComponent={Input.Password}
                    error={passwordError}
                    placeholder={editMode ? "New password" : "Password"}
                    value={password}
                    onChange={passwordChange}
                    autoComplete="new-password" />
                <InputContainer
                    error={firstNameError}
                    placeholder="First name"
                    value={firstName}
                    onChange={firstNameChange} />
                <InputContainer
                    error={lastNameError}
                    placeholder="Last name"
                    value={lastName}
                    onChange={lastNameChange} />
                <Flex gap="small">
                    <Switch checked={isApproved} onChange={isApprovedChange} />
                    <Typography.Text>Is approved</Typography.Text>
                </Flex>
                <Flex gap="small">
                    <Switch checked={isAdmin} onChange={isAdminChange} />
                    <Typography.Text>Is admin</Typography.Text>
                </Flex>
                <Flex gap="small">
                    <Switch checked={isRegistrar} onChange={isRegistrarChange} />
                    <Typography.Text>Is registrar</Typography.Text>
                </Flex>
                <Flex gap="small">
                    <Switch checked={isCompany} onChange={isCompanyChange} />
                    <Typography.Text>Is company user</Typography.Text>
                </Flex>

                {accessTree && (
                    <AccessTree
                        accessTree={accessTree}
                        value={access}
                        onChange={setAccess} />
                )}

                <Flex justify="right" gap="small">
                    <Button
                        type="text"
                        children="Cancel"
                        disabled={submitting}
                        onClick={onCancelClick} />
                    <Button
                        children="Save"
                        type="primary"
                        loading={submitting}
                        disabled={submitting}
                        onClick={onSubmitClick} />
                </Flex>
            </NullForm>
        </FormContext.Provider>
    )
}

function UserFormDialog({ open, onClose, onSave, user }) {

    const onFormSave = useCallback(
        () => {
            onClose && onClose();
            onSave && onSave();
        },
        [onClose, onSave]
    );
    const onFormCancel = useCallback(
        () => {
            onClose && onClose();
        },
        [onClose]
    );

    return (
        <Modal
            title={user ? `${user.firstName} ${user.lastName}` : "Create user"}
            open={open}
            closable={false}
            centered
            maskClosable={false}
            destroyOnClose
            footer={null}>
            <UserForm
                onCancel={onFormCancel}
                onSave={onFormSave}
                user={user} />
        </Modal>
    )
}

export default UserFormDialog