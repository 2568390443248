import supabase from "lib/supabase/main";
import { data2csv, downloadCsv } from "lib/csv";

const PER_PAGE = 1000;

const columns = [
    { dataIndex: "type" },
    { dataIndex: "name" },
    { dataIndex: "description" },
    { dataIndex: "last_checked" },
    { dataIndex: "ip" },
    { dataIndex: "dns" },
    { dataIndex: "tarpit" },
    { dataIndex: "ip_county" },
    { dataIndex: "ip_last_dns_match" },
    { dataIndex: "ip_dns_requests" },
    { dataIndex: "ip_last_tarpit_match" },
    { dataIndex: "ip_tarpit_requests" }
]

async function loadWatclistContentsPage({ watchlistId, table, offset = 0 }) {

    let graph = "*";

    if (table === "watchlist_company") {
        graph += ", entity(name, domain)"
    }
    else {
        graph += `, ip_source:ip(ip, last_dns_log_match, last_tarpit_log_match, 
                        dns_log_counter, tarpit_log_counter, 
                        country)`
    }

    const req = supabase.from(table)
        .select(graph)
        .eq("watchlist_id", watchlistId)
        .range(offset, offset + PER_PAGE);

    if (table !== "watchlist_company") {
        req.not("ip_reference", "is", null);
        req.order("last_tarpit_log_match", { foreignTable: "ip", ascending: false });
        req.order("last_dns_log_match", { foreignTable: "ip", ascending: false });
    }
    else {
        req.eq("matched", true);
    }

    const { data } = await req;

    return data;
}

async function loadWatclistContents({ watchlistId, table }) {

    let all = [];
    let offset = 0;

    while (true) {
        try {
            const data = await loadWatclistContentsPage({ watchlistId, table, offset });
            all = [...all, ...data];
            if (data.length < PER_PAGE) {
                break;
            }
        }
        catch (err) {
            console.error(err);
            break;
        }
    }

    return all;
};

async function exportMatches(id) {

    const company = await loadWatclistContents({ watchlistId: id, table: "watchlist_company" });
    const ip = await loadWatclistContents({ watchlistId: id, table: "watchlist_ip" });
    const cidr = await loadWatclistContents({ watchlistId: id, table: "watchlist_cidr" });
    const domain = await loadWatclistContents({ watchlistId: id, table: "watchlist_domain" });
    const all = [];

    company.forEach(row => {
        all.push({
            type: "Entity",
            name: row.entity.name,
            description: row.entity.domain,
            last_checked: row.last_checked_at,
            ip: "",
            dns: row.metadata?.dns_log || false,
            tarpit: row.metadata?.tarpit_log || false,
            ip_county: "",
            ip_last_dns_match: "",
            ip_dns_requests: "",
            ip_last_tarpit_match: "",
            ip_tarpit_requests: ""
        });
    });

    ip.forEach(row => {
        all.push({
            type: "IP",
            name: row.ip,
            description: "",
            last_checked: row.last_checked_at,
            ip: row.ip,
            dns: row.metadata?.dns_log || false,
            tarpit: row.metadata?.tarpit_log || false,
            ip_county: row.ip_source?.country,
            ip_last_dns_match: row.ip_source?.last_dns_log_match,
            ip_dns_requests: row.ip_source?.dns_log_counter,
            ip_last_tarpit_match: row.ip_source?.last_tarpit_log_match,
            ip_tarpit_requests: row.ip_source?.tarpit_log_counter
        });
    });

    cidr.forEach(row => {
        all.push({
            type: "CIDR",
            name: row.cidr,
            description: "",
            last_checked: row.last_checked_at,
            ip: row.ip_source?.ip,
            dns: row.metadata?.dns_log || false,
            tarpit: row.metadata?.tarpit_log || false,
            ip_county: row.ip_source?.country,
            ip_last_dns_match: row.ip_source?.last_dns_log_match,
            ip_dns_requests: row.ip_source?.dns_log_counter,
            ip_last_tarpit_match: row.ip_source?.last_tarpit_log_match,
            ip_tarpit_requests: row.ip_source?.tarpit_log_counter
        });
    });

    domain.forEach(row => {
        all.push({
            type: "Domain",
            name: row.domain,
            description: "",
            last_checked: row.last_checked_at,
            ip: row.ip,
            dns: row.metadata?.dns_log || false,
            tarpit: row.metadata?.tarpit_log || false,
            ip_county: row.ip_source?.country,
            ip_last_dns_match: row.ip_source?.last_dns_log_match,
            ip_dns_requests: row.ip_source?.dns_log_counter,
            ip_last_tarpit_match: row.ip_source?.last_tarpit_log_match,
            ip_tarpit_requests: row.ip_source?.tarpit_log_counter
        });
    });

    const csv = data2csv(columns, all);
    downloadCsv(csv, "watchlist.csv");
}

export default exportMatches