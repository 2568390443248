
import isAttached from "./isAttached.js"
import getScrollTop from "./getScrollTop.js"
import getScrollLeft from "./getScrollLeft.js"

export default function dom_getOffset(node, includeScroll = true) {

    let box = { top: 0, left: 0 },
        html = window.document.documentElement;

    // Make sure it's not a disconnected DOM node
    if (!isAttached(node) || node === window) {
        return box;
    }

    // Support: BlackBerry 5, iOS 3 (original iPhone)
    // If we don't have gBCR, just use 0,0 rather than error
    if (node.getBoundingClientRect) {
        box = node.getBoundingClientRect();
    }

    let st = includeScroll ? getScrollTop() : 0,
        sl = includeScroll ? getScrollLeft() : 0;

    return {
        top: box.top + st - html.clientTop,
        left: box.left + sl - html.clientLeft
    };
};