import { Button, Input, Flex } from "antd";
import useDictRef from "hooks/useDictRef";
import { Fragment, useCallback, useMemo } from "react";
import { PlusCircleFilled, MinusCircleOutlined } from "@ant-design/icons";

function InputArray({
    InputComponent = Input,
    values = [],
    onChange,
    inputProps = null,
    maxItems = null,
    ...rest }) {

    const ref = useDictRef({ values, onChange });

    const onSingleInputChange = useCallback(
        (e, inx) => {
            const newValues = [...ref.values];
            newValues[inx] = e.target.value;
            ref.onChange(newValues);
        },
        // eslint-disable-next-line
        []
    );

    const onSigneItemRemove = useCallback(
        (inx) => {
            const newValues = [...ref.values];
            newValues.splice(inx, 1);
            ref.onChange(newValues);
        },
        // eslint-disable-next-line
        []
    );

    function createOnChange(inx) {
        return (e) => onSingleInputChange(e, inx)
    }

    function createOnRemove(inx) {
        return () => onSigneItemRemove(inx)
    }

    const onAddValue = useCallback(
        () => {
            const newValues = [...ref.values];
            newValues.push("");
            ref.onChange(newValues);
        },
        // eslint-disable-next-line
        []
    );

    const inputs = useMemo(
        () => {
            const inputValues = values && values.length > 0 ? values : [""];
            return inputValues.map((value, inx) => {

                const props = (inputProps ? inputProps(inx, value) : {}) || {};
                const { suffix = null, ...rest2 } = props;

                return (
                    <Flex align="center" key={inx}>
                        <InputComponent
                            {...rest}
                            {...rest2}
                            flex="1"
                            value={value}
                            onChange={createOnChange(inx)}
                            suffix={
                                <Fragment>
                                    {suffix}
                                    {inputValues.length > 1 ?
                                        <Button
                                            icon={<MinusCircleOutlined />}
                                            type="text"
                                            size="small"
                                            onClick={createOnRemove(inx)} /> :
                                        null}
                                </Fragment>
                            } />
                        {(inx === inputValues.length - 1 &&
                            (maxItems === null || maxItems > inputValues.length)) &&
                            <Button
                                icon={<PlusCircleFilled />}
                                type="text"
                                onClick={onAddValue} />}
                    </Flex>
                )
            })
        },
        // eslint-disable-next-line
        [values, rest, inputProps, maxItems, onAddValue]
    );

    return (
        <Fragment>
            <Flex vertical gap="small">
                {inputs}
            </Flex>
        </Fragment>
    )
}

export default InputArray