import { Typography, Button, Table, Spin } from "antd"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import Chart from "react-apexcharts"
import moment from "moment"

import Page from "components/Page"
import CountrySelect from "components/selector/CountrySelect"

import supabase from "lib/supabase/main"
import formatNumber from "lib/format/number"
import countriesDict from "dict/countries.json"
import { getDefaultOptions, getDefaultSeries, DEFAULT_HEIGHT } from "hooks/useApexChart"
import { Portal } from "components/Portal"
import { getCountryNameWithFlag } from "components/CountryName"

async function loadTopCountries(options = {}) {

    const { data: { rows, columns }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_countries",
            options,
            replica: true
        },
        method: "POST"
    });

    return { data: rows, columns, error }
}

async function loadCountiesByDate(options = {}) {

    const after = moment().subtract(31, "day").format("YYYY-MM-DD");
    const { country } = options;
    const { data, error } = await supabase.from("country_date")
        .select()
        .in("country", country)
        .gt("date", after);

    return { data, error };
}

const generalColumns = [
    {
        key: "country",
        dataIndex: "country",
        title: "Country",
        render: country => {
            const name = getCountryNameWithFlag(country);
            return (
                <Link to={`/reports/country/${country}`} children={name} />
            )
        }
    },
    {
        key: "ip_count",
        dataIndex: "ip_count",
        title: "Ip count",
        render: formatNumber
    },
    {
        key: "dns_log_count",
        dataIndex: "dns_log_count",
        title: "Dns requests",
        render: formatNumber
    },
    {
        key: "tarpit_log_count",
        dataIndex: "tarpit_log_count",
        title: "Tarpit requests",
        render: formatNumber
    }
];

const chartConfigs = [
    {
        field: "ip_count",
        title: "Ip count"
    },
    {
        field: "dns_log_count",
        title: "Dns requests"
    },
    {
        field: "tarpit_log_count",
        title: "Tarpit requests"
    }
]

function PageReportsCountries() {

    const [countries, setCountries] = useState([]);
    const [stats, setStats] = useState([]);
    const [statsByDate, setStatsByDate] = useState([]);
    const [statsLoading, setStatsLoading] = useState(false);
    const [statsByDateLoading, setStatsByDateLoading] = useState(false);

    const loading = useMemo(
        () => statsByDateLoading || statsLoading,
        [statsByDateLoading, statsLoading]
    );

    const loadGeneralStats = useCallback(
        async (options) => {
            setStatsLoading(true);
            const { data } = await loadTopCountries(options);
            setStats(data);
            setStatsLoading(false);
        },
        []
    );

    const loadStatsByDate = useCallback(
        async (options) => {
            setStatsByDateLoading(true);
            const { data } = await loadCountiesByDate(options);
            setStatsByDate(data);
            setStatsByDateLoading(false);
        },
        []
    );

    const dates = useMemo(
        () => {
            return statsByDate.map(row => row.date)
                .filter((d, inx, self) => self.indexOf(d) === inx)
                .sort()
        },
        [statsByDate]
    );

    const charts = useMemo(
        () => {
            const countries = statsByDate.map(row => row.country)
                .filter((c, inx, self) => self.indexOf(c) === inx);

            return chartConfigs.map(cc => {

                const series = countries.map(c => {
                    const stats = statsByDate.filter(row => row.country === c);

                    return getDefaultSeries(
                        dates.map(date => {
                            return stats.find(row => row.date === date) || {
                                country: c,
                                ip_count: 0,
                                dns_log_count: 0,
                                tarpit_log_count: 0
                            }
                        }),
                        {
                            y: {
                                field: cc.field,
                                name: countriesDict.find(ct => ct.code === c).name
                            }
                        }
                    )
                });

                const options = getDefaultOptions(
                    dates.map(date => ({ date: (new Date(date)).getTime() }))
                );

                const chart = {
                    title: cc.title,
                    config: {
                        options,
                        series,
                        height: DEFAULT_HEIGHT,
                        type: "area"
                    }
                };
                return chart;
            })
        },
        [statsByDate, dates]
    );

    const onViewClick = useCallback(
        async () => {
            loadStatsByDate({ country: countries });
            loadGeneralStats({ country: countries, limit: false });
        },
        [countries, loadStatsByDate, loadGeneralStats]
    );

    useEffect(
        () => {
            setStatsLoading(true);
            loadTopCountries({ limit: 5 }).then(({ data }) => {
                const countries = data.map(row => row.country);
                setCountries(countries);
                setStats(data);
                setStatsLoading(false);
                loadStatsByDate({ country: countries });
            })
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Page className="page-reports-countries">
            <Portal host="header">
                <Typography.Title level={1}>Countries</Typography.Title>
                <div className="toolbar">
                    <CountrySelect
                        className="page-reports-countries-selector"
                        size="large"
                        placeholder="Select countries"
                        mode="multiple"
                        value={countries}
                        onChange={setCountries}
                        disabled={loading}
                        allowClear />
                    <Button
                        onClick={onViewClick}
                        loading={loading}
                        disabled={loading}
                        size="large"
                        children="View report" />
                </div>
            </Portal>
            <section className="gradient-section">
                <Typography.Title level={2}>General stats</Typography.Title>
                <Table
                    sticky
                    size="small"
                    loading={loading}
                    dataSource={stats}
                    columns={generalColumns}
                    rowKey="country"
                    pagination={false} />
            </section>

            {statsByDate.length > 0 &&
                charts.map((chart, inx) => (
                    <section key={inx}>
                        <Spin spinning={loading}>
                            <Typography.Title level={2}>{chart.title}</Typography.Title>
                            <div className="chart-wrapper">
                                <Chart {...chart.config} />
                            </div>
                        </Spin>
                    </section>
                ))}
        </Page>
    )
}

export default PageReportsCountries