import supabase from "lib/supabase/main"
import renderGMT from "lib/renderGMT";
import IpLink from "components/link/Ip";
import formatNumber from "lib/format/number";
import CountryName from "components/CountryName";

export const columns = [

    {
        dataIndex: "ip",
        key: "ip",
        title: "IP",
        render: ip => <IpLink ip={ip} />
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (code) => <CountryName country={code} />
    },
    {
        dataIndex: "port",
        key: "port",
        title: "Port"
    },
    {
        dataIndex: "first_occurence",
        key: "first_occurence",
        title: "First",
        render: renderGMT
    },
    {
        dataIndex: "last_occurence",
        key: "last_occurence",
        title: "Last",
        render: renderGMT
    },
    {
        dataIndex: "occurences",
        key: "occurences",
        title: "Total",
        render: formatNumber
    },
]

export async function loadIpTarpitPort(options = {}) {

    const { ip, country, companyId, customerCompanyId, period,
        withCompany = false, withCountry = false,
        page = 0, pageSize = 20 } = options;

    const params = {
        offset: page * pageSize,
        limit: pageSize,
        ip,
        country,
        companyId,
        customerCompanyId,
        period,
        withCompany,
        withCountry
    };

    const { data: { rows, columns: dataColumns, count = 0 }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "ip_tarpit_port",
            options: params,
            replica: true
        },
        method: "POST"
    });

    const hasMore = rows.length >= pageSize;

    return { data: rows, columns: dataColumns, count, hasMore, error };
}