import supabase from "lib/supabase/main"
import CountryName from "components/CountryName";


export const columns = [

    {
        dataIndex: "continent",
        key: "continent",
        title: "Continent"
    },
    {
        dataIndex: "country",
        key: "country",
        title: "Country",
        render: (code) => (<CountryName country={code} />)
    },
    {
        dataIndex: "stateprov_code",
        key: "stateprov_code",
        title: "State province code"
    },
    {
        dataIndex: "stateprov",
        key: "stateprov",
        title: "State province"
    },
    {
        dataIndex: "district",
        key: "district",
        title: "District"
    },
    {
        dataIndex: "city",
        key: "city",
        title: "City"
    },
    {
        dataIndex: "zipcode",
        key: "zipcode",
        title: "Zipcode"
    },
    {
        dataIndex: "timezone_name",
        key: "timezone_name",
        title: "Timezone"
    },
    {
        dataIndex: "isp_name",
        key: "isp_name",
        title: "ISP"
    },
    {
        dataIndex: "usage_type",
        key: "usage_type",
        title: "Usage type"
    },
    {
        dataIndex: "connection_type",
        key: "connection_type",
        title: "Connection type"
    },
    {
        dataIndex: "organization_name",
        key: "organization_name",
        title: "Organization"
    }
]

export async function loadIpGeo(options = {}) {

    const { ip } = options;

    const params = {
        offset: 0,
        limit: 1,
        ip
    };

    const { data: { rows, columns: dataColumns, count = 0 }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "ip_geo",
            options: params
        },
        method: "POST"
    });

    return { data: rows, columns: dataColumns, count: parseInt(count), error };
}