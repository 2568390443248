import { useCallback, useEffect, useState } from "react"
import useDictRef from "./useDictRef";
import useUpdateEffect from "./useUpdateEffect";

function useQuery(fn, deps = [false], options = {}, resetDeps = [false]) {

    const { append, prepend, params, rowIdKey, map,
        prepareParams, dataRef = null,
        prepare, onLoad, onBeforeLoad, onLoadFailed, enabled = true } = options;
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState(
        () => options.initialData !== undefined ? options.initialData : []
    );
    const [error, setError] = useState(null);
    const [lastParams, setLastParams] = useState(null);
    const [count, setCount] = useState(options.initialData ? options.initialData.length : 0);
    const [extraData, setExtraData] = useState({});
    const ref = useDictRef({
        fn, append, prepend, params, prepareParams, isLoading, dataRef,
        rowIdKey, map, prepare, onLoad, onBeforeLoad, enabled, onLoadFailed
    });

    const prepareData = useCallback(
        (prev, data, extraData, params) => {
            if (ref.prepare) {
                data = ref.prepare(data, extraData);
            }
            if (ref.map && Array.isArray(data)) {
                data = data.map(ref.map);
            }
            if (ref.append) {
                data = [...prev, ...data];
            }
            else if (ref.prepend) {
                data = [...data, ...prev];
            }
            if (ref.rowIdKey && prev && prev.length > 0) {
                data = data
                    .reverse()
                    .filter((r, inx, self) =>
                        typeof ref.rowIdKey === "string" ?
                            self.findIndex(r1 => r1[ref.rowIdKey] === r[ref.rowIdKey]) === inx :
                            self.findIndex(r1 => ref.rowIdKey(r1) === ref.rowIdKey(r)) === inx
                    )
                    .reverse();
            }
            ref.onLoad && ref.onLoad(data, params);
            return data;
        },
        [ref]
    );

    const refetch = useCallback(
        async (params) => {
            if (!ref.enabled || ref.dataRef) {
                return;
            }
            if (ref.isLoading) {
                return;
            }
            ref.isLoading = true;
            setIsLoading(true);

            let finalParams = { ...ref.params, ...params };
            if (ref.prepareParams) {
                finalParams = await ref.prepareParams(finalParams);
            }
            if (finalParams === false) {
                setData([]);
                setIsLoading(false);
                setExtraData({});
                setError(null);
                setCount(0);
            }
            else {
                ref.onBeforeLoad && ref.onBeforeLoad(finalParams);
                try {
                    const { data, error, count, ...rest } = await ref.fn(finalParams);
                    setLastParams(finalParams);
                    setData(prev => prepareData(prev, data, { error, count, ...rest }, finalParams));
                    setIsLoading(false);
                    setIsLoaded(true);
                    setExtraData(rest || {});
                    setError(error);
                    if (count !== undefined) {
                        setCount(count);
                    }
                }
                catch (err) {
                    console.error(err);
                    ref.onLoadFailed && ref.onLoadFailed(finalParams);
                }
            }
        },
        [ref, prepareData]
    );

    const reset = useCallback(
        () => {
            setData([]);
            setCount(0);
            setError(null);
        },
        []
    );

    /*useEffect(
        () => {
            setData(options.initialData || []);
        },
        // eslint-disable-next-line
        []
    );*/

    useEffect(
        () => void refetch(),
        // eslint-disable-next-line
        deps
    );

    useUpdateEffect(
        () => void reset(),
        resetDeps
    );

    if (dataRef) {
        return dataRef();
    }

    return {
        data, isLoading, isLoaded, error, refetch, count, reset, extraData, params: lastParams,
        ref: () => ({
            data, isLoading, isLoaded, error, refetch, count, reset, extraData, params: lastParams
        })
    }
}

export default useQuery