import { App } from "context/AppContext"

const initialAppState = {
    loading: true,
    loggedIn: false,
    loggedInMfa: false,
    watchlistNotificationsCount: 0,
    userNotificationsCount: 0,
    expiredDomainsNotificationsCount: 0,
    cdnUrl: "https://d16972zk827mas.cloudfront.net"
}

const app = new App(initialAppState);

export default app;