
import supabase from "lib/supabase/main"

const table2key = {
    "watchlist_ip": "ip",
    "watchlist_cidr": "cidr",
    "watchlist_company": "entity_id"
}

export async function addToWatchlist(watchlistId, userId, table, value) {

    try {
        const payload = {
            watchlist_id: watchlistId,
            user_id: userId,
            last_checked_at: (new Date()).toISOString(),
            [table2key[table]]: value
        };
        await supabase.from(table).insert(payload);
    }
    catch (err) {
        console.error(err);
    }
}
