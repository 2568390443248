import { useState, useMemo, useCallback } from "react"
import { Flex, Modal } from "antd"
import { useOn } from "@kuindji/observable-react"

import InputContainer from "components/form/InputContainer"

import { Form, FormContext, useFormFields } from "lib/form/Form"

import required from "lib/form/validator/required"
import supabase from "lib/supabase/main"
import useUpdateEffect from "hooks/useUpdateEffect"

async function uniqueId(value, _, values) {
    if (value === values.editId) {
        return true;
    }
    const req = supabase.from("malware").select("id").eq("id", value.toLowerCase().trim());
    const { data } = await req;
    return data.length === 0;
}


const fields = [
    {
        name: "id",
        validator: [
            [required, "Please enter malware id"],
            [uniqueId, "Malware ID must be unique"]
        ]
    },
    {
        name: "name",
        validator: [[required, "Please enter malware name"]]
    },
    {
        name: "description"
    },
    {
        name: "alias"
    },
    {
        name: "url"
    }
]

function MalwareForm() {

    const {
        id, idError, idChange,
        name, nameError, nameChange,
        description, descriptionChange,
        alias, aliasChange,
        url, urlChange
    } = useFormFields(["id", "name", "description", "alias", "url"]);

    return (
        <Flex vertical gap="small">
            <InputContainer
                value={id}
                placeholder="ID"
                onChange={idChange}
                error={idError} />
            <InputContainer
                value={name}
                placeholder="Name"
                onChange={nameChange}
                error={nameError} />
            <InputContainer
                value={description}
                placeholder="Description"
                onChange={descriptionChange} />
            <InputContainer
                value={alias}
                placeholder="Alias"
                onChange={aliasChange} />
            <InputContainer
                value={url}
                placeholder="URL"
                onChange={urlChange} />
        </Flex>
    )
}

function MalwareFormDialog({ open, onClose, onCreate, malware }) {

    const form = useMemo(
        () => new Form(fields, { ...malware, editId: malware?.id }),
        // eslint-disable-next-line
        []
    );
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = useCallback(
        async () => {

            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await form.validateAll();

            if (valid) {
                await supabase.from("malware")
                    .insert(form.get(["id", "name", "description", "alias", "url"]))
            }

            setSubmitting(false);

            if (valid) {
                onCreate && onCreate({
                    query: form.get("name")
                });
                onClose && onClose();
                form.resetAll();
            }
        },
        [onClose, onCreate, form, submitting]
    );

    const onCancel = useCallback(
        () => {
            form.resetAll();
            onClose && onClose();
        },
        [onClose, form]
    );

    const okButtonProps = useMemo(
        () => ({
            loading: submitting,
            disabled: submitting
        }),
        [submitting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submitting,
            type: "text"
        }),
        [submitting]
    );

    useOn(form, "submit", onSubmit);

    useUpdateEffect(
        () => {
            if (malware) {
                form.resetAll();
                form.set({ ...malware, editId: malware.id });
            }
            else {
                form.resetAll();
            }
        },
        [malware]
    );

    return (
        <Modal
            title="Malware editor"
            open={open}
            closable={false}
            onOk={onSubmit}
            centered
            onCancel={onCancel}
            okText="Save"
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}>
            <FormContext.Provider value={form}>
                <MalwareForm />
            </FormContext.Provider>
        </Modal>
    )
}

export default MalwareFormDialog