import { useCallback, useContext, useMemo, useState } from "react"
import { useOn } from "@kuindji/observable-react"
import { App } from "antd"

import WatchlistDialog from "./WatchlistDialog"
import AppContext from "context/AppContext"

import { addToWatchlist } from "api/watchlist"
import useAppKey from "hooks/useAppKey"

const type2table = {
    "ip": "watchlist_ip",
    "cidr": "watchlist_cidr",
    "company": "watchlist_company"
}

function AddToWatchlistDialog() {

    const app = useContext(AppContext);
    const userProfile = useAppKey("userProfile");
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(null);
    const [values, setValues] = useState([]);
    const [adding, setAdding] = useState(false);
    const { notification } = App.useApp();

    const title = useMemo(
        () => {
            let title;
            if (!type) {
                return "";
            }
            if (type === "ip" || type === "cidr") {
                title = `Add "${values.join(", ")}" to`;
            }
            if (type === "company") {
                title = `Add "${values.map(c => c.name).join(", ")}" to`;
            }
            return title;
        },
        [type, values]
    );

    const onAddIps = useCallback(
        (values) => {
            setType("ip");
            setValues(values);
            setOpen(true);
        },
        []
    );

    const onAddCidrs = useCallback(
        (values) => {
            setType("cidr");
            setValues(values);
            setOpen(true);
        },
        []
    );

    const onAddCompanies = useCallback(
        (values) => {
            setType("company");
            setValues(values);
            setOpen(true);
        },
        []
    );

    const onSelect = useCallback(
        async (watchlistIds) => {
            setAdding(true);

            const table = type2table[type];
            for (let i = 0, l = watchlistIds.length; i < l; i++) {
                const watchlistId = watchlistIds[i];
                for (let j = 0, jl = values.length; j < jl; j++) {
                    const value = values[j];
                    await addToWatchlist(
                        watchlistId,
                        userProfile.id,
                        table,
                        type === "company" ? value.id : value
                    );
                }
            }

            notification.success({ message: "Items added to watchlist", duration: 2 });

            setAdding(false);
        },
        [type, values, userProfile, notification]
    );

    const onCreateClick = useCallback(
        ({ onSuccess }) => {
            app.trigger("new-watchlist", { onSuccess });
        },
        [app]
    );

    const onClose = useCallback(
        () => {
            setOpen(false);
            setType(null);
            setValues([]);
        },
        []
    );

    useOn(app, "add-ips-to-watchlist", onAddIps);
    useOn(app, "add-cidrs-to-watchlist", onAddCidrs);
    useOn(app, "add-companies-to-watchlist", onAddCompanies);

    return (
        <WatchlistDialog
            open={open}
            title={title}
            onCreate={onCreateClick}
            onSelect={onSelect}
            onClose={onClose}
            autoload={true}
            selecting={adding} />
    )
}

export default AddToWatchlistDialog