import { useState, useCallback, useMemo } from "react";
import { Input, Table, Typography, Button, App, Flex, Tooltip } from "antd"
import {
    SyncOutlined, SearchOutlined,
    EditOutlined, DeleteOutlined,
    ApiOutlined
} from "@ant-design/icons"

import UserFormDialog from "components/admin/user/UserForm"
import ApiKeyDialog from "components/dialog/ApiKey"

import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"
import Page from "components/Page"
//import useArrayToggle from "hooks/useArrayToggle"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
//import useToggle from "hooks/useToggle"
import useContextKey from "hooks/useContextKey";
import AppContext from "context/AppContext";
import useDualState from "hooks/useDualState";
import useUpdateEffect from "hooks/useUpdateEffect";
import { Portal } from "components/Portal";

const { Title } = Typography;

function loadUsers(params) {
    return supabase.functions.invoke("backend", {
        method: "POST",
        body: {
            controller: "users/list",
            payload: params
        }
    }).then(response => {
        const { data: ctlData } = response;
        const { data, error } = ctlData || {};
        return { data: data.rows, count: data.count || 0, error };
    })
};

const PER_PAGE = 500;
const params = [
    {
        name: "query"
    }
]

function Users() {

    const { query, setQuery, all, apply } = useDualState({ params, mode: "both" });
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PER_PAGE);
    const [showUserForm, setShowUserForm] = useState(false);
    const [editUser, setEditUser] = useState(undefined);
    const { modal } = App.useApp();
    const userProfile = useContextKey("userProfile", AppContext);

    const { data, isLoading, count, reset, refetch } = useQuery(
        loadUsers,
        [page, pageSize, all],
        {
            rowIdKey: "id",
            params: { offset: page * PER_PAGE, limit: pageSize, ...all },
            initialData: []
        }
    );

    const onCloseUserForm = useCallback(
        () => {
            setEditUser(undefined);
            setShowUserForm(false)
        },
        []
    );

    const onCreateUserClick = useCallback(
        () => setShowUserForm(true),
        []
    );

    const onEditUserClick = useCallback(
        (row) => {
            setEditUser({
                id: row.id,
                firstName: row.first_name,
                lastName: row.last_name,
                isAdmin: !!row.raw_app_meta_data?.is_admin,
                isApproved: !!row.raw_app_meta_data?.is_approved,
                isRegistrar: !!row.raw_app_meta_data?.is_registrar,
                isCompany: !!row.raw_app_meta_data?.is_company,
                ...row
            });
            setShowUserForm(true);
        },
        []
    );

    useUpdateEffect(
        () => reset(),
        [all]
    );

    const doSearch = useCallback(
        () => apply(),
        [apply]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const onDeleteUserClick = useCallback(
        async (id) => {
            modal.confirm({
                content: "Are you sure?",
                okButtonProps: { danger: true },
                onOk: async () => {
                    await supabase.functions.invoke("backend", {
                        method: "POST",
                        body: {
                            controller: "users/delete",
                            payload: { id }
                        }
                    });
                    refetch();
                }
            });
        },
        [refetch, modal]
    );

    const onApiKey = useCallback(
        (userId) => {
            ApiKeyDialog.show({
                userId
            });
        },
        []
    );

    const pagination = useMemo(
        () => {
            if (count <= PER_PAGE) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange
            }
        },
        [onPaginationChange, count]
    );

    const columns = useMemo(
        () => {

            const columns = [
                {
                    key: "email",
                    dataIndex: "email",
                    title: "Email"
                },
                {
                    key: "name",
                    dataIndex: "first_name",
                    title: "Name",
                    className: "table-fill",
                    render: (_, row) => `${row.first_name} ${row.last_name}`
                },
                {
                    key: "admin",
                    dataIndex: "raw_app_meta_data",
                    title: "Is Admin",
                    render: m => m.is_admin ? "Yes" : "No"
                },
                {
                    key: "approved",
                    dataIndex: "raw_app_meta_data",
                    title: "Is Approved",
                    render: m => m.is_approved ? "Yes" : "No"
                },
                {
                    key: "registrar",
                    dataIndex: "raw_app_meta_data",
                    title: "Is Registrar",
                    render: m => m.is_registrar ? "Yes" : "No"
                },
                {
                    key: "company",
                    dataIndex: "raw_app_meta_data",
                    title: "Is Company user",
                    render: m => m.is_company ? "Yes" : "No"
                },
                {
                    key: "delete",
                    dataIndex: "raw_app_meta_data",
                    title: "",
                    align: "right",
                    className: "table-cell-collapse",
                    render: (m, row) => {
                        return (
                            <Flex gap="small">
                                <Button
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditUserClick(row)} />
                                <Tooltip title="Api key">
                                    <Button
                                        size="small"
                                        icon={<ApiOutlined />}
                                        onClick={() => onApiKey(row.id)} />
                                </Tooltip>
                                {(!m.is_admin === true && row.id !== userProfile?.id) &&
                                    <Button
                                        size="small"
                                        onClick={() => onDeleteUserClick(row.id)}
                                        icon={<DeleteOutlined />} />}
                            </Flex>
                        )
                    }
                }
            ];

            return columns;
        },
        [userProfile, onDeleteUserClick, onEditUserClick, onApiKey]
    );

    const onReloadClick = useSwallowEventCallback(
        () => refetch(),
        [refetch]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                doSearch();
            }
        },
        [doSearch]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onSearchClick = useCallback(
        () => doSearch(),
        [doSearch]
    );

    return (
        <Page className="page-users">
            <Portal host="header">
                <Title level={1}>Users</Title>
                <div className="toolbar">
                    <Button
                        size="large"
                        type="primary"
                        onClick={onCreateUserClick}
                        children="Create user" />
                    <Input
                        size="large"
                        allowClear
                        onKeyDown={onKeyDown}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onSearchClick}
                        children="Search" />
                    <Button
                        type="text"
                        size="large"
                        onClick={onReloadClick}
                        className="toolbar-right"
                        icon={<SyncOutlined spin={data.length > 0 && isLoading} />} />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={data.length === 0 && isLoading}
                dataSource={data}
                columns={columns}
                rowKey="id"
                pagination={pagination} />
            <UserFormDialog
                open={showUserForm}
                onClose={onCloseUserForm}
                onSave={refetch}
                user={editUser} />
        </Page>
    )

}

export default Users

