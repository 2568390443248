import { Select } from "antd"
import supabase from "lib/supabase/main";
import { useCallback, useState } from "react"


function RegistrarSelect(props) {

    const inputProps = { ...props };
    delete inputProps.onPressEnter;

    const [options, setOptions] = useState([]);

    const onSearch = useCallback(
        async (query) => {
            //console.log("search", query)
            const req = supabase.from("registrar").select();
            if (query) {
                req.ilike("name", `%${query}%`);
            }
            const { data } = await req;
            const options = data.map(row => ({
                value: row.id,
                label: row.name
            }));
            setOptions(options);
        },
        []
    );

    return (
        <Select
            showSearch
            defaultActiveFirstOption={false}
            filterOption={false}
            onSearch={onSearch}
            notFoundContent={null}
            options={options}
            {...inputProps} />
    )
}

export default RegistrarSelect