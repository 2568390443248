import { Modal, Input, App } from "antd"
import { useCallback, useContext, useMemo, useState } from "react"

import AppContext from "context/AppContext"

import { useOn } from "@kuindji/observable-react"

import supabase from "lib/supabase/main"
import useAppKey from "hooks/useAppKey"
import async from "lib/async"

function NewWatchlistDialog({ onClose }) {

    const app = useContext(AppContext);
    const userProfile = useAppKey("userProfile");
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [saving, setSaving] = useState(false);
    const [successCb, setSuccessCb] = useState(null);
    const [nameStatus, setNameStatus] = useState(null);
    const { notification } = App.useApp();

    const onNewWatchlist = useCallback(
        ({ onSuccess }) => {
            setSuccessCb(() => onSuccess);
            setOpen(true);
        },
        []
    );

    const validate = useCallback(
        () => {
            return name && name.trim();
        },
        [name]
    );

    const onNameChange = useCallback(
        (e) => {
            setName(e.target.value);
            if (nameStatus) {
                async(() => {
                    if (validate()) {
                        setNameStatus(null);
                    }
                });
            }
        },
        [nameStatus, validate]
    );

    const onOk = useCallback(
        async () => {

            const valid = validate();

            if (!valid) {
                setNameStatus("error");
                return;
            }

            setSaving(true);
            setNameStatus(null);

            const payload = {
                name,
                user_id: userProfile.id
            };
            const { error } = await supabase.from("watchlist").insert(payload);

            if (error) {
                console.error(error);
                notification.error({ message: error.message || "Failed to create watchlist" });
                setSaving(false);
                return;
            }
            else {
                notification.success({ message: "Watchlist created", duration: 2 });
            }

            if (successCb) {
                successCb({ query: name });
            }

            setName("");
            setSaving(false);
            setOpen(false);
            onClose && onClose();
        },
        [successCb, validate, onClose, userProfile, notification, name]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onOk();
            }
        },
        [onOk]
    );

    const onCancel = useCallback(
        () => {
            setOpen(false);
            setName("");
            setNameStatus(null);
            onClose && onClose();
        },
        [onClose]
    );

    const okButtonProps = useMemo(
        () => ({
            disabled: saving,
            loading: saving
        }),
        [saving]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: saving
        }),
        [saving]
    );

    useOn(app, "new-watchlist", onNewWatchlist);

    return (
        <Modal
            title="New watchlist"
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}>
            <Input
                value={name}
                status={nameStatus}
                onChange={onNameChange}
                onKeyDown={onKeyDown}
                disabled={saving}
                placeholder="Watchlist name" />
        </Modal>
    );
}

export default NewWatchlistDialog