import { useEffect, useContext, useCallback } from "react"
import { ConfigProvider, Spin, App as AntApp } from "antd"
import { BrowserRouter } from "react-router-dom"
import { SessionContextProvider, useUser } from "@supabase/auth-helpers-react"
import { useOn } from "@kuindji/observable-react"
import posthog from 'posthog-js'

import AppContext from "context/AppContext"

import VigilocityView from "views/VigilocityView"
import AuthView from "views/AuthView"
import CompanyView from "views/CompanyView"
import UnapprovedView from "views/UnapprovedView"

import themeConfig from "theme/config"
import supabase from "lib/supabase/main"
import useSubscription from "hooks/useSubscription"
import useDictRef from "hooks/useDictRef"
import { countWatchlistNotifications, countUserNotifications } from "lib/notifications"
import RegistrarView from "views/RegistrarView"
import useKeys from "hooks/useKeys"
import MFAView from "views/MFAView"

import app from "appContext"

if (window.location.hostname !== "localhost") {
    posthog.init(
        'phc_DvUzvohc58YBVMwG2pfzcBG5288ufibNevN3VkXT5nb',
        {
            enable: true,
            api_host: "https://us.posthog.com",
            autocapture: true,
        }
    );
}

function Vigilocity() {
    const app = useContext(AppContext);
    const { loading, loggedIn, loggedInMfa, hasVerifiedFactors,
        userProfile, isApproved, isRegistrar, isCompany } = useKeys(
            ["loading", "loggedIn", "loggedInMfa", "hasVerifiedFactors",
                "userProfile", "isApproved", "isRegistrar", "isCompany"],
            app
        );
    const user = useUser();

    const onWatchlistChanged = useCallback(
        async () => countWatchlistNotifications(app),
        [app]
    );

    const onUserNotificationReceived = useCallback(
        async () => countUserNotifications(app),
        [app]
    );

    const ref = useDictRef({
        onWatchlistChanged, onUserNotificationReceived,
        userProfile, user, app
    });

    const loadUserProfile = useCallback(
        async (id) => {
            const graph = `
                *,
                company(id, name)
            `;
            const { data: profile, error } = await supabase.from("user_profile").select(graph).eq("id", id).single();
            if (error) {
                console.error(error);
            }
            ref.app.set("userProfile", profile);
            ref.app.trigger("profile/loaded", profile);
        },
        // eslint-disable-next-line
        []
    );

    const loadUserSettings = useCallback(
        async (id) => {
            const { data: settings, error } = await supabase.from("user_settings").select().eq("id", id).single();
            if (error) {
                console.error(error);
            }
            ref.app.set("userSettings", settings);
            ref.app.trigger("settings/loaded", settings);
        },
        // eslint-disable-next-line
        []
    );

    const loadUserAccess = useCallback(
        async (id) => {
            const { data: access, error } = await supabase.from("user_access").select().eq("id", id).single();
            if (error) {
                console.error(error);
            }
            ref.app.set("userAccess", access);
            ref.app.trigger("access/loaded", access);
        },
        // eslint-disable-next-line
        []
    );

    const onUserProfileUpdated = useCallback(
        () => loadUserProfile(ref.user.id),
        [loadUserProfile, ref.user?.id]
    );

    const onUserSettingsUpdated = useCallback(
        () => loadUserSettings(ref.user.id),
        [loadUserSettings, ref.user?.id]
    );

    const setCurrentUser = useCallback(
        async (user) => {
            const app = ref.app;
            //console.log(user)
            if (user && user.aud === "authenticated") {

                if (window.location.hostname !== "localhost") {
                    posthog.identify(
                        user.id,
                        {
                            email: user.email,
                            metadata: user.app_metadata
                        }
                    )
                }

                const isApproved = user.app_metadata?.is_approved || false;
                const hasVerifiedFactors = user.factors &&
                    !!user.factors.find(f => f.status === "verified");

                //if (isApproved) {
                const { data } = await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
                if (data?.currentLevel === "aal2") {
                    app.set("loggedInMfa", true);
                }
                //}

                app.set("userId", user.id);
                app.set("loggedIn", true);
                app.set("hasVerifiedFactors", hasVerifiedFactors);
                app.set("isAdmin", user.app_metadata?.is_admin || false);
                app.set("isApproved", isApproved);
                app.set("isRegistrar", user.app_metadata?.is_registrar || false);
                app.set("isCompany", user.app_metadata?.is_company || false);
                app.set("isClaimsAdmin", user.app_metadata?.claims_admin || false);
                app.set("loading", false);

                if (!ref.userProfile || ref.userProfile.id !== user.id) {
                    loadUserProfile(user.id);
                }
                if (isApproved && (!ref.userProfile || ref.userProfile.id !== user.id)) {
                    loadUserSettings(user.id);
                    loadUserAccess(user.id);
                }
                if (isApproved) {
                    ref.onWatchlistChanged();
                    ref.onUserNotificationReceived();
                }
            }
            else {
                app.set("userId", null);
                app.set("loggedIn", false);
                app.set("loggedInMfa", false);
                app.set("isApproved", false);
                app.set("isAdmin", false);
                app.set("isRegistrar", false);
                app.set("isCompany", false);
                app.set("hasVerifiedFactors", false);
                const { data } = await supabase.auth.getSession();
                if (!data?.session) {
                    app.set("loading", false);
                }
            }
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            setCurrentUser(user);
        },
        // eslint-disable-next-line
        [user]
    );


    useOn(app, "profile/update", onUserProfileUpdated);
    useOn(app, "settings/update", onUserSettingsUpdated);

    useSubscription({
        table: "watchlist_notification",
        filter: `user_id=eq.${userProfile?.id}`,
        enabled: !!userProfile?.id && isApproved,
        callback: onWatchlistChanged
    });

    useSubscription({
        table: "user_settings",
        filter: `id=eq.${userProfile?.id}`,
        enabled: !!userProfile?.id && isApproved,
        callback: onUserSettingsUpdated
    });

    useSubscription({
        table: "user_notification",
        filter: `user_id=eq.${userProfile?.id}`,
        enabled: !!userProfile?.id && isApproved,
        callback: onUserNotificationReceived
    });

    if (loading) {
        return (
            <Spin />
        )
    }

    if (!loggedIn) {
        return (
            <AuthView />
        )
    }

    if (!hasVerifiedFactors || !loggedInMfa) {
        return (
            <MFAView />
        )
    }

    if (!isApproved) {
        return (
            <UnapprovedView />
        )
    }

    if (isRegistrar) {
        return (
            <RegistrarView />
        )
    }

    if (isCompany) {
        if (!userProfile) {
            return (
                <Spin />
            )
        }
        return (
            <CompanyView />
        )
    }

    return (
        <VigilocityView />
    )
}


function App() {
    return (
        <ConfigProvider theme={themeConfig}>
            <AppContext.Provider value={app}>
                <SessionContextProvider supabaseClient={supabase}>
                    <BrowserRouter basename="/">
                        <AntApp>
                            <Vigilocity />
                        </AntApp>
                    </BrowserRouter>
                </SessionContextProvider>
            </AppContext.Provider>
        </ConfigProvider>
    );
}

export default App;
