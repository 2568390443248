import { useCallback, useState } from "react"
import { Input, Table, /*Tooltip,*/ Button, Select, Descriptions } from "antd"
import { SearchOutlined/*, DownloadOutlined, SyncOutlined*/ } from "@ant-design/icons"

import DomainExpandable from "components/DomainExpandable"

import useDualState from "hooks/useDualState"
//import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import useUpdateEffect from "hooks/useUpdateEffect"
import useQuery from "hooks/useQuery"

import async from "lib/async"
//import downloadCsv from "lib/downloadCsv"

import { loadThreatFoxIoc, columns, expandedColumns } from "api/threatfoxIoc"
import { Portal } from "components/Portal"


const queryOptions = [
    { value: "ioc", label: "IOC" },
    { value: "malware", label: "Malware" },
    { value: "tags", label: "Tags" }
];

const params = [
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    },
    {
        name: "iocType",
        default: null
    },
    {
        name: "threatType",
        default: null
    }
];

const iocTypeOptions = [
    { value: "domain", label: "Domain" },
    { value: "ip:port", label: "IP:Port" },
    { value: "url", label: "URL" },
    { value: "md5_hash", label: "MD5" },
    { value: "sha1_hash", label: "SHA1" },
    { value: "sha256_hash", label: "SHA256" },
    { value: "sha3_384_hash", label: "SHA3" },
]

export function IocTypeSelector(props) {
    return (
        <Select {...props} options={iocTypeOptions} />
    )
}

const threatTypeOptions = [
    { value: "botnet_cc", label: "Botnet" },
    { value: "cc_skimming", label: "Skimming" },
    { value: "payload", label: "Payload" },
    { value: "payload_delivery", label: "Payload delivery" },
]

export function ThreatTypeSelector(props) {
    return (
        <Select {...props} options={threatTypeOptions} />
    )
}


function ThreatFoxIoc({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowSearch = true,
    //allowCsv = true,
    allowMore = true,
    initialParams = {}
}) {

    const {
        query, setQuery,
        queryBy, setQueryBy,
        iocType, setIocType,
        threatType, setThreatType,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });
    const [page, setPage] = useState(0);
    //const [ downloading, setDownloading ] = useState(false);

    const { data, isLoading, reset, extraData = {} } = useQuery(
        loadThreatFoxIoc,
        [all, page],
        {
            rowIdKey: "id",
            params: { ...initialParams, page, ...all },
            initialData: [],
            append: true
        }
    );
    const { hasMore } = extraData;
    const fetchedColumns = extraData.columns || [];

    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    const loadNextPage = useCallback(
        () => {
            setPage(prev => prev + 1);
        },
        [setPage]
    );

    const onSearchClick = useCallback(
        () => {
            reset();
            setPage(0);
            async(apply);
        },
        [reset, apply, setPage]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    /*const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadThreatFoxIoc({ query, queryBy, page, csv: true });
            downloadCsv(csv, "threatfox_ioc.csv");
            setDownloading(false);
        },
        [ page, query, queryBy ]
    );*/

    const renderExpanded = useCallback(
        (row) => {
            if (row.domain) {
                return (
                    <div style={{ padding: 10, paddingTop: 0 }}>
                        <DomainExpandable
                            defaultActiveKey="ioc"
                            domain={row.domain}
                            initialIocReports={row} />
                    </div>
                )
            }
            const items = expandedColumns.map(col => ({
                key: col.dataIndex,
                label: col.title,
                children: col.render ?
                    col.render(row[col.dataIndex] || "") :
                    row[col.dataIndex] || ""
            }));
            return (
                <Descriptions column={1} size="small" bordered items={items} />
            )
        },
        []
    );

    return (
        <div className="threatfox-ioc">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowSearch &&
                            <>
                                <IocTypeSelector
                                    allowClear
                                    size={toolbarSize}
                                    value={iocType}
                                    onChange={setIocType}
                                    placeholder="IOC Type" />
                                <ThreatTypeSelector
                                    allowClear
                                    size={toolbarSize}
                                    value={threatType}
                                    onChange={setThreatType}
                                    placeholder="Threat Type" />
                                <Input
                                    addonBefore={
                                        <Select
                                            allowClear
                                            size={toolbarSize}
                                            placeholder="Query by"
                                            value={queryBy}
                                            onChange={setQueryBy}
                                            options={queryOptions} />
                                    }
                                    allowClear
                                    size={toolbarSize}
                                    onKeyDown={onKeyDown}
                                    prefix={<SearchOutlined />}
                                    onChange={onQueryChange}
                                    value={query} />
                            </>}
                        {(allowSearch) &&
                            <Button
                                size={toolbarSize}
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search" />}
                    </div>
                </Portal>}
            <Table
                sticky
                size="small"
                bordered
                loading={data.length === 0 && isLoading}
                dataSource={data}
                columns={columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1)}
                rowKey="id"
                pagination={false}
                expandable={{
                    expandedRowRender: renderExpanded,
                    rowExpandable: () => true,
                }} />
            {(allowMore && hasMore) &&
                <>
                    <br />
                    <Button
                        loading={data.length > 0 && isLoading}
                        onClick={loadNextPage}
                        children="Load more"
                        disabled={isLoading}
                        style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} />
                </>}
        </div>
    )
}

export default ThreatFoxIoc