import { Route, Routes, Navigate } from "react-router-dom"

import Dashboard from "pages/Dashboard"

import PageLogsDns from "pages/logs/Dns"
import PageLogsTarpit from "pages/logs/Tarpit"
import PageLogsDnsTarpit from "pages/logs/TarpitPlusDns"

import PageRegistryDomains from "pages/registry/Domains"
import PageRegistryEntities from "pages/registry/Entities"
import PageRegistryThreats from "pages/registry/Threats"

import PageAdminDomains from "pages/admin/Domains"
import PageAdminServers from "pages/admin/Servers"
import PageAdminCompanies from "pages/admin/Companies"
import PageAdminUsers from "pages/admin/Users"

import PageHuntReports from "pages/hunt/Reports"
import PageHuntOtx from "pages/hunt/Otx"
import PageHuntThreatFoxIoc from "pages/hunt/Ioc"
import PageHuntPreview from "pages/hunt/Preview"
import PageHuntQueue from "pages/hunt/Queue"
import PageHuntRelated from "pages/hunt/Related"
import PageHuntWebCheck from "pages/hunt/WebCheck"
import PageExpiringDomains from "pages/hunt/Expiring"

import PageReportsIp from "pages/reports/Ip"
import PageReportsEntity from "pages/reports/Entity"
import PageReportsDomain from "pages/reports/Domain"
import PageReportsCountry from "pages/reports/Country"
import PageReportsCountries from "pages/reports/Countries"
import PageReportsWebcheck from "pages/reports/Webcheck"
import PageReportsCompanies from "pages/reports/Companies"

import PageProfile from "pages/user/Profile"
import PageWatchlists from "pages/Wachlists"
import PageAgentKeys from "pages/user/Agent"

import PageMFAEnroll from "pages/mfa/Enroll"
import PageMFAFactors from "pages/mfa/List"

import PageCompanyDashboard from "pages/company/Dashboard"
import PageCompanyIp from "pages/company/Ip"

import Header from "components/Header"
import Footer from "components/Footer"
import ScrollToTop from "components/ScrollToTop"
import AddToWatchlistDialog from "components/selector/AddToWatchlistDialog"
import NewWatchlistDialog from "components/dialog/NewWatchlist"
import ApiKeyDialog from "components/dialog/ApiKey"

import useAppKey from "hooks/useAppKey"
import useHtmlClass from "hooks/useHtmlClass"

function Vigilocity() {

    useHtmlClass("html-vigilocity-view");
    const isAdmin = useAppKey("isAdmin");
    const userAccess = useAppKey("userAccess");
    const huntViewAccess = userAccess?.hunt && userAccess.hunt?.view;
    // const registryViewAccess = userAccess?.registry && userAccess.registry?.view;
    // const reportsViewAccess = userAccess?.report && userAccess.report?.view;
    // const logsViewAccess = userAccess?.logs && userAccess.logs?.view;

    return (
        <>
            <ScrollToTop />
            <Header />
            <Routes>
                <Route path="/" element={<Dashboard />} />

                <Route path="/logs/dns" element={<PageLogsDns />} />
                <Route path="/logs/tarpit" element={<PageLogsTarpit />} />
                <Route path="/logs/tarpit-dns" element={<PageLogsDnsTarpit />} />

                <Route path="/registry/domains" element={<PageRegistryDomains />} />
                <Route path="/registry/entities" element={<PageRegistryEntities />} />
                <Route path="/registry/threats" element={<PageRegistryThreats />} />

                <Route path="/hunt/reports" element={<PageHuntReports />} />
                {(isAdmin || huntViewAccess) && <Route path="/hunt/preview" element={<PageHuntPreview />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/queue" element={<PageHuntQueue />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/intelligence" element={<PageHuntOtx />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/ioc" element={<PageHuntThreatFoxIoc />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/related" element={<PageHuntRelated />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/scan" element={<PageHuntWebCheck />} />}
                {(isAdmin || huntViewAccess) && <Route path="/hunt/expiring" element={<PageExpiringDomains />} />}

                <Route path="/reports/entity/:id" element={<PageReportsEntity />} />
                <Route path="/reports/ip/:ip" element={<PageReportsIp />} />
                <Route path="/reports/country/:country" element={<PageReportsCountry />} />
                <Route path="/reports/domain/*" element={<PageReportsDomain />} />
                <Route path="/reports/countries" element={<PageReportsCountries />} />
                <Route path="/reports/companies" element={<PageReportsCompanies />} />
                <Route path="/reports/scan/:domain" element={<PageReportsWebcheck />} />

                <Route path="/profile" element={<PageProfile />} />
                <Route path="/mfa/enroll" element={<PageMFAEnroll />} />
                <Route path="/mfa/factors" element={<PageMFAFactors />} />
                <Route path="/watchlists" element={<PageWatchlists />} />
                <Route path="/profile/agent" element={<PageAgentKeys />} />

                {isAdmin && <Route path="/company/:id" element={<PageCompanyDashboard />} />}
                {isAdmin && <Route path="/company/:id/ip/:ip" element={<PageCompanyIp />} />}

                {isAdmin && <Route path="/admin/domains" element={<PageAdminDomains />} />}
                {isAdmin && <Route path="/admin/companies" element={<PageAdminCompanies />} />}
                {isAdmin && <Route path="/admin/servers" element={<PageAdminServers />} />}
                {isAdmin && <Route path="/admin/users" element={<PageAdminUsers />} />}

                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
            <AddToWatchlistDialog />
            <NewWatchlistDialog />
            <ApiKeyDialog />
        </>
    )
}

export default Vigilocity