
import { Typography } from "antd"

import Page from "components/Page"
import IpRegistryCompanyTable from "components/table/IpRegistryCompany"


const { Title } = Typography;

const params = {
    withCidrsCount: true,
    withCount: true,
    withCounters: true
}

function IpRegistry() {


    return (
        <Page className="page-dns-log">
            <IpRegistryCompanyTable
                before={<Title level={1}>Entity search</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                stateMode="both"
                initialParams={params} />
        </Page>
    )
}

export default IpRegistry