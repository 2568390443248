import { Popover, Spin } from "antd"
import { sha256 } from 'js-sha256'
import { useCallback, useMemo, useState } from "react"

import preloadImages from "lib/image/preload"

function EmailPopover({ email, children }) {

    const [loading, setLoading] = useState(false);
    const [preloaded, setPreloaded] = useState(null);
    const gravatarUrl = useMemo(
        () => {
            const hash = sha256.create();
            hash.update(email.toLowerCase().trim());
            const hex = hash.hex();
            return `https://www.gravatar.com/avatar/${hex}?d=404`;
        },
        [email]
    );

    const content = useMemo(
        () => {
            if (loading) {
                return <Spin spinning />
            }
            if (preloaded === true) {
                return <img src={gravatarUrl} alt={email} />
            }
            if (preloaded === false) {
                return "Avatar preview is not available";
            }
            return null;
        },
        [gravatarUrl, loading, preloaded, email]
    );

    const onOpenChange = useCallback(
        async (open) => {
            if (open && preloaded === null) {
                setLoading(true);
                const res = await preloadImages([gravatarUrl]);
                console.log(res);
                setPreloaded(res.length > 0);
                setLoading(false);
            }
        },
        [gravatarUrl, preloaded]
    );

    return (
        <Popover
            title={email}
            content={content}
            onOpenChange={onOpenChange}>
            {children || email}
        </Popover>
    )
}

export default EmailPopover