import { useState, useCallback, useContext, useMemo } from "react"
import { Input, Modal, Flex, Button } from "antd"

//import supabase from "lib/supabase/main"
//import useAppKey from "hooks/useAppKey"
import useKey from "hooks/useKey"

import checkIsIp from "lib/isIp"
import checkIsCidr from "lib/isCidr"
import WatchlistContext from "components/watchlist/Context"
import aws from "lib/aws"
import loadOtxData from "api/otx"


async function updateWatchlistIps({ watchlist, ip, description }) {
    const values = ip.split(/[\s\n]/)
        .map(ip => ip.trim())
        .filter(ip => !!ip)
        .filter((ip, inx, self) => self.indexOf(ip) === inx);
    const ips = values.filter(v => checkIsIp(v));
    const cidrs = values.filter(v => checkIsCidr(v));

    while (ips.length > 0) {
        const part = ips.splice(0, 100);
        await aws.backend.post("/watchlist/add_ip", {
            body: {
                watchlistId: watchlist.id,
                ip: part,
                description
            }
        });
    }

    while (cidrs.length > 0) {
        const part = cidrs.splice(0, 100);
        await aws.backend.post("/watchlist/add_cidr", {
            body: {
                watchlistId: watchlist.id,
                cidr: part,
                description
            }
        });
    }
}


function PassiveDNSDialog({ open = false, onIpsReceived, onCancel }) {

    const [domain, setDomain] = useState("");
    const [fetching, setFetching] = useState(false);
    const onDomainChange = useCallback(
        (e) => setDomain(e.target.value),
        []
    );

    const fetchPassiveDns = useCallback(
        async () => {
            setFetching(true);
            const resp = await loadOtxData(domain);
            const pd = resp.passive_dns;
            const ips = (pd || [])
                .filter(row => checkIsCidr(row.address) || checkIsIp(row.address))
                .map(row => row.address);
            onIpsReceived && onIpsReceived(ips);
            setFetching(false);
            onCancel && onCancel();
        },
        [domain, onIpsReceived, onCancel]
    );

    const okProps = useMemo(
        () => ({
            disabled: !domain || fetching,
            loading: fetching
        }),
        [domain, fetching]
    );

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            okButtonProps={okProps}
            onOk={fetchPassiveDns}
            title="Passive DNS for domain">
            <Input
                onPressEnter={domain ? fetchPassiveDns : null}
                placeholder="domain.name"
                value={domain}
                onChange={onDomainChange} />
        </Modal>
    )
}


function AddIpsForm({ }) {

    //const userProfile = useAppKey("userProfile");
    const context = useContext(WatchlistContext);
    const watchlist = useKey("addIpsToWatchlist", context);
    const [value, setValue] = useState("");
    const [description, setDescription] = useState("");
    const [isAdding, setIsAdding] = useState(false);
    const [showPassiveDns, setShowPassiveDns] = useState(false);

    const onOk = useCallback(
        async () => {
            setIsAdding(true);
            await updateWatchlistIps({
                watchlist,
                ip: value,
                description
            });
            setIsAdding(false);
            setValue("");
            context.set("addIpsToWatchlist", null);
            context.trigger("refetch-ip", watchlist.id);
            context.trigger("refetch-cidr", watchlist.id);
        },
        [value, watchlist, context, description]
    );

    const onCancel = useCallback(
        () => {
            context.set("addIpsToWatchlist", null);
        },
        [context]
    );

    const onValueChange = useCallback(
        (e) => setValue(e.target.value),
        []
    );

    const onDescriptionChange = useCallback(
        (e) => setDescription(e.target.value),
        []
    );

    const onImportPassiveDnsClick = useCallback(
        () => setShowPassiveDns(true),
        []
    );

    const onPassiveDnsCancel = useCallback(
        () => setShowPassiveDns(false),
        []
    );

    const onIpsReceived = useCallback(
        (ips) => {
            setValue(prev => {
                const str = ips.join("\n");
                return prev ? prev + "\n" + str : str;
            })
        },
        []
    );

    const footer = useMemo(
        () => {
            return (
                <Flex gap="0.5rem" justify="space-between">
                    <Button
                        onClick={onImportPassiveDnsClick}
                        type="text"
                        children="Import Passive DNS"
                        disabled={isAdding} />
                    <Button
                        onClick={onCancel}
                        type="text"
                        style={{ marginLeft: "auto" }}
                        children="Cancel"
                        disabled={isAdding} />
                    <Button
                        onClick={onOk}
                        type="primary"
                        children="Add"
                        disabled={isAdding || !value} />
                </Flex>
            )
        },
        [onOk, onCancel, isAdding, value, onImportPassiveDnsClick]
    );

    return (
        <Modal title={(watchlist?.name || "") + ": add IPs/CIDRs"}
            open={!!watchlist}
            footer={footer}
            confirmLoading={isAdding}>
            <Flex vertical gap="0.5rem">
                <Input.TextArea
                    value={value}
                    disabled={isAdding}
                    onChange={onValueChange}
                    style={{ minHeight: 200 }} />
                <Input
                    placeholder="Description"
                    disabled={isAdding}
                    onChange={onDescriptionChange} />
            </Flex>
            <PassiveDNSDialog
                open={showPassiveDns}
                onCancel={onPassiveDnsCancel}
                onIpsReceived={onIpsReceived} />
        </Modal>
    )
}


export default AddIpsForm