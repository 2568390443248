import { useCallback } from "react"
import { Typography, Switch, Tooltip } from "antd"
import { BellOutlined, BellFilled } from "@ant-design/icons"
import moment from "moment"

import Page from "components/Page"
import RegistrarHunt from "components/table/RegistrarHunt"
import useSettings from "hooks/useSettings"

const { Title } = Typography;

function RegistrarHuntPage() {

    const { userSettings, saving, updateSettings } = useSettings();
    const notificationsEnabled = userSettings?.registrar_hunt_since_gmt !== null;
    const toggleNotifications = useCallback(
        (state) => {
            updateSettings({
                registrar_hunt_since_gmt: state ? moment().utc().toISOString() : null,
                registrar_hunt_counter: 0
            });
        },
        [updateSettings]
    );

    const onResetNewItems = useCallback(
        () => {
            updateSettings({
                registrar_hunt_since_gmt: moment().utc().toISOString(),
                registrar_hunt_counter: 0
            });
        },
        [updateSettings]
    );

    return (
        <Page className="page-threatfox-ioc">
            <RegistrarHunt
                before={
                    <Title level={1}>
                        Automated Hunt Preview
                        &nbsp;&nbsp;
                        <Tooltip title="New domains notification">
                            <Switch
                                checked={notificationsEnabled}
                                loading={saving}
                                disabled={saving}
                                onChange={toggleNotifications}
                                checkedChildren="On"
                                unCheckedChildren="Off" />
                            {notificationsEnabled ?
                                <BellFilled style={{ fontSize: "0.65em" }} /> :
                                <BellOutlined style={{ fontSize: "0.65em" }} />}
                        </Tooltip>
                    </Title>
                }
                toolbarPortal="header"
                toolbarSize="large"
                newItemsSince={userSettings?.registrar_hunt_since_gmt}
                onResetNewItems={onResetNewItems}
                stateMode="both" />
        </Page>
    )
}

export default RegistrarHuntPage