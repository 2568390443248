import isScalar from "is-scalar"


function isDeepEmpty(value) {

    if (isScalar(value)) {
        return value === null || value === undefined;
    }

    if (Array.isArray(value)) {
        if (value.length === 0) {
            return true;
        }
        return value.findIndex(item => !isDeepEmpty(item)) === -1;
    }

    const keys = Object.keys(value);

    if (keys.length === 0) {
        return true;
    }

    return keys.findIndex(key => !isDeepEmpty(value[key])) === -1;
}

export default isDeepEmpty