

export default function dom_getStyle(node, prop, numeric) {

    var style, val;

    if (window.getComputedStyle) {
        if (node === window) {
            return prop ? (numeric ? 0 : null) : {};
        }
        style = window.getComputedStyle(node, null);
        val = prop ? style[prop] : style;
    }
    else {
        style = node.currentStyle || node.style || {};
        val = prop ? style[prop] : style;
    }

    return numeric ? parseFloat(val) || 0 : val;
};
