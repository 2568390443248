import { useState, useCallback, useContext } from "react"
import { Input, Modal } from "antd"

//import useAppKey from "hooks/useAppKey"
import useKey from "hooks/useKey"

import WatchlistContext from "components/watchlist/Context"
import aws from "lib/aws"

async function updateWatchlistDomains({ watchlist, domain }) {

    const values = domain.split(/[\s\n]/)
        .map(domain => domain.trim())
        .map(domain => domain.toLowerCase())
        .filter(domain => !!domain)
        .filter((domain, inx, self) => self.indexOf(domain) === inx);

    while (values.length > 0) {
        const part = values.splice(0, 100);
        await aws.backend.post("/watchlist/add_domain", {
            body: {
                watchlistId: watchlist.id,
                domain: part
            }
        });
    }
}

function AddDomainsForm({ }) {

    //const userProfile = useAppKey("userProfile");
    const context = useContext(WatchlistContext);
    const watchlist = useKey("addDomainsToWatchlist", context);
    const [value, setValue] = useState("");
    const [isAdding, setIsAdding] = useState(false);

    const onOk = useCallback(
        async () => {
            setIsAdding(true);
            await updateWatchlistDomains({
                watchlist,
                domain: value
            });
            setIsAdding(false);
            setValue("");
            context.set("addDomainsToWatchlist", null);
            context.trigger("refetch-domain", watchlist.id);
        },
        [value, watchlist, context]
    );

    const onCancel = useCallback(
        () => {
            context.set("addDomainsToWatchlist", null);
        },
        [context]
    );

    const onValueChange = useCallback(
        (e) => setValue(e.target.value),
        []
    );

    return (
        <Modal title={(watchlist?.name || "") + ": add domains"}
            open={!!watchlist}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={isAdding}>
            <Input.TextArea
                value={value}
                disabled={isAdding}
                onChange={onValueChange}
                style={{ minHeight: 200 }} />
        </Modal>
    )
}


export default AddDomainsForm