import { Flex } from "antd";
import { useCallback, createContext, useContext, useMemo } from "react";

const NullFormContext = createContext();

/**
 * The sole purpose of this component is to disable Google password manager
 */
function NullForm({ children, useFlex = true, ...rest }) {

    const context = useContext(NullFormContext);

    const onSubmit = useCallback(
        (e) => {
            e.preventDefault();
            return false;
        },
        []
    );

    const { Component, props } = useMemo(
        () => {
            const Component = useFlex ?
                Flex :
                context ?
                    "div" :
                    "form";
            const props = { ...rest };
            if (useFlex && !context) {
                props.component = "form";
            }

            return { Component, props };
        },
        [useFlex, context, rest]
    )

    if (context) {
        return (
            <Component {...props}>
                {children}
            </Component>
        )
    }

    return (
        <NullFormContext.Provider value={true}>
            <Component autoComplete="off" onSubmit={onSubmit} {...props}>
                {children}
            </Component>
        </NullFormContext.Provider>
    )
}

export default NullForm