import { useState, useRef, useCallback } from "react"

function useStateWithGetter(initialValue) {

    const ref = useRef(null);
    const [value, setValue] = useState(initialValue);
    const getValue = useCallback(() => ref.current, []);
    ref.current = value;

    return [value, setValue, getValue];
}

export default useStateWithGetter