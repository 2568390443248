import { useCallback, useMemo, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Table, Typography, Flex, Modal } from "antd"
import { SyncOutlined } from "@ant-design/icons"
import { useOn } from "@kuindji/observable-react"

import Page from "components/Page"
import { Portal } from "components/Portal"
import InputContainer from "components/form/InputContainer"

import useQuery from "hooks/useQuery"
import renderGMT from "lib/renderGMT"
import supabase from "lib/supabase/main"
import useAppKey from "hooks/useAppKey"
import required from "lib/form/validator/required"
import { useFormFields, Form, FormContext } from "lib/form/Form"

async function loadKeys({ userId }) {
    const { data, error } = await supabase.from("agent_key").select().eq("user_id", userId)
    return { data, error };
}

function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0")
}

function generateKey(len = 40) {
    let arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec2hex).join('')
}

const fields = [
    {
        name: "name",
        validator: [[required, "Please enter key name"]]
    }
];


function NewAgentForm() {

    const { name, nameError, nameChange } = useFormFields(["name"]);

    return (
        <Flex vertical gap="small">
            <InputContainer
                value={name}
                placeholder="Name"
                onChange={nameChange}
                error={nameError} />
        </Flex>
    )
}

function NewAgentDialog({ open, onClose, onSave }) {

    const userProfile = useAppKey("userProfile");
    const form = useMemo(
        () => new Form(fields),
        // eslint-disable-next-line
        []
    );
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = useCallback(
        async () => {
            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await form.validateAll();

            if (valid) {
                const payload = form.get(["name"]);
                payload.key = generateKey();
                payload.user_id = userProfile.id;
                await supabase.from("agent_key").insert(payload);
            }

            setSubmitting(false);

            if (valid) {
                onSave && onSave();
                onClose && onClose();
                form.resetAll();
            }
        },
        [onClose, onSave, form, submitting, userProfile]
    );

    const onCancel = useCallback(
        () => {
            form.resetAll();
            onClose && onClose();
        },
        [onClose, form]
    );

    const okButtonProps = useMemo(
        () => ({
            loading: submitting,
            disabled: submitting
        }),
        [submitting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submitting,
            type: "text"
        }),
        [submitting]
    );

    useOn(form, "submit", onSubmit);

    return (
        <Modal
            title="New key"
            open={open}
            destroyOnClose
            closable={false}
            onOk={onSubmit}
            centered
            onCancel={onCancel}
            okText="Save"
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}>
            <FormContext.Provider value={form}>
                <NewAgentForm />
            </FormContext.Provider>
        </Modal>
    )
}



function KeyInstallation({ agentKey }) {

    return (
        <Flex vertical gap="1rem">
            <Flex vertical gap="0.25rem">
                <Typography.Text>Cron script</Typography.Text>
                <Typography.Text code copyable>
                    1 */1 * * * curl https://j48aspwlnb.execute-api.us-east-1.amazonaws.com/live/{agentKey}
                </Typography.Text>
            </Flex>
        </Flex>
    )

}

function AgentKeys() {
    const userProfile = useAppKey("userProfile") || {};
    const [showForm, setShowForm] = useState(false);
    const { data, isLoading, refetch } = useQuery(
        loadKeys,
        [userProfile?.id],
        {
            enabled: !!userProfile,
            params: {
                userId: userProfile.id
            }
        }
    );

    const onDelete = useCallback(
        async (row) => {
            await supabase.from("agent_key").delete().eq("id", row.id);
            refetch();
        },
        [refetch]
    );

    const onFormClose = useCallback(
        () => {
            setShowForm(false);
        },
        []
    );

    const onFormSave = useCallback(
        () => {
            setShowForm(false);
            refetch();
        },
        [refetch]
    );

    const columns = useMemo(
        () => {
            return [
                {
                    key: "name",
                    dataIndex: "name",
                    title: "Name"
                },
                {
                    key: "created_at",
                    dataIndex: "created_at",
                    title: "Created at (GMT)",
                    render: renderGMT
                },
                {
                    key: "key",
                    dataIndex: "key",
                    title: "Key",
                    render: key => (
                        <Typography.Text code copyable>{key}</Typography.Text>
                    )
                },
                {
                    key: "action",
                    dataIndex: "status",
                    className: "table-cell-collapse",
                    render: (_, row) => {
                        return (
                            <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => onDelete(row)}>
                                <Button size="small" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        )
                    }
                }
            ];
        },
        [onDelete]
    );

    const onCreateClick = useCallback(
        () => {
            setShowForm(true);
        },
        []
    );

    const renderInstallation = useCallback(
        (row) => {
            return (
                <KeyInstallation agentKey={row.key} />
            )
        },
        []
    );

    const expandable = useMemo(
        () => {
            return {
                rowExpandable: () => true,
                expandedRowRender: renderInstallation,
                defaultExpandAllRows: true
            }
        },
        [renderInstallation]
    );

    return (
        <Page className="page-mfa-list">
            <Portal host="header">
                <div className="toolbar header-single-row">
                    <Typography.Title level={1}>Agent keys</Typography.Title>
                    <Button
                        className="toolbar-right"
                        size="large"
                        type="primary"
                        children="Create key"
                        onClick={onCreateClick} />
                    <Button
                        size="large"
                        type="text"
                        icon={<SyncOutlined spin={isLoading} />}
                        onClick={refetch} />
                </div>
            </Portal>
            <Table
                bordered
                size="small"
                rowKey="id"
                tableLayout="auto"
                loading={isLoading}
                dataSource={data}
                columns={columns}
                pagination={false}
                expandable={expandable} />
            <NewAgentDialog
                open={showForm}
                onSave={onFormSave}
                onClose={onFormClose} />
        </Page>
    )
}

export default AgentKeys