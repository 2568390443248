
let intl;

function formatNumber(num = 0, digits = 0) {
    if (!intl) {
        if (window.Intl) {
            const options = {};
            if (digits > 0) {
                options.minimumFractionDigits = digits;
                options.maximumFractionDigits = digits;
            }
            intl = new Intl.NumberFormat('en-US', options);
        }
        else {
            intl = { format: num => num }
        }
    }
    return intl.format(num);
}


export default formatNumber