import { Button, Typography, Input, App as AntApp, Segmented } from "antd"
import { useCallback, useContext, useMemo, useState, useRef } from "react";

import Page from "components/Page"
import HuntReportQueueTable from "components/table/HuntReportQueue"
import HuntReportFormDialog from "components/hunt/HuntReportForm"

import AppContext from "context/AppContext"
import { DownloadOutlined, StopOutlined, RollbackOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { downloadReports, rejectReports, queueReports, sinkholeReports } from "api/huntReportQueue"
//import useAppKey from "hooks/useAppKey"
import useDictRef from "hooks/useDictRef"
import useStateOrQuery from "hooks/useStateOrQuery";
import { Portal } from "components/Portal";
import useAppKey from "hooks/useAppKey";

const { Title } = Typography;

const statusOptions = [
    {
        key: "queue",
        value: "",
        label: "Queue"
    },
    {
        key: "rejected",
        value: "rejected",
        label: "Rejected"
    },
    {
        key: "sinkholed",
        value: "sinkholed",
        label: "Sinkholed"
    },
]

function PageHuntQueue() {

    const app = useContext(AppContext);
    const tableRef = useRef();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selection, setSelection] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [downloading, setDownloading] = useState(false);
    const [rejecting, setRejecting] = useState(false);
    const [sinkholing, setSinkholing] = useState(false);
    const [status, setStatus] = useStateOrQuery("status", "", "query");
    const [rejectReason, setRejectReason] = useState("");
    const { modal } = AntApp.useApp();
    const isAdmin = useAppKey("isAdmin");
    const isRegistrar = useAppKey("isRegistrar");
    const ref = useDictRef({ rejectReason });

    const onRejectReasonChange = useCallback(
        (e) => setRejectReason(e.target.value),
        []
    );

    const onCreateClick = useCallback(
        () => setDialogOpen(true),
        []
    );

    const onDownloadClick = useCallback(
        async () => {
            setDownloading(true);
            await downloadReports(selection);
            setDownloading(false);
        },
        [selection]
    );

    const onSinkholeClick = useCallback(
        async () => {
            setSinkholing(true);
            await sinkholeReports(selection);
            tableRef.current.refetch();
            setSelection([]);
            setSelectedKeys([]);
            setSinkholing(false);
        },
        [selection]
    );

    const onRejectClick = useCallback(
        async () => {
            setRejecting(true);
            modal.confirm({
                title: "Reject reason",
                content: (
                    <Input onChange={onRejectReasonChange} />
                ),
                onOk: async () => {
                    await rejectReports(selection, ref.rejectReason);
                    tableRef.current.refetch();
                    setRejectReason("");
                    setSelection([]);
                    setSelectedKeys([]);
                    setRejecting(false);
                },
                onCancel: () => {
                    setRejecting(false);
                }
            })

        },
        // eslint-disable-next-line
        [selection, modal, onRejectReasonChange]
    );

    const onClearClick = useCallback(
        () => {
            setRejecting(true);
            setSinkholing(true);
            modal.confirm({
                title: "Are you sure?",
                onOk: async () => {
                    await queueReports(selection);
                    tableRef.current.refetch();
                    setSelection([]);
                    setSelectedKeys([]);
                    setRejecting(false);
                    setSinkholing(false);
                },
                onCancel: () => {
                    setRejecting(false);
                    setSinkholing(false);
                }
            });
        },
        [selection, modal]
    );

    const onCreateClose = useCallback(
        () => setDialogOpen(false),
        []
    );

    const onReportSaved = useCallback(
        () => {
            app.trigger("hunt-report/queue/saved");
        },
        [app]
    );

    const onSelectionChange = useCallback(
        (keys, rows) => {
            setSelection(rows);
            setSelectedKeys(keys);
        },
        []
    );

    const queueParams = useMemo(
        () => ({ status }),
        [status]
    );

    return (
        <Page className="page-hunt-queue">
            <Portal host="header">
                <Title level={1}>Automated Hunt Queue</Title>
                <div className="toolbar">

                    {(isAdmin || isRegistrar) &&
                        <>
                            <Button
                                type="primary"
                                size="large"
                                children="Create report"
                                onClick={onCreateClick} />

                            <Button
                                size="large"
                                loading={downloading}
                                disabled={selection.length === 0 || downloading}
                                type="text"
                                icon={<DownloadOutlined />}
                                onClick={onDownloadClick}
                                children="Download reports" />

                            <Button
                                size="large"
                                loading={rejecting}
                                disabled={selection.length === 0 || rejecting}
                                type="text"
                                icon={status === "rejected" ? <RollbackOutlined /> : <StopOutlined />}
                                onClick={status === "rejected" ? onClearClick : onRejectClick}
                                children={status === "rejected" ?
                                    "Put reports back into queue" :
                                    "Reject reports"} />

                            <Button
                                size="large"
                                loading={sinkholing}
                                disabled={selection.length === 0 || sinkholing}
                                type="text"
                                icon={status === "sinkholed" ? <RollbackOutlined /> : <CheckCircleOutlined />}
                                onClick={status === "sinkholed" ? onClearClick : onSinkholeClick}
                                children={status === "sinkholed" ?
                                    "Put reports back into queue" :
                                    "Mark sinkholed"} />
                        </>
                    }

                    <Segmented
                        size="large"
                        defaultValue=""
                        style={{ marginLeft: "auto" }}
                        options={statusOptions}
                        value={status}
                        onChange={setStatus} />
                </div>
            </Portal>
            <HuntReportQueueTable
                ref={tableRef}
                selectable={!downloading && (isAdmin || isRegistrar)}
                onSelect={onSelectionChange}
                selectedKeys={selectedKeys}
                initialParams={queueParams} />
            <HuntReportFormDialog
                open={dialogOpen}
                onClose={onCreateClose}
                onSave={onReportSaved} />
        </Page>
    )
}

export default PageHuntQueue