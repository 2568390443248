import { useMemo, useState, useRef, useEffect, useCallback } from "react"
import { useNavigate } from "react-router-dom"

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

import useQuery from "hooks/useQuery"
import supabase from "lib/supabase/main"

import { MAPBOX_ACCESS_TOKEN } from "env"
import pointPaint from "lib/charts/pointPaint"
import mapStyle from "lib/charts/mapStyle"
import { Spin } from "antd"
import useDictRef from "hooks/useDictRef"
import { getCountryNameWithFlag } from "components/CountryName"

mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

function renderIps(ips) {
    let str = ips.slice(0, 10).map(ip => `<a href="/reports/ip/${ip}">${ip}</a>`).join(", ");
    if (ips.length > 10) {
        str += ", +" + (ips.length - 10);
    }
    return str;
}

function renderCompanyNames(names) {
    names = names.filter((n, inx, self) => self.indexOf(n) === inx);
    let str = names.slice(0, 10).join(", ");
    if (names.length > 10) {
        str += ", +" + (names.length - 10);
    }
    return str;
}

function LastActiveIpsMap({ companyId, height = "400px" }) {

    const navigate = useNavigate();
    const mapContainerRef = useRef();
    const [loaded, setLoaded] = useState(false);
    const [centerApplied, setCenterApplied] = useState(false);

    const { data, isLoading, isLoaded, refetch } = useQuery(
        async ({ companyId }) => {
            const resp = await supabase.functions.invoke("query", {
                body: {
                    report: "last_active_ips",
                    options: {
                        companyId
                    }
                },
                method: "POST"
            });
            return resp;
        },
        [companyId],
        {
            params: {
                companyId
            }
        }
    );

    const geoData = useMemo(
        () => {
            const features = data?.rows?.map(row => {
                const latitude = row.geo_json.coordinates[1] || 0;
                const longitude = row.geo_json.coordinates[0] || 0;
                return {
                    type: "Feature",
                    properties: {
                        counter: parseInt(row.counter),
                        dns_log_counter: parseInt(row.dns_log_counter),
                        tarpit_log_counter: parseInt(row.tarpit_log_counter),
                        country: row.country,
                        city: row.city,
                        ip: renderIps(row.ip),
                        company_name: renderCompanyNames(row.company_name)
                    },
                    geometry: {
                        type: "Point",
                        coordinates: [
                            longitude,
                            latitude,
                            parseInt(row.counter)
                        ]
                    }
                }
            }) || []
            return {
                type: "FeatureCollection",
                features
            };
        },
        [data]
    );

    const clusterPaintProps = useMemo(
        () => {
            const counts = data?.rows?.map(row => parseInt(row.counter)) || [];

            if (counts.length === 0) {
                return pointPaint;
            }

            const sum = counts.reduce((a, c) => a + c, 0);
            const avgValue = sum / counts.length;
            const maxValue = Math.max(...counts);

            return {
                ...pointPaint,
                'circle-color': [
                    "interpolate",
                    ["linear"],
                    ['get', 'sum'],
                    0,
                    "#2973d6",
                    maxValue * 0.5,
                    "#911a11"
                ],
                'circle-radius': [
                    'step',
                    ['get', 'sum'],
                    20,
                    parseInt(avgValue),
                    30,
                    parseInt(avgValue + ((maxValue - avgValue) / 2)),
                    40
                ]
            }
        },
        [data]
    );


    const pointPaintProps = useMemo(
        () => {
            const counts = data?.rows?.map(row => parseInt(row.counter)) || [];

            if (counts.length === 0) {
                return pointPaint;
            }

            const maxValue = Math.max(...counts);

            return {
                ...pointPaint,
                'circle-color': [
                    "interpolate",
                    ["linear"],
                    ['get', 'counter'],
                    0,
                    "#2973d6",
                    maxValue * 0.25,
                    "#911a11"
                ]
            }
        },
        [data]
    );


    const ref = useDictRef({
        clusterPaintProps,
        pointPaintProps,
        geoData,
        refetch
    });

    useEffect(
        () => {

            const map = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: mapStyle,
                center: [-22, 42],
                zoom: 3,
                attributionControl: false
            });


            map.on('load', () => {

                map.setFog({
                    'range': [0.8, 8],
                    'color': 'rgba(220, 159, 159, 0)',
                    'horizon-blend': 0,
                    'high-color': 'rgba(36, 92, 223, 0)',
                    'space-color': 'rgba(0, 0, 0, 0)',
                    'star-intensity': 0
                });

                map.addSource('logs', {
                    type: 'geojson',
                    data: ref.geoData,
                    cluster: true,
                    clusterMaxZoom: 14,
                    clusterRadius: 50,
                    clusterProperties: {
                        sum: ["+", ["get", "counter"]]
                    }
                });

                map.addLayer({
                    id: 'clusters',
                    type: 'circle',
                    source: 'logs',
                    filter: ['has', 'point_count'],
                    paint: { ...ref.clusterPaintProps }
                });

                map.addLayer({
                    id: 'cluster-count',
                    type: 'symbol',
                    source: 'logs',
                    filter: ['has', 'point_count'],
                    layout: {
                        'text-field': ['get', 'sum', /*'point_count_abbreviated'*/],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 12,
                    },
                    paint: {
                        'text-color': "#fff"
                    }
                });

                map.addLayer({
                    id: 'unclustered-point',
                    type: 'circle',
                    source: 'logs',
                    filter: ['!', ['has', 'point_count']],
                    paint: { ...ref.pointPaintProps }
                });

                map.addLayer({
                    id: 'unclustered-point-label',
                    type: 'symbol',
                    source: 'logs',
                    filter: ['!=', 'cluster', true],
                    layout: {
                        'text-field': ['get', 'counter'],
                        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                        'text-size': 9
                    },
                    paint: {
                        'text-color': "#fff"
                    }
                });


                // inspect a cluster on click
                map.on('click', 'clusters', (e) => {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: ['clusters']
                    });
                    const clusterId = features[0].properties.cluster_id;
                    map.getSource('logs')
                        .getClusterExpansionZoom(clusterId, (err, zoom) => {
                            if (err) return;

                            map.easeTo({
                                center: features[0].geometry.coordinates,
                                zoom: zoom
                            });
                        });
                });

                // When a click event occurs on a feature in
                // the unclustered-point layer, open a popup at
                // the location of the feature, with
                // description HTML from its properties.
                map.on('click', 'unclustered-point', (e) => {
                    const feat = e.features[0];
                    const coordinates = feat.geometry.coordinates.slice();
                    const {
                        dns_log_counter,
                        tarpit_log_counter,
                        country,
                        city,
                        ip,
                        company_name } = feat.properties;

                    // Ensure that if the map is zoomed out such that
                    // multiple copies of the feature are visible, the
                    // popup appears over the copy being pointed to.
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }

                    //const cn = countries.find(c => c.code === country)?.name;
                    const cn = getCountryNameWithFlag(country);

                    new mapboxgl.Popup()
                        .setLngLat(coordinates)
                        .setHTML(
                            `
                            <b>${cn}, ${city}</b><br>
                            ${ip}<br>
                            ${company_name}<br>
                            Dns requests: ${dns_log_counter}<br>
                            Data requests: ${tarpit_log_counter}`
                        )
                        .addTo(map);
                });

                map.on('mouseenter', 'clusters', () => {
                    map.getCanvas().style.cursor = 'pointer';
                });
                map.on('mouseleave', 'clusters', () => {
                    map.getCanvas().style.cursor = '';
                });

                setLoaded(true);
            });

            ref.map = map;
            window.map = map;
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            return () => {
                try {
                    ref.map?.remove();
                }
                catch (e) { }
            }
        },
        // eslint-disable-next-line
        []
    );

    useEffect(
        () => {
            if (loaded) {
                try {
                    ref.map.getSource("logs")?.setData(geoData);
                }
                catch (e) { }
            }
        },
        // eslint-disable-next-line
        [geoData, loaded]
    );

    useEffect(
        () => {
            if (loaded) {
                try {
                    ref.map.setPaintProperty(
                        "clusters",
                        "circle-radius",
                        [...clusterPaintProps["circle-radius"]]
                    );
                    ref.map.setPaintProperty(
                        "clusters",
                        "circle-color",
                        [...clusterPaintProps["circle-color"]]
                    );
                    ref.map.setPaintProperty(
                        "unclustered-point",
                        "circle-color",
                        [...pointPaintProps["circle-color"]]
                    );
                }
                catch (e) { }
            }
        },
        // eslint-disable-next-line
        [clusterPaintProps, pointPaintProps, loaded]
    );

    useEffect(
        () => {
            try {
                if (loaded && data.rows?.length > 0 && !centerApplied) {
                    const maxValue = Math.max(...(data.rows.map(r => parseInt(r.counter))));
                    const inx = data.rows.findIndex(r => parseInt(r.counter) === maxValue);
                    const row = data.rows[inx];
                    if (row) {
                        const latitude = row.geo_json.coordinates[1] || 0;
                        const longitude = row.geo_json.coordinates[0] || 0;
                        ref.map.setCenter([longitude, latitude]);
                        setCenterApplied(true);
                    }
                }
            }
            catch (e) {
                console.error(e)
            }
        },
        // eslint-disable-next-line
        [data, loaded, centerApplied]
    );

    useEffect(
        () => {
            const itv = setInterval(refetch, 60000);
            return () => {
                clearInterval(itv);
            }
        },
        // eslint-disable-next-line
        []
    );

    const handleLinkClicks = useCallback(
        (e) => {
            const href = e.target.getAttribute("href");
            e.preventDefault();
            e.stopPropagation();
            navigate(href);
            // console.log(href)
        },
        [navigate]
    );

    return (
        <Spin spinning={isLoading && !isLoaded}>
            <div onClick={handleLinkClicks} ref={mapContainerRef} style={{ height }} />
        </Spin>
    )
}

export default LastActiveIpsMap