
import { Button, Modal, Table, Input, Flex } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useCallback, useMemo, useState } from "react"

import useQuery from "hooks/useQuery"

const DEFAULT_PAGE_SIZE = 20;

function SelectorDialog({
    open = false,
    onSelect,
    onClose,
    onCreate,
    columns,
    loaderFn,
    multiple = true,
    searchable = true,
    autoload = false,
    selecting = false,
    returnObjects = false,
    rowKey = "id",
    paginationMode = false,
    fetchSelected,
    isDisabled,
    isSelected,
    createProps,
    okProps,
    cancelProps,
    ...rest }) {

    const [query, setQuery] = useState("");
    const [appliedQuery, setAppliedQuery] = useState("");
    const [selection, setSelection] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const allowCreate = useMemo(
        () => !!onCreate || !!createProps,
        [onCreate, createProps]
    );

    const { data, count, isLoading, refetch, extraData = {} } = useQuery(
        loaderFn,
        [appliedQuery, open, autoload, page, pageSize],
        {
            append: paginationMode === "more",
            enabled: !!appliedQuery || (open && autoload),
            params: {
                query: appliedQuery,
                page,
                pageSize
            },
            initialData: []
        }
    );

    const { data: selectedData, isLoading: isSelectedLoading } = useQuery(
        fetchSelected,
        [fetchSelected, open],
        {
            enabled: !!fetchSelected && open
        }
    );

    const loading = isLoading || isSelectedLoading;
    const { hasMore = false } = extraData;

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        []
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const onMoreClick = useCallback(
        () => {
            setPage(prev => prev + 1)
        },
        []
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                setSelection([]);
                setAppliedQuery(query);
            }
        },
        [query]
    );

    const onSearchClick = useCallback(
        () => {
            setSelection([]);
            setAppliedQuery(query)
        },
        [query]
    );

    const onOk = useCallback(
        async () => {
            setProcessing(true);
            const value = returnObjects ?
                selection.map(id => data.find(r => r[rowKey] === id)) :
                selection;
            await Promise.resolve(onSelect(value));
            setSelection([]);
            setProcessing(false);
            onClose();
        },
        [onClose, onSelect, selection, returnObjects, rowKey, data]
    );

    const onCancel = useCallback(
        () => {
            setSelection([]);
            onClose();
        },
        [onClose]
    );

    const onNewItemCreated = useCallback(
        ({ query }) => {
            if (query) {
                setQuery(query);
                setAppliedQuery(query);
            }
            else {
                refetch();
            }
        },
        [refetch]
    );

    const onCreateClick = useCallback(
        () => {
            onCreate({
                onSuccess: onNewItemCreated
            });
        },
        [onCreate, onNewItemCreated]
    );

    const pagination = useMemo(
        () => {
            if (paginationMode === false || paginationMode === "more") {
                return false;
            }
            if (!count) {
                return false;
            }
            return {
                hideOnSinglePage: true,
                total: count,
                defaultPageSize: DEFAULT_PAGE_SIZE,
                onChange: onPaginationChange,
                pageSize: pageSize
            }
        },
        [count, pageSize, onPaginationChange, paginationMode]
    );

    const footer = useMemo(
        () => {
            return (
                <Flex gap="0.5rem" justify="right">
                    {paginationMode === "more" &&
                        <Button
                            children="Load more"
                            style={{ marginRight: "auto" }}
                            onClick={onMoreClick}
                            disabled={!hasMore || selecting || processing || loading}
                            loading={loading} />}
                    <Button
                        children="Cancel"
                        type="text"
                        onClick={onCancel}
                        disabled={selecting || processing}
                        {...cancelProps} />
                    <Button
                        children="Ok"
                        type="primary"
                        onClick={onOk}
                        disabled={selection.length === 0}
                        loading={selecting || processing}
                        {...okProps} />
                </Flex>
            )
        },
        [selection.length, selecting, processing, okProps, cancelProps,
            onOk, onCancel, hasMore, onMoreClick, paginationMode, loading]
    );

    const rowSelection = useMemo(
        () => ({
            selectedRowKeys: selection,
            onChange: setSelection,
            type: multiple ? "checkbox" : "radio",
            getCheckboxProps: (row) => {
                if (isDisabled && isDisabled(row, selectedData)) {
                    return {
                        disabled: true
                    }
                }
                return {};
            }
        }),
        [selection, setSelection, multiple, selectedData, isDisabled]
    );

    return (
        <Modal
            centered
            {...rest}
            open={open}
            closable={false}
            footer={footer}>
            {(searchable || allowCreate) &&
                <div className="toolbar">
                    {allowCreate &&
                        <Button
                            children="Create new"
                            type="dashed"
                            onClick={onCreateClick}
                            {...createProps} />}
                    {searchable &&
                        <>
                            <Input
                                allowClear
                                onKeyDown={onKeyDown}
                                prefix={<SearchOutlined />}
                                onChange={onQueryChange}
                                value={query} />
                            <Button
                                loading={isLoading}
                                onClick={onSearchClick}
                                children="Search" />
                        </>}
                </div>}
            <div className="selector-dialog-body">
                <Table
                    rowSelection={rowSelection}
                    dataSource={data}
                    columns={columns}
                    bordered
                    sticky
                    loading={isLoading}
                    pagination={pagination}
                    rowKey={rowKey}
                    tableLayout="auto"
                    size="small" />
            </div>
        </Modal>
    )
}

export default SelectorDialog