import { useCallback, useMemo, useState } from "react";
import { Button, Table, Flex, Popconfirm, Modal, Input, Typography } from "antd";
import { DeleteOutlined, SyncOutlined } from "@ant-design/icons"

import Page from "components/Page"
import ServerSelect from "components/selector/ServerSelect";
import InputContainer from "components/form/InputContainer"

import { Form, useFormFields } from "lib/form/Form"
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main"
import required from "lib/form/validator/required"
import { Portal } from "components/Portal";


const fields = [
    {
        name: "server",
        validator: [[required, "Please select server"]]
    },
    {
        name: "ip",
        validator: [[required, "Please enter ip address"]]
    },
]

async function getServers() {
    return await supabase.from("tarpit_server")
        .select(`id, description, mac_address, 
                                tarpit_server_ip(ip, domain_settings(domain))`);
}


function IpForm({ onClose, onSave }) {
    const form = useMemo(
        () => new Form(fields),
        // eslint-disable-next-line
        []
    );
    const [saving, setSaving] = useState(false);

    const {
        server, serverChange, serverError,
        ip, ipChange, ipError
    } = useFormFields(["server", "ip"], form);

    const onSubmit = useCallback(
        async () => {

            const valid = await form.validateAll();
            if (!valid) {
                return;
            }

            setSaving(true);

            await supabase.from("tarpit_server_ip").insert({
                tarpit_server_id: server,
                ip
            });

            setSaving(false);
            onSave();
            onClose();
        },
        [form, onSave, onClose, server, ip]
    );

    return (
        <Flex vertical gap="0.5rem">
            <InputContainer error={serverError}>
                <ServerSelect
                    disabled={saving}
                    value={server}
                    onChange={serverChange}
                    placeholder="Server"
                    style={{ width: "100%" }} />
            </InputContainer>
            <InputContainer error={ipError}>
                <Input
                    disabled={saving}
                    value={ip}
                    onChange={ipChange}
                    placeholder="IP"
                    style={{ width: "100%" }} />
            </InputContainer>
            <Button
                type="primary"
                loading={saving}
                disabled={saving}
                onClick={onSubmit}
                children="Save" />
        </Flex>
    )
}

function IpModal({ open = false, onClose, onSave }) {


    return (
        <Modal
            title="Add IP"
            centered
            footer={null}
            open={open}
            onCancel={onClose}
            destroyOnClose>
            {open && <IpForm onClose={onClose} onSave={onSave} />}
        </Modal>
    )
}

function IpsTable({ ips, onDeleted }) {

    const onDeleteIp = useCallback(
        async (ip) => {
            await supabase.from("tarpit_server_ip").delete().eq("ip", ip);
            onDeleted();
        },
        [onDeleted]
    )

    const columns = useMemo(
        () => {
            return [
                {
                    key: "ip",
                    dataIndex: "ip",
                    title: "IP"
                },
                {
                    key: "domain_settings",
                    dataIndex: "domain_settings",
                    title: "Domain",
                    render: (ds) => {
                        if (ds && ds.length > 0) {
                            return ds[0].domain;
                        }
                    }
                },
                {
                    key: "actions",
                    dataIndex: "ip",
                    title: "",
                    className: "table-cell-collapse",
                    render: (ip, row) => {
                        return (
                            <Flex gap="small">
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => onDeleteIp(ip)}>
                                    <Button icon={<DeleteOutlined />} size="small" />
                                </Popconfirm>
                            </Flex>
                        )
                    }
                }
            ]
        },
        [onDeleteIp]
    );

    return (
        <Table
            sticky
            size="small"
            bordered
            tableLayout="auto"
            dataSource={ips}
            columns={columns}
            rowKey="ip"
            pagination={false} />
    )
}

function PageServers() {

    const [showForm, setShowForm] = useState(false);
    const { data: servers, refetch, isLoading } = useQuery(getServers, []);

    const onAddClick = useCallback(
        () => {
            setShowForm(true);
        },
        []
    );

    const columns = useMemo(
        () => {
            return [
                {
                    key: "id",
                    dataIndex: "id",
                    title: "ID"
                },
                {
                    key: "description",
                    dataIndex: "description",
                    title: "Description"
                },
                {
                    key: "ips",
                    dataIndex: "tarpit_server_ip",
                    title: "IPs",
                    render: ips => ips ? ips.length : 0
                }
            ]
        },
        []
    );


    const onFormClose = useCallback(
        () => {
            setShowForm(false);
        },
        []
    )

    const renderExpanded = useCallback(
        (row) => (<IpsTable ips={row.tarpit_server_ip} onDeleted={refetch} />),
        [refetch]
    );

    const expandable = useMemo(
        () => ({
            expandedRowRender: renderExpanded,
            rowExpandable: () => true,
        }),
        [renderExpanded]
    )


    return (
        <Page>
            <Portal host="header">
                <div className="toolbar header-signle-row">
                    <Typography.Title level={1}>Servers</Typography.Title>
                    <Button
                        size="large"
                        type="primary"
                        children="Add IP"
                        className="toolbar-right"
                        onClick={onAddClick} />
                    <Button
                        size="large"
                        type="text"
                        onClick={refetch}
                        disabled={isLoading}
                        icon={<SyncOutlined spin={isLoading} />} />
                </div>
            </Portal>
            <Table
                sticky
                size="small"
                bordered
                tableLayout="auto"
                loading={servers.length === 0 && isLoading}
                dataSource={servers}
                columns={columns}
                rowKey="id"
                pagination={false}
                expandable={expandable} />
            <IpModal
                open={showForm}
                onClose={onFormClose}
                onSave={refetch} />
        </Page>
    )
}

export default PageServers