

import { useMemo } from "react"
import { Typography, Spin, Table, Button, Tooltip } from "antd"
import { CloudDownloadOutlined } from "@ant-design/icons"
//import { Link } from "react-router-dom"

import useQuery from "hooks/useQuery"

import supabase from "lib/supabase/main"

//import { periodOptionsWithAll } from "lib/periodOptions"
//import countries from "dict/countries.json"
import formatNumber from "lib/format/number"
import ThreatName from "components/ThreatName"

const { Title } = Typography;


function downloadYaraRules(threat) {
    const url = `https://malpedia.caad.fkie.fraunhofer.de/yara/${threat.id}`;
    window.open(url);
}


const columns = [
    {
        dataIndex: "threat",
        key: "threat",
        title: "Threat",
        render: t => (
            <ThreatName id={t.id} name={t.name} />
        )
    },
    {
        dataIndex: "actor",
        key: "actor",
        title: "Actor",
        //render: t => t.name
        //width: 200,
    },
    {
        dataIndex: "dns_log_count",
        key: "dns_log_count",
        title: "Dns log entries",
        render: formatNumber,
        //width: 200,
    },
    {
        //className: "table-cell-nowrap",
        dataIndex: "tarpit_log_count",
        key: "tarpit_log_count",
        title: "Tarpit log entries",
        render: formatNumber,
        //width: 200,
    },
    {
        //className: "table-cell-collapse table-cell-nowrap",
        dataIndex: "threat",
        key: "yara_available",
        title: "Yara rules",
        width: 100,
        render: (threat) => {
            if (!threat.yara_available) {
                return null;
            }
            return (
                <Tooltip title="Download YARA rules">
                    <Button
                        type="text"
                        size="small"
                        onClick={() => downloadYaraRules(threat)}
                        icon={<CloudDownloadOutlined />} />
                </Tooltip>
            )
        }
    },
]

async function loadThreats({ companyId }) {

    const { data, error } = await supabase.from("company_report_threat")
        .select("*, threat(id, name, yara_available)")
        .eq("company_id", companyId)

    return { data, error }
}

function CompanyThreats({ title = "Top countries", companyId }) {

    //const [ period, setPeriod ] = useState("");
    const { data, isLoading } = useQuery(
        loadThreats,
        [companyId],
        {
            params: {
                companyId
            }
        }
    );

    const tableColumns = useMemo(
        () => {
            /*if (period === "" || period === "all") {
                return columns;
            }
            const inx = columns.findIndex(c => c.dataIndex === "ip_count");
            const tableColumns = [ ...columns ];
            tableColumns.splice(inx, 1);
            return tableColumns;*/
            return columns
        },
        []
    )

    return (
        <div className="small-report report-top-threat-domains">
            <div className="toolbar">
                {title && <Title level={4}>{title}</Title>}
                {/*<Segmented 
                    className="toolbar-right"
                    value={ period } 
                    onChange={ setPeriod } 
                    options={ periodOptionsWithAll } />*/}
            </div>
            <Spin spinning={isLoading}>
                <Table
                    // className="table-transparent-header"
                    sticky
                    size="small"
                    bordered
                    //tableLayout="auto"
                    loading={isLoading}
                    dataSource={data}
                    columns={tableColumns}
                    rowKey="threat_id"
                    pagination={false} />
            </Spin>
        </div>
    )

}

export default CompanyThreats