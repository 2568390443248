import { Typography } from "antd"
import Page from "components/Page"
import { Portal } from "components/Portal"
import PotentialPhishingDomains from "components/table/PotentialPhishingDomains";
import useAppKey from "hooks/useAppKey";
import useQuery from "hooks/useQuery";
import supabase from "lib/supabase/main"
import { useMemo } from "react";

async function loadRequests({ sources }) {

    const graph = `
        company_id,
        domain,
        similar_domain,
        request_status,
        status_changed_at,
        similar_domain_ref:domain!company_domain_similar_similar_domain_fkey(source)
    `;
    return supabase
        .from("company_domain_similar")
        .select(graph)
        .eq('takedown_requested', true)
        // doesn't work for some reason :( 
        // .in('domain!company_domain_similar_similar_domain_fkey.source', sources)
        .order('status_changed_at', { ascending: false });
}

function TakedownRequests() {

    const access = useAppKey("userAccess");

    const registrarSources = useMemo(
        () => Object.keys(access?.registrar || []).filter(key => access.registrar[key] === true),
        [access]
    );

    const { data, refetch } = useQuery(
        loadRequests,
        [access, registrarSources],
        {
            enabled: registrarSources.length > 0,
            params: {
                sources: registrarSources
            },
            prepare: rows => rows.filter(r => registrarSources.indexOf(r.similar_domain_ref.source) !== -1)
        }
    );

    return (
        <Page>
            <Portal host="header">
                <Typography.Title level={1}>Takedown requests</Typography.Title>
            </Portal>
            <PotentialPhishingDomains
                data={data}
                onStatusChange={refetch} />
        </Page>
    )
}

export default TakedownRequests