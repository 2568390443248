
import { loadIpRegistryLog } from "api/ipRegistry"

import SelectorDialog from "./SelectorDialog"

const columns = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name"
    },
    {
        key: "domain",
        dataIndex: "domain",
        title: "Domain"
    }
];

function CompanySeletorDialog(props) {

    return (
        <SelectorDialog
            {...props}
            columns={columns}
            paginationMode="more"
            loaderFn={loadIpRegistryLog} />
    )
}

export default CompanySeletorDialog