import AppContext from "context/AppContext";
import useHtmlClass from "hooks/useHtmlClass";
import { useContext, useEffect, useMemo } from "react"


function Page({ children, className, htmlClassName }) {

    const app = useContext(AppContext);

    const cls = useMemo(
        () => ["page", className].filter(c => !!c).join(" "),
        [className]
    );

    useHtmlClass(htmlClassName);

    useEffect(
        () => () => void app.trigger("portal-header", null),
        // eslint-disable-next-line
        []
    );

    return (
        <div className={cls}>
            {children}
        </div>
    )
}

export default Page