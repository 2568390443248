import { useCallback, useContext, useMemo, useState } from "react"
import { Modal, Flex, Input, Button } from "antd"
import { v4 as uuid } from "uuid"
import { useOn } from "@kuindji/observable-react"

import InputContainer from "components/form/InputContainer"
import Dropzone from "components/Dropzone"


import { Form, FormContext, useFormFields } from "lib/form/Form"

import required from "lib/form/validator/required"
import supabase from "lib/supabase/main"
import useUpdateEffect from "hooks/useUpdateEffect"
import readInputFile from "lib/dom/readInputFile"
import upload from "lib/upload"
import aws from "lib/aws"
import preload from "lib/image/preload"
import AppContext from "context/AppContext"

const fields = [
    {
        name: "name",
        validator: [[required, "Please enter company name"]]
    },
    {
        name: "domains"
    },
    {
        name: "logo"
    }
];

async function uploadLogo(uploadInfo) {

    const uploadKey = await upload(uploadInfo.file);
    const imageInfo = await preload([uploadInfo.src]).then(list => list[0]);
    const { key } = await aws.backend.post("/upload/logo", {
        body: {
            uploadKey
        }
    });

    const logo = { ...imageInfo, key };
    delete logo.src;
    return logo;
}

function CompanyForm() {

    const {
        name, nameError, nameChange,
        domains, domainsChange,
        logo, logoChange
    } = useFormFields(["name", "domains", "logo"]);
    const app = useContext(AppContext);

    const src = useMemo(
        () => {
            if (!logo) {
                return null;
            }
            if (logo.src) {
                return logo.src;
            }
            if (logo.key) {
                const cdn = app.get("cdnUrl");
                return `${cdn}/${logo.key}`;
            }
            return null;
        },
        [logo, app]
    );

    const onFileChange = useCallback(
        async (file) => {
            const { mime, data } = await readInputFile(file);
            const tmpid = uuid();
            const image = {
                name: file.name,
                mime,
                data,
                file,
                src: `data:${mime};base64,${data}`,
                tmpid
            }
            logoChange(image);
            console.log(image)
        },
        [logoChange]
    );

    const onRemoveLogoClick = useCallback(
        () => {
            logoChange(null);
        },
        [logoChange]
    );

    return (
        <Flex vertical gap="small">
            <InputContainer
                value={name}
                placeholder="Name"
                onChange={nameChange}
                error={nameError} />
            <InputContainer
                value={domains}
                ignorePressEnter
                placeholder="Domains"
                InputComponent={Input.TextArea}
                onChange={domainsChange}
                style={{ minHeight: "100px" }} />
            <div className="company-form-logo">
                {src &&
                    <div className="company-form-logo-image">
                        <img src={src} alt="" />
                    </div>}
                <div className="company-form-logo-actions">
                    {src &&
                        <Button
                            children="Remove"
                            type="default"
                            onClick={onRemoveLogoClick} />}
                    <Dropzone
                        className="company-form-dropzone"
                        useInputField
                        onChange={onFileChange}>
                        <p>Click here to upload company logo</p>
                    </Dropzone>
                </div>
            </div>

        </Flex>
    )
}

function CompanyEditorDialog({ open, company, onClose, onSave }) {

    const form = useMemo(
        () => {
            return new Form(fields, {
                ...company,
                editId: company?.id,
                domains: company ? company.company_domain.map(d => d.domain).join("\n") : ""
            })
        },
        // eslint-disable-next-line
        []
    );
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = useCallback(
        async () => {
            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await form.validateAll();
            let companyId = company?.id;
            let domains = [];
            let deleteDomains = [];
            let formDomains = form.get("domains")
                .split(/\s/g)
                .map(d => d.trim().toLowerCase())
                .filter(d => !!d);

            let logo = form.get("logo");

            if (logo?.tmpid) {
                logo = await uploadLogo(logo);
            }

            if (valid) {
                if (companyId) {
                    const updates = form.get(["name"]);
                    updates.logo = logo;
                    await supabase.from("company")
                        .update(updates)
                        .eq("id", companyId);
                    domains = formDomains
                        .filter(d => !company.company_domain.find(cd => cd.domain === d));
                    deleteDomains = company.company_domain
                        .map(cd => cd.domain)
                        .filter(d => formDomains.indexOf(d) === -1);
                }
                else {
                    const inserts = form.get(["name"]);
                    inserts.logo = logo;
                    const { data: res } = await supabase.from("company")
                        .insert(inserts)
                        .select();
                    companyId = res?.[0]?.id;
                    domains = formDomains;
                }

                if (domains.length > 0) {
                    domains = domains.map(domain => ({
                        company_id: companyId,
                        domain
                    }));
                    await supabase.from("company_domain").insert(domains);
                }

                if (deleteDomains.length > 0) {
                    for (const dd of deleteDomains) {
                        await supabase.from("company_domain")
                            .delete()
                            .eq("company_id", companyId)
                            .eq("domain", dd);
                    }
                }
            }

            setSubmitting(false);

            if (valid) {
                onSave && onSave();
                onClose && onClose();
                form.resetAll();
            }
        },
        [onClose, onSave, form, submitting, company]
    );

    const onCancel = useCallback(
        () => {
            form.resetAll();
            onClose && onClose();
        },
        [onClose, form]
    );

    const okButtonProps = useMemo(
        () => ({
            loading: submitting,
            disabled: submitting
        }),
        [submitting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submitting,
            type: "text"
        }),
        [submitting]
    );

    useOn(form, "submit", onSubmit);

    useUpdateEffect(
        () => {
            if (company) {
                form.resetAll();
                form.set({
                    ...company,
                    editId: company.id,
                    domains: company.company_domain.map(d => d.domain).join("\n")
                });
            }
            else {
                form.resetAll();
            }
        },
        [company]
    );

    return (
        <Modal
            title="Company editor"
            open={open}
            destroyOnClose
            closable={false}
            onOk={onSubmit}
            centered
            onCancel={onCancel}
            okText="Save"
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}>
            <FormContext.Provider value={form}>
                <CompanyForm />
            </FormContext.Provider>
        </Modal>
    )
}

export default CompanyEditorDialog