import { useMemo, useState } from "react"
import { useSearchParams } from "react-router-dom";


function useStateOrQuery(name, init, mode = "state", serialize, unserialize) {

    const [stateValue, setStateValue] = useState(init);
    const [urlParams, setUrlParams] = useSearchParams();

    const value = useMemo(
        () => {
            if (mode === "state") {
                return stateValue;
            }
            else {
                let value = urlParams.get(name);
                if ((value === undefined || value === null) && init !== undefined && init !== null) {
                    return init;
                }
                if (unserialize) {
                    value = unserialize(value);
                }
                return value;
            }
        },
        [stateValue, urlParams, mode, name, unserialize, init]
    );

    const setValue = useMemo(
        () => {
            return function (value) {
                if (mode === "state") {
                    setStateValue(value);
                }
                else {
                    if (typeof value === "string") {
                        if (serialize) {
                            value = serialize(value);
                        }
                        setUrlParams(`${name}=${value}`);
                    }
                    else {
                        setUrlParams(value);
                    }
                }
            }
        },
        [mode, name, serialize, setUrlParams]
    );

    return [value, setValue];
}

export default useStateOrQuery