import ipaddr from "ipaddr.js"


function isIp(value) {
    if (value) {
        try {
            const ip = ipaddr.parse(value);
            return !!ip;
        }
        catch (err) {
            return false;
        }
    }
    return false;
}

export default isIp