import { WindowsOutlined, AppleOutlined, LinuxOutlined, AndroidOutlined } from "@ant-design/icons"
import { useMemo } from "react";
// import { ReactComponent as JsIcon } from "svg/js.svg"
// import { ReactComponent as PhpIcon } from "svg/php.svg"

const osIcons = {
    "win": <WindowsOutlined />,
    "osx": <AppleOutlined />,
    "elf": <LinuxOutlined />,
    "apk": <AndroidOutlined />,
    // "js": <JsIcon />,
    // "php": <PhpIcon />,
};

function ThreatName({ id, name }) {

    const icon = useMemo(
        () => {
            if (!id) {
                return null;
            }
            const type = id.split(".")[0];
            return osIcons[type] || null;
        },
        [id]
    );

    return (
        <>
            {icon}
            {icon ? " " : null}
            {name}
        </>
    )
}

export default ThreatName