
import aws from "./aws"
import readInputFile from "./dom/readInputFile"

async function upload(file) {
    const ext = file.name.split('.').pop();
    const { url, s3key } = await aws.backend.post("/upload/url", { body: { ext } });
    const buf = file.buf || await readInputFile(file, "buffer");

    await fetch(url, {
        method: "PUT",
        body: buf,
        // we must pass headers here
        // otherwise Android will fail
        headers: {
            "Content-type": file.mime
        }
    });
    return s3key;
}

export default upload