import isIp from "./isIp"

function isCidr(value) {

    if (value) {
        if (value.indexOf("/") !== -1 && isIp(value.split("/")[0])) {
            return true;
        }
    }

    return false;
}

export default isCidr