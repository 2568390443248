import { useCallback, useContext, useMemo, useState, forwardRef, useImperativeHandle } from "react"
import { Table, Button, Popconfirm, Flex } from "antd"
import { EditOutlined, DeleteOutlined } from "@ant-design/icons"

import DomainExpandable from "components/DomainExpandable"

import useQuery from "hooks/useQuery"
import { loadHuntReportQueue, columns, deleteReport } from "api/huntReportQueue"
import AppContext from "context/AppContext"
import { useOn } from "@kuindji/observable-react"
import renderGMT from "lib/renderGMT"
import HuntReportFormDialog from "components/hunt/HuntReportForm"
import useAppKey from "hooks/useAppKey"

const PER_PAGE = 100;

function renderReportPath(key, value) {

    if (key === "creation_date_gmt") {
        //return renderGMT(value);
        return false;
    }
    else if (key === "expiry_date_gmt") {
        return renderGMT(value);
    }
    else if (key === "created_at") {
        return renderGMT(value);
    }
    return null;
}

const reportKeyNames = {
    "created_at": "Created at (GMT)"
}



const HuntReportQueue = forwardRef(function HuntReportQueue({
    //stateMode = "state",
    showPagination = true,
    selectable = false,
    onSelect = null,
    selectedKeys = null,
    initialParams = {} }, apiRef) {

    const app = useContext(AppContext);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PER_PAGE);
    const [editReport, setEditReport] = useState(null);
    const isAdmin = useAppKey("isAdmin");

    const { data, isLoading, count, refetch, extraData = {} } = useQuery(
        loadHuntReportQueue,
        [page, pageSize, initialParams.status],
        {
            rowIdKey: "domain",
            params: { ...initialParams, page, pageSize },
            initialData: []
        }
    );

    useImperativeHandle(
        apiRef,
        () => ({ refetch }),
        [refetch]
    );

    useOn(app, "hunt-report/queue/saved", refetch);

    const onEditPress = useCallback(
        (report) => {
            setEditReport(report)
        },
        []
    );

    const onEditClose = useCallback(
        () => {
            setEditReport(null);
        },
        []
    );

    const onEditSave = useCallback(
        () => {
            setEditReport(null);
            refetch();
        },
        [refetch]
    );

    const onDeletePress = useCallback(
        async (report) => {
            await deleteReport(report.domain);
            refetch();
        },
        [refetch]
    );

    const tableColumns = useMemo(
        () => {
            const fetchedColumns = [...(extraData.columns || [])];
            const tableColumns = columns.filter(
                c => fetchedColumns.indexOf(c.dataIndex) !== -1
            );
            if (isAdmin) {
                tableColumns.push({
                    key: "edit",
                    dataIndex: "id",
                    className: "table-cell-collapse",
                    render: (id, row) => {
                        return (
                            <Flex gap="small">
                                <Button
                                    size="small"
                                    icon={<EditOutlined />}
                                    onClick={() => onEditPress(row)} />
                                <Popconfirm
                                    title="Are you sure?"
                                    onConfirm={() => onDeletePress(row)}>
                                    <Button
                                        size="small"
                                        icon={<DeleteOutlined />} />
                                </Popconfirm>
                            </Flex>
                        )
                    }
                });
            }
            return tableColumns;
        },
        [extraData.columns, onEditPress, onDeletePress, isAdmin]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const onSelectionChange = useCallback(
        (keys, rows) => {
            onSelect && onSelect(keys, rows);
        },
        [onSelect]
    );

    const renderExpanded = useCallback(
        (row) => {
            return (
                <div style={{ padding: 10, paddingTop: 0 }}>
                    <DomainExpandable
                        defaultActiveKey="custom"
                        domain={row.domain}
                        customData={{
                            data: row,
                            name: "Report",
                            renderPath: renderReportPath,
                            keyNames: reportKeyNames
                        }}
                        showHuntReports={false} />
                </div>
            )
        },
        []
    );

    const pagination = useMemo(
        () => {
            if (!showPagination || parseInt(count) <= pageSize) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [onPaginationChange, showPagination, count, pageSize]
    );

    const expandable = useMemo(
        () => {
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            }
        },
        [renderExpanded]
    );

    const rowSelection = useMemo(
        () => {
            if (!selectable) {
                return null;
            }
            const cfg = {
                type: "checkbox",
                onChange: onSelectionChange
            }

            if (selectedKeys) {
                cfg.selectedRowKeys = selectedKeys;
            }

            return cfg;
        },
        [onSelectionChange, selectable, selectedKeys]
    );

    return (
        <div className="table-hunt-report-queue">
            <Table
                sticky
                size="small"
                tableLayout="auto"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="domain"
                rowSelection={rowSelection}
                expandable={expandable}
                pagination={pagination} />
            <HuntReportFormDialog
                open={!!editReport}
                report={editReport}
                onClose={onEditClose}
                onSave={onEditSave} />
        </div>
    )
})

export default HuntReportQueue