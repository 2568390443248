import { useCallback, useState } from "react"


function useToggle(initialState = false) {

    const [state, setState] = useState(initialState);

    const toggle = useCallback(
        () => setState(prev => !prev),
        []
    );

    const setFalse = useCallback(
        () => setState(false),
        []
    );

    const setTrue = useCallback(
        () => setState(true),
        []
    );

    return [state, toggle, setState, setTrue, setFalse];
}

export default useToggle