import { useCallback, useMemo, useState, useEffect } from "react"
import { Modal, Flex, App as AntApp } from "antd"
import { useOn } from "@kuindji/observable-react"

import InputContainer from "components/form/InputContainer"

import { Form, FormContext, useFormFields } from "lib/form/Form"

import required from "lib/form/validator/required"
import supabase from "lib/supabase/main"
//import useUpdateEffect from "hooks/useUpdateEffect"
import useDictRef from "hooks/useDictRef"

const fields = [
    {
        name: "name",
        validator: [[required, "Please enter watchlist name"]]
    },
    {
        name: "userId",
        default: null
    },
    {
        name: "companyId",
        default: null
    }
];


function WatchlistForm() {

    const { name, nameError, nameChange } = useFormFields(["name"]);

    return (
        <Flex vertical gap="small">
            <InputContainer
                value={name}
                placeholder="Name"
                onChange={nameChange}
                error={nameError} />
        </Flex>
    )
}

function WatchlistEditorDialog({ open, watchlist, onClose, onSave, userId, companyId }) {

    const { notification } = AntApp.useApp();
    const form = useMemo(
        () => {
            return new Form(fields, {
                ...watchlist,
                editId: watchlist?.id,
                userId,
                companyId
            })
        },
        // eslint-disable-next-line
        []
    );
    const [submitting, setSubmitting] = useState(false);

    const ref = useDictRef({ form });

    const onSubmit = useCallback(
        async () => {
            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await ref.form.validateAll();
            const userId = ref.form.get("userId") || null;
            const companyId = ref.form.get("companyId") || null;
            let watchlistId = watchlist?.id;

            if (valid) {
                if (watchlistId) {
                    await supabase.from("watchlist")
                        .update(ref.form.get(["name"]))
                        .eq("id", watchlistId);
                }
                else {

                    if (!userId && !companyId) {
                        notification.error({ message: "Failed to create a moodboard" });
                        return;
                    }

                    const res = await supabase.from("watchlist")
                        .insert({
                            ...ref.form.get(["name"]),
                            user_id: userId,
                            company_id: companyId
                        });
                    watchlistId = res.id;
                }
            }

            setSubmitting(false);

            if (valid) {
                onSave && onSave();
                onClose && onClose();
                ref.form.resetAll();
                ref.form.set({ userId, companyId });
            }
        },
        // eslint-disable-next-line
        [onClose, onSave, submitting, watchlist, notification]
    );

    const onCancel = useCallback(
        () => {
            ref.form.resetAll();
            onClose && onClose();
        },
        // eslint-disable-next-line
        [onClose]
    );

    const okButtonProps = useMemo(
        () => ({
            loading: submitting,
            disabled: submitting
        }),
        [submitting]
    );

    const cancelButtonProps = useMemo(
        () => ({
            disabled: submitting,
            type: "text"
        }),
        [submitting]
    );

    useOn(form, "submit", onSubmit);

    useEffect(
        () => {
            if (watchlist) {
                ref.form.resetAll();
                ref.form.set({
                    ...watchlist,
                    editId: watchlist.id,
                    userId,
                    companyId
                });
            }
            else {
                ref.form.resetAll();
                ref.form.set({
                    userId,
                    companyId
                });
            }
        },
        // eslint-disable-next-line
        [watchlist, userId, companyId]
    );

    return (
        <Modal
            title={watchlist?.id ? "Edit watchlist" : "Create watchlist"}
            open={open}
            destroyOnClose
            closable={false}
            onOk={onSubmit}
            centered
            onCancel={onCancel}
            okText="Save"
            okButtonProps={okButtonProps}
            cancelButtonProps={cancelButtonProps}>
            <FormContext.Provider value={form}>
                <WatchlistForm />
            </FormContext.Provider>
        </Modal>
    )
}

export default WatchlistEditorDialog