import { Select } from "antd"
import { useMemo } from "react"
import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"

async function getServers() {
    return supabase.from("tarpit_server")
        .select("id, description, mac_address");
}

function ServerSelect(props) {
    const { data: servers, isLoading } = useQuery(
        getServers,
        []
    );
    const options = useMemo(
        () => {
            const options = servers.map(s => ({
                key: s.id,
                value: s.id,
                label: `${s.description} (${s.id})`
            }));

            return options;
        },
        [servers]
    );

    return (
        <Select
            loading={isLoading}
            defaultActiveFirstOption={false}
            filterOption={false}
            notFoundContent={null}
            options={options}
            {...props} />
    )
}

export default ServerSelect