import React, { useCallback, useMemo, useState } from "react"
import { Input, Table, DatePicker, Tooltip, Button, Select } from "antd"
import {
    SearchOutlined, DownloadOutlined,
    SyncOutlined
} from "@ant-design/icons"
import dayjs from "dayjs"

import DomainExpandable from "components/DomainExpandable"
//import HuntReportFormDialog from "components/hunt/HuntReportForm"

import domainNameRegex from "lib/domainNameRegex"
import useQuery from "hooks/useQuery"
import { loadHuntReportLog, columns } from "api/huntReportLog"
import useSwallowEventCallback from "hooks/useSwallowEventCallback"
import { downloadCsv } from "lib/csv"
import useQueryOptions from "hooks/useQueryOptions"
import useDualState from "hooks/useDualState"
import useUpdateEffect from "hooks/useUpdateEffect"
import async from "lib/async"
import { Portal } from "components/Portal"

const defaultQueryOptions = [
    { value: 'domain', label: 'Domain' },
    { value: 'registrar', label: 'Registrar' },
    { value: 'registrantName', label: 'Registrant name' },
    { value: 'threat', label: 'Threat' },
    { value: 'threatType', label: 'Threat type' },
    { value: 'threatDecription', label: 'Threat description' },
];

const params = [
    {
        name: "query",
        default: "",
        serialize: v => v ? v.trim() : undefined
    },
    {
        name: "queryBy",
        default: null
    },
    {
        name: "status",
        default: "",
        autoApply: true,
    },
    {
        name: "dateRange",
        default: [],
        serialize: dr => dr ? dr.map(d => d.toISOString()).join(",") : undefined,
        unserialize: dr => dr ? dr.split(",").map(d => dayjs(d)) : []
    }
]


const searchOptions = [
    {
        value: "",
        label: "All"
    },
    {
        value: "expiring",
        label: "Expiring (yet active)"
    },
    {
        value: "expired",
        label: "Expired (yet active)"
    }
]

const PER_PAGE = 20;



function HuntReportLog({
    toolbarPortal = null,
    before = null,
    toolbarSize = "default",
    stateMode = "state",
    showToolbar = true,
    allowDateRange = true,
    allowSearch = true,
    allowCsv = true,
    showPagination = true,
    domainLink = true,
    domainExpandable = true,
    excludeQueryOptions,
    initialParams = {} }) {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PER_PAGE);
    const [downloading, setDownloading] = useState(false);
    //const [ editReport, setEditReport ] = useState(null);
    const queryOptions = useQueryOptions(defaultQueryOptions, excludeQueryOptions);

    const {
        query, setQuery,
        dateRange, setDateRange,
        queryBy, setQueryBy,
        status, setStatus,
        all, apply
    } = useDualState({ params, mode: stateMode, defaults: initialParams });


    const { data, isLoading, reset, count, extraData = {} } = useQuery(
        loadHuntReportLog,
        [page, pageSize, all],
        {
            rowIdKey: "domain",
            params: { ...initialParams, page, pageSize, ...all },
            initialData: []
        }
    );


    useUpdateEffect(
        () => reset(),
        [all, reset]
    );

    /*const onEditPress = useCallback(
        (report) => {
            setEditReport(report)
        },
        []
    )*/

    const tableColumns = useMemo(
        () => {
            const fetchedColumns = [...(extraData.columns || [])];
            const tableColumns = columns.filter(
                c => fetchedColumns.indexOf(c.dataIndex) !== -1
            );

            if (domainLink === false) {
                const inx = tableColumns.findIndex(c => c.dataIndex === "domain");
                if (inx !== -1) {
                    delete tableColumns[inx].render;
                }
            }

            /*tableColumns.push({
                key: "edit",
                dataIndex: "id",
                className: "table-cell-collapse",
                render: (id, row) => {
                    return (
                        <Button 
                            size="small" 
                            icon={ <EditOutlined/> }
                            onClick={ () => onEditPress(row) }/>
                    )
                }
            });*/
            return tableColumns;
        },
        [extraData.columns, domainLink]
    );

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const onQueryChange = useCallback(
        (e) => {
            const query = e.target.value.trim();

            if (query) {
                if (query.match(domainNameRegex)) {
                    setQueryBy("domain");
                }
                else {
                    if (queryBy === "domain") {
                        setQueryBy("registrar");
                    }
                }
            }
            else {
                setQueryBy(undefined);
            }

            setQuery(e.target.value);
        },
        [queryBy, setQuery, setQueryBy]
    );

    const onSearchClick = useCallback(
        () => {
            reset()
            setPage(0);
            async(apply);
        },
        [apply, reset]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onSearchClick();
            }
        },
        [onSearchClick]
    );

    const onDownloadCsv = useSwallowEventCallback(
        async () => {
            setDownloading(true);
            const csv = await loadHuntReportLog({
                ...initialParams,
                page,
                ...all,
                csv: true
            });

            downloadCsv(csv, "hunt_report_log.csv");
            setDownloading(false);
        },
        [page, all]
    );

    const renderExpanded = useCallback(
        (row) => {
            return (
                <div style={{ padding: 10, paddingTop: 0 }}>
                    <DomainExpandable
                        defaultActiveKey="hunt"
                        domain={row.domain}
                        initialHuntReports={row} />
                </div>
            )
        },
        []
    );

    const pagination = useMemo(
        () => {
            if (!showPagination || parseInt(count) <= pageSize) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [onPaginationChange, showPagination, count, pageSize]
    );

    const expandable = useMemo(
        () => {
            if (!domainExpandable) {
                return false;
            }
            return {
                expandedRowRender: renderExpanded,
                rowExpandable: () => true,
            }
        },
        [renderExpanded, domainExpandable]
    );

    return (
        <div className="table-hunt-report-log">
            {showToolbar &&
                <Portal host={toolbarPortal}>
                    {before}
                    <div className="toolbar">
                        {allowDateRange &&
                            <DatePicker.RangePicker
                                placement="bottomLeft"
                                showTime
                                size={toolbarSize}
                                value={dateRange}
                                onChange={setDateRange} />}
                        {allowSearch &&
                            <Select
                                options={searchOptions}
                                size={toolbarSize}
                                value={status}
                                onChange={setStatus} />}
                        {allowSearch &&
                            <Input
                                addonBefore={
                                    <Select
                                        size={toolbarSize}
                                        placeholder="Query by"
                                        value={queryBy}
                                        onChange={setQueryBy}
                                        options={queryOptions} />
                                }
                                allowClear
                                size={toolbarSize}
                                onKeyDown={onKeyDown}
                                prefix={<SearchOutlined />}
                                onChange={onQueryChange}
                                value={query} />
                        }

                        {(allowDateRange || allowSearch) &&
                            <Button
                                size={toolbarSize}
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={onSearchClick}
                                children="Search" />}
                        {allowCsv &&
                            <Tooltip title="Export as CSV">
                                <Button
                                    type="text"
                                    size={toolbarSize}
                                    onClick={onDownloadCsv}
                                    icon={downloading ? <SyncOutlined spin /> : <DownloadOutlined />} />
                            </Tooltip>}
                    </div>
                </Portal>}
            <Table
                sticky
                size="small"
                //tableLayout="auto"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={tableColumns}
                rowKey="domain"
                expandable={expandable}
                pagination={pagination} />
        </div>
    )
}

export default HuntReportLog