import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Spin, Tabs, Table } from "antd"
import { Link } from "react-router-dom"

import RandomData from "./RandomData"
import IpLink from "./link/Ip"

import loadOtxData, { keyNames } from "api/otx"
import useDictRef from "hooks/useDictRef"
import useUpdateEffect from "hooks/useUpdateEffect"
import renderGMT from "lib/renderGMT"
import { loadRegistrarLog, columns as domainColumns } from "api/registrarLog"
import { loadHuntReportLog } from "api/huntReportLog"
import { loadThreatFoxIoc } from "api/threatfoxIoc"
import ReportWebCheck from "./report/WebCheck"
import useWebCheck from "hooks/useWebCheck"
import { getCountryNameWithFlag } from "./CountryName"
import useQuery from "hooks/useQuery"
import supabase from "lib/supabase/main"

const registrarKeyNames = {
    creation_date_gmt: "Creation date (GMT)",
    expiry_date_gmt: "Expiry date (GMT)",
    currentstatus: "Current status"
}

async function loadDomainHistory({ domain }) {
    const res = await supabase.from("domain_archive")
        .select()
        .eq("domain", domain)
        .order("creation_date", { ascending: false });
    //console.log(res)
    return res;
}

function registrarRenderPath(key, value) {
    if (key === "domain") {
        return (
            <Link to={`/reports/domain/${value}`} children={value} />
        )
    }
    else if (key === "registration_ip") {
        return (
            <IpLink ip={value} />
        )
    }
    else if (key === "creation_date_gmt" || key === "expiry_date_gmt") {
        return renderGMT(value);
    }
    else if (key === "country_code") {
        return getCountryNameWithFlag(value);
    }

    return null;
}

function registrantRenderPath(key, value) {

    if (key === "email") {
        const sp = new URLSearchParams();
        sp.set("queryBy", "customerEmail");
        sp.set("query", value);

        return (
            <Link to={"/registry/domains?" + sp.toString()}>
                {value}
            </Link>
        )
    }
    else if (key === "country") {
        return getCountryNameWithFlag(value);
    }

    return value;
}

function defaultRenderPath(key, value) {
    if (value === null || value === "null") {
        return "";
    }
    return value;
}

function renderHuntReportPath(key, value) {
    if (value === null || value === "null") {
        return "";
    }
    if (key === "evidence") {
        if (value && value.length && value.length > 0) {
            return (
                <Fragment>
                    {value.map(link => (
                        <Fragment key={link}>
                            <a href={link} target="_blank" rel="noreferrer">{link}</a>
                            <br />
                        </Fragment>
                    ))}
                </Fragment>
            )
        }
    }
    return value;
}

function prepareForRandomData(data) {
    const newData = { ...data };
    delete newData.created_at;
    delete newData.submitted_to_registrar;
    return newData;
}


function WebcheckTab({ domain, active }) {

    if (active) {
        return (
            <ReportWebCheck domain={domain} />
        )
    }

    return null;
}


function ScreenshotTab({ domain, active }) {

    const { data, isLoading, isLoaded } = useWebCheck(
        { job: "screenshot", domain: `https://${domain}` },
        { enabled: active }
    );

    if (active) {
        if (isLoading) {
            return (
                <Spin spinning />
            )
        }
        if (!isLoading && data && data.success === false) {
            return (
                <p>Failed to make a screenshot.</p>
            )
        }
        if (isLoaded && !data) {
            return (
                <p>Failed to make a screenshot.</p>
            )
        }
        if (data?.image) {
            return (
                <img
                    alt={domain}
                    style={{ maxWidth: "100%" }}
                    src={'data:image/png;base64, ' + data.image} />
            )
        }
    }

    return null;
}

function HistoryTab({ domain, active }) {

    const { data, isLoading } = useQuery(
        loadDomainHistory,
        [domain],
        {
            enabled: !!domain && active,
            params: {
                domain
            }
        }
    );

    const renderExpanded = useCallback(
        (row) => {
            return (
                <DomainExpandable
                    domain={domain}
                    initialRegistrarData={row}
                    showHuntReports={false}
                    showIocReports={false}
                    showOtx={false}
                    showWebcheck={false}
                    showHistory={false}
                    showScreenshot={false} />
            )
        },
        [domain]
    );

    const expandable = useMemo(
        () => ({
            expandedRowRender: renderExpanded,
            rowExpandable: () => true
        }),
        [renderExpanded]
    );

    if (active) {
        return (
            <Table
                className="domain-expanded-history"
                loading={isLoading}
                dataSource={data}
                columns={domainColumns}
                expandable={expandable} />
        )
    }

    return null;
}

function DomainExpandable({
    domain,
    initialRegistrarData = null,
    initialHuntReports = null,
    initialIocReports = null,
    initialOtxData = null,
    customData = null,
    showRegistrarData = true,
    showHuntReports = true,
    showIocReports = true,
    showOtx = true,
    showWebcheck = true,
    showHistory = true,
    showScreenshot = true,
    defaultActiveKey = null }) {

    const [currentTab, setCurrentTab] = useState(null);

    const ref = useDictRef({ domain, currentTab });

    const [registrarData, setRegistrarData] = useState(initialRegistrarData);
    const [huntReports, setHuntReports] = useState(initialHuntReports);
    const [iocReports, setIocReports] = useState(initialIocReports);
    const [otxData, setOtxData] = useState(initialOtxData);

    const [isRegistarLoading, setIsRegistrarLoading] = useState(false);
    const [isHuntReportLoading, setIsHuntReportLoading] = useState(false);
    const [isIocReportLoading, setIsIocReportLoading] = useState(false);
    const [isOtxLoading, setIsOtxLoading] = useState(false);


    const loadOtxDataReport = useCallback(
        async () => {
            setIsOtxLoading(true);
            const data = await loadOtxData(ref.domain);
            if (data) {
                setOtxData(data);
            }
            setIsOtxLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const loadRegistrarData = useCallback(
        async () => {
            setIsRegistrarLoading(true);
            const { data } = await loadRegistrarLog({ domain: ref.domain });
            setRegistrarData(data && data.length > 0 ? data[0] : null);
            setIsRegistrarLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const loadHuntReports = useCallback(
        async () => {
            setIsHuntReportLoading(true);
            const { data } = await loadHuntReportLog({ domain: ref.domain });
            setHuntReports(data && data.length > 0 ?
                data.length === 1 ? data[0] : data :
                null);
            setIsHuntReportLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const loadIocReports = useCallback(
        async () => {
            setIsIocReportLoading(true);
            const { data } = await loadThreatFoxIoc({ domain: ref.domain });
            setIocReports(data && data.length > 0 ? data.length === 1 ? data[0] : data : null);
            setIsIocReportLoading(false);
        },
        // eslint-disable-next-line
        []
    );

    const tabs = useMemo(
        () => {
            const tabs = [];

            if (customData) {
                tabs.push({
                    key: "custom",
                    label: customData.name,
                    children: (
                        <RandomData
                            skipRootTabs
                            keyNames={customData.keyNames}
                            renderPath={customData.renderPath}
                            data={prepareForRandomData(customData.data)} />
                    )
                })
            }

            if (showRegistrarData) {
                let registrar, registrant, customer, reseller, misc;

                if (registrarData) {
                    registrar = { ...registrarData };
                    registrant = registrarData.registrant;
                    customer = registrarData.customer;
                    reseller = registrarData.reseller;
                    misc = registrarData.misc;
                    delete registrar.registrant;
                    delete registrar.customer;
                    delete registrar.id;
                    delete registrar.gen_creation_date;
                    delete registrar.reseller;
                    delete registrar.misc;
                }

                tabs.push({
                    key: "registrar",
                    label: "Registrar",
                    children: (
                        <RandomData
                            skipRootTabs
                            loading={isRegistarLoading}
                            keyNames={registrarKeyNames}
                            renderPath={registrarRenderPath}
                            data={registrar} />
                    )
                });
                tabs.push({
                    key: "registrant",
                    label: "Registrant",
                    children: (
                        <RandomData
                            skipRootTabs
                            loading={isRegistarLoading}
                            keyNames={registrarKeyNames}
                            renderPath={registrantRenderPath}
                            data={registrant} />
                    )
                });
                tabs.push({
                    key: "customer",
                    label: "Customer",
                    children: (
                        <RandomData
                            skipRootTabs
                            loading={isRegistarLoading}
                            keyNames={registrarKeyNames}
                            renderPath={registrantRenderPath}
                            data={customer} />
                    )
                });

                if (reseller) {
                    tabs.push({
                        key: "reseller",
                        label: "Reseller",
                        children: (
                            <RandomData
                                skipRootTabs
                                loading={isRegistarLoading}
                                keyNames={registrarKeyNames}
                                data={reseller} />
                        )
                    });
                }
                if (misc) {
                    tabs.push({
                        key: "misc",
                        label: "Misc",
                        children: (
                            <RandomData
                                skipRootTabs
                                loading={isRegistarLoading}
                                keyNames={registrarKeyNames}
                                data={misc} />
                        )
                    });
                }
            }

            if (showHuntReports) {
                tabs.push({
                    key: "hunt",
                    label: "Hunt report",
                    children: (
                        <RandomData
                            skipRootTabs
                            data={huntReports}
                            renderPath={renderHuntReportPath}
                            loading={isHuntReportLoading} />
                    )
                });
            }

            if (showIocReports) {
                tabs.push({
                    key: "ioc",
                    label: "IOC",
                    children: (
                        <RandomData
                            skipRootTabs
                            data={iocReports}
                            renderPath={defaultRenderPath}
                            loading={isIocReportLoading} />
                    )
                });
            }

            if (showOtx) {
                tabs.push({
                    key: "intelligence",
                    label: "Intelligence",
                    children: (
                        <div style={{ padding: 20, paddingTop: 0 }}>
                            <RandomData
                                data={otxData}
                                loading={isOtxLoading}
                                keyNames={keyNames} />
                        </div>
                    )
                });
            }

            if (showWebcheck) {
                tabs.push({
                    key: "webcheck",
                    label: "Scan",
                    children: (
                        <WebcheckTab
                            domain={domain}
                            active={currentTab === "webcheck"} />
                    )
                });
            }

            if (showScreenshot) {
                tabs.push({
                    key: "screenshot",
                    label: "Screenshot",
                    children: (
                        <ScreenshotTab
                            domain={domain}
                            active={currentTab === "screenshot"} />
                    )
                });
            }

            if (showHistory) {
                tabs.push({
                    key: "history",
                    label: "History",
                    children: (
                        <HistoryTab domain={domain} active={currentTab === "history"} />
                    )
                });
            }

            return tabs;
        },
        [registrarData, huntReports, iocReports, otxData,
            isOtxLoading, isRegistarLoading, isHuntReportLoading, isIocReportLoading,
            showRegistrarData, showHuntReports, showIocReports, showOtx,
            showHistory, showScreenshot,
            customData, showWebcheck, domain, currentTab]
    );

    const onTabChange = useCallback(
        (tab) => {
            setCurrentTab(tab);
            if (tab === "intelligence" && !otxData) {
                loadOtxDataReport();
            }
            else if (tab === "registrar" || tab === "registrant" || tab === "customer") {
                if (!registrarData) {
                    loadRegistrarData();
                }
            }
            else if (tab === "hunt") {
                if (!huntReports) {
                    loadHuntReports();
                }
            }
            else if (tab === "ioc") {
                if (!iocReports) {
                    loadIocReports();
                }
            }
        },
        [registrarData, huntReports, iocReports, otxData,
            loadOtxDataReport, loadHuntReports, loadIocReports, loadRegistrarData]
    );

    useUpdateEffect(
        () => {
            // console.log("on domain change", domain)
            // setRegistrarData(null);
            // setHuntReports(null);
            // setIocReports(null);
            // setOtxData(null);
        },
        [domain]
    );

    useEffect(
        () => {
            if ((!defaultActiveKey || defaultActiveKey === "registrar") && !registrarData) {
                loadRegistrarData();
            }
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Tabs
            items={tabs}
            size="small"
            onChange={onTabChange}
            defaultActiveKey={defaultActiveKey || tabs[0]?.key} />
    )
}

export default DomainExpandable