import moment from "moment"

export default function renderGMT(ts) {
    if (!ts) {
        return "";
    }
    const dt = new Date(ts);
    const year = dt.getFullYear();
    const thisYear = (new Date()).getFullYear();
    const tpl = year === thisYear ? "MMM D, HH:mm:ss" : "MMM D, YYYY, HH:mm:ss";
    const tsStr = moment(ts).utc().format(tpl);
    return tsStr;
}