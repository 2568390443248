import Page from "components/Page"
import ThreatTable from "components/table/Threat"
import { Typography } from "antd"

const { Title } = Typography;

function PageThreats() {
    return (
        <Page className="page-threatfox-ioc">
            <ThreatTable
                before={<Title level={1}>Threats</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                stateMode="both" />
        </Page>
    )
}

export default PageThreats