import { useCallback, useContext, useState } from "react"
import { useOn } from "@kuindji/observable-react"
import { Button, Flex, Modal, Spin, App as AntApp, Typography } from "antd"

import app from "appContext"
import AppContext from "context/AppContext"
import supabase from "lib/supabase/main"
import useQuery from "hooks/useQuery"

async function waitSome(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function loadKey({ userId = null, companyId = null }) {

    const req = supabase.from("api_key").select();

    if (userId) {
        req.eq("user_id", userId);
    }
    else if (companyId) {
        req.eq("company_id", companyId);
    }

    const { data } = await req;

    return { data: data?.[0] };
};

async function loadUsagePlan({ userId = null, companyId = null }) {
    const req = supabase.from("api_key_usage_plan").select();

    if (userId) {
        req.eq("user_id", userId);
    }
    else if (companyId) {
        req.eq("company_id", companyId);
    }

    const { data } = await req;

    return { data: data?.[0] };
}

async function createKey({ userId, companyId }, notification) {

    let up = await loadUsagePlan({ userId, companyId });
    let key = null;
    let tries = 0;

    if (!up.data) {
        notification.info({ message: "Creating usage plan..." });
        await supabase.from("api_key_usage_plan")
            .insert({ user_id: userId, company_id: companyId });
    }

    tries = 0;
    while (!up.data || !up.data.aws_usage_plan_id) {
        if (tries > 3) {
            throw new Error("Failed to create usage plan");
        }
        await waitSome(1000);
        up = await loadUsagePlan({ userId, companyId });
        tries++;
    }

    notification.info({ message: "Creating api key..." });
    await supabase.from("api_key")
        .insert({
            user_id: userId,
            company_id: companyId,
            usage_plan_id: up.data.aws_usage_plan_id
        });

    tries = 0;
    while (!key || !key.data || !key.data.aws_api_key_id) {
        if (tries > 3) {
            throw new Error("Failed to create api key");
        }
        await waitSome(1000);
        key = await loadKey({ userId, companyId });
        tries++;
    }

    return key.data;
}

async function deleteKey(key) {
    await supabase.from("api_key").delete().eq("id", key.id);
    await waitSome(1000);
    await supabase.from("api_key_usage_plan").delete()
        .eq("aws_usage_plan_id", key.usage_plan_id);
}

function ApiKeyForm({ userId, companyId }) {

    const { notification } = AntApp.useApp();
    const [creating, setCreating] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const { data: key, isLoading, refetch } = useQuery(
        loadKey,
        [userId, companyId],
        {
            initialData: null,
            params: {
                userId,
                companyId
            }
        }
    );

    const onCreateClick = useCallback(
        async () => {
            setCreating(true);
            try {
                await createKey({ userId, companyId }, notification);
            }
            catch (err) {
                notification.error({ message: err.message });
            }
            refetch();
            setCreating(false);
        },
        [refetch, userId, companyId, notification]
    );

    const onDeleteClick = useCallback(
        async () => {
            setDeleting(true);
            await deleteKey(key);
            refetch();
            setDeleting(false);
        },
        [refetch, key]
    );

    if (isLoading && key === null) {
        return (
            <Spin spinning />
        )
    }

    if (key) {
        return (
            <Flex vertical gap="0.5rem" justify="center">
                <Typography.Text copyable>{key.key}</Typography.Text>
                <Button
                    loading={deleting}
                    disabled={deleting}
                    onClick={onDeleteClick}
                    children="Delete key" />
            </Flex>
        )
    }
    else {
        return (
            <Flex vertical={false} justify="center">
                <Button
                    loading={creating}
                    disabled={creating}
                    size="large"
                    type="primary"
                    children="Create key"
                    onClick={onCreateClick} />
            </Flex>
        )
    }

}

function ApiKeyDialog() {

    const app = useContext(AppContext);
    const [options, setOptions] = useState({});
    const [open, setOpen] = useState(false);

    useOn(app, "app/api-key-dialog/options", setOptions);
    useOn(app, "app/api-key-dialog/open", setOpen);

    return (
        <Modal
            title={options.userId ? "User API key" : "Company API key"}
            open={open}
            closable
            centered
            maskClosable={false}
            destroyOnClose
            footer={null}
            onCancel={ApiKeyDialog.hide}>
            {open && <ApiKeyForm {...options} />}
        </Modal>
    )
}

ApiKeyDialog.show = (options) => {
    app.trigger("app/api-key-dialog/options", options);
    app.trigger("app/api-key-dialog/open", true);
};

ApiKeyDialog.hide = () => {
    app.trigger("app/api-key-dialog/open", false);
};


export default ApiKeyDialog