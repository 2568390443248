import { Input, Tooltip, Typography } from "antd"
import { useCallback, useContext, useMemo } from "react"

import { FormContext } from "lib/form/Form"

const { Paragraph } = Typography;

function InputContainer(props) {

    const form = useContext(FormContext);
    const { children, error,
        showTooltip = true,
        tooltip = null,
        InputComponent = Input,
        containerStyle,
        ignorePressEnter = false,
        ...rest } = props;

    const onPressEnter = useCallback(
        () => {
            form && form.trigger("submit");
        },
        [form]
    );

    const inputChildren = useMemo(
        () => {
            const input = children || <InputComponent
                status={error ? "error" : null}
                onPressEnter={ignorePressEnter ? null : onPressEnter}
                {...rest} />
            if (showTooltip) {
                return (
                    <Tooltip
                        title={tooltip || rest.placeholder}
                        placement={typeof showTooltip === "boolean" ? "left" : showTooltip}>
                        {input}
                    </Tooltip>
                )
            }
            else {
                return input;
            }
        },
        // eslint-disable-next-line 
        [rest, InputComponent, children, error, showTooltip, tooltip,
            onPressEnter, ignorePressEnter]
    );

    return (
        <div className="form-input-container" style={containerStyle}>
            {inputChildren}
            {error && <Paragraph type="danger">{error}</Paragraph>}
        </div>
    )
}

export default InputContainer