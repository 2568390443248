import { useCallback, useMemo } from "react"
import throttle from "lodash.throttle"

function useThrottledCallback(fn, deps, wait, options) {

    // eslint-disable-next-line
    const callback = useCallback(fn, deps);

    const thCallback = useMemo(
        () => throttle(callback, wait, options),
        [callback, wait, options]
    );

    return thCallback;
}

export default useThrottledCallback