import { useState } from 'react'
import MapGL, { Source, Layer } from '@urbica/react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MAPBOX_ACCESS_TOKEN } from "env"

import pointPaint from "lib/charts/pointPaint"
import useUpdateEffect from "hooks/useUpdateEffect"
import mapStyle from 'lib/charts/mapStyle'

function getPoint(longitude, latitude) {
    if (longitude && latitude) {
        return {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [
                            longitude,
                            latitude
                        ]
                    }
                }
            ]
        };
    }
    return {
        type: "FeatureCollection",
        features: []
    };
}

function getViewport(longitude, latitude) {
    return {
        latitude: longitude && latitude ? latitude : 0,
        longitude: longitude && latitude ? longitude : 0,
        zoom: 5
    }
}

function MapPoint({ longitude, latitude }) {

    const [geoData, setGeoData] = useState(() => getPoint(longitude, latitude));
    const [viewport, setViewport] = useState(() => getViewport(longitude, latitude));

    useUpdateEffect(
        () => {
            setGeoData(getPoint(longitude, latitude));
            setViewport(getViewport(longitude, latitude));
        },
        [longitude, latitude]
    );

    return (
        <MapGL
            className="map"
            mapStyle={mapStyle}
            accessToken={MAPBOX_ACCESS_TOKEN}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            onViewportChange={setViewport}
            scrollZoom={true}
            dragPan={true}
            viewportChangeMethod="flyTo">
            <Source
                id='points'
                type='geojson'
                data={geoData} />
            <Layer
                id='points-fills'
                type='circle'
                source='points'
                paint={pointPaint} />
        </MapGL>
    )
}

export default MapPoint