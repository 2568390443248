
import { useCallback } from "react";
import SelectorDialog from "./SelectorDialog"

import supabase from "lib/supabase/main"
import useAppKey from "hooks/useAppKey";

const columns = [
    {
        key: "name",
        dataIndex: "name",
        title: "Name"
    }
];



function WatchlistDialog(props) {
    const userProfile = useAppKey("userProfile");

    const loadWatchlists = useCallback(
        async ({ query }) => {
            const req = supabase.from("watchlist").select("id, name");
            req.eq("user_id", userProfile.id);
            if (query) {
                req.ilike("name", `%${query}%`);
            }
            const { data, error } = await req;
            return { data, error };
        },
        [userProfile]
    );

    return (
        <SelectorDialog
            {...props}
            columns={columns}
            loaderFn={loadWatchlists} />
    )
}

export default WatchlistDialog