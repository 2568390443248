import { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Modal, Tabs, Descriptions, Flex, DatePicker, Typography } from "antd"
//import isEmail from "validator/lib/isEmail"
import dayjs from "dayjs"

import { Form, FormContext, useFormFields, useForm } from "lib/form/Form"
import InputContainer from "components/form/InputContainer"
import MalwareDialog from "components/selector/MalwareDialog"
import MalwareFormDialog from "./MalwareForm"
import RegistrarSelect from "components/selector/RegistrarSelect"
import InputLoadingIndicator from "components/form/InputLoadingIndicator"

import required from "lib/form/validator/required"
import isDomain from "lib/form/validator/isDomain"
import { useOn } from "@kuindji/observable-react"
import useUpdateEffect from "hooks/useUpdateEffect"
import useThrottledCallback from "hooks/useThrottledCallback"
import InputArray from "components/form/InputArray"
import supabase from "lib/supabase/main"
import useKey from "hooks/useKey"
import useDictRef from "hooks/useDictRef"
import { ExportOutlined } from "@ant-design/icons"
import UrlButton from "components/UrlButton"


async function uniqueInHuntLog(value, _, values) {
    if (value === values.report?.domain) {
        return true;
    }
    const { data } = await supabase.from("hunt_report_log")
        .select("domain")
        .eq("domain", value);
    return data.length === 0;
}

async function uniqueInQueue(value, _, values) {
    if (value === values.report?.domain) {
        return true;
    }

    const { data } = await supabase.from("hunt_report_queue")
        .select("domain")
        .eq("domain", value);
    return data.length === 0;
}

async function loadDomainData(domain) {
    const { data: domains } = await supabase.from("domain")
        .select("*")
        .eq("domain", domain);

    return domains[0] || null;
}

async function loadRegistrarDataByName(name) {
    const { data } = await supabase.from("registrar")
        .select()
        .eq("name", name);

    return data[0] || null;
}

async function loadRegistrarDataById(id) {
    const { data } = await supabase.from("registrar")
        .select()
        .eq("id", id);

    return data[0] || null;
}


async function loadThreatByDomain(domain) {
    const { data: iocData } = await supabase.from("threatfox_ioc").select().eq("domain", domain);
    let malware = null;
    let evidence = null;
    if (iocData && iocData.length > 0) {
        const ioc = iocData[0];
        if (ioc.reference) {
            evidence = [ioc.reference];
        }
        const { data: malwareData } = await supabase.from("threat")
            .select()
            .eq("id", ioc.malware);
        if (malwareData && malwareData.length > 0) {
            malware = malwareData[0];

            if (malware.malpedia_data?.urls) {
                evidence = [...(evidence || []), ...malware.malpedia_data?.urls];
                evidence = evidence.filter((e, inx, self) => self.indexOf(e) === inx);
            }
        }
    }
    return { malware, evidence };
}


const fields = [
    {
        name: "domain",
        validator: [
            [required, "Please enter the domain name"],
            [isDomain, "Please enter a valid domain name"],
            [uniqueInHuntLog, "This domain already exists in Hunt Report log"],
            [uniqueInQueue, "This domain already exists in Hunt Report queue"]
        ]
    },
    {
        name: "registrar",
        default: null,
        validator: [
            //[ required, "Please enter the registrar" ]
        ]
    },
    {
        name: "registrarName"
    },
    {
        name: "domainType"
    },
    {
        name: "malwareId"
    },
    {
        name: "nameservers",
        default: []
    },
    {
        name: "threatName"
    },
    {
        name: "threatType"
    },
    {
        name: "threatDescription"
    },
    {
        name: "threatActor"
    },
    {
        name: "evidence",
        default: []
    },
    {
        name: "creationDate"
    },
    {
        name: "expirationDate"
    },
    {
        name: "registrantName"
    },
    {
        name: "registrantEmail"
    }
]

function getQueuePayload(form) {
    const payload = {};
    const {
        registrar,
        registrarName,
        domain,
        domainType,
        creationDate,
        expirationDate,
        registrantName,
        registrantEmail,
        nameservers,
        malwareId,
        threatName,
        threatType,
        threatDescription,
        threatActor,
        evidence,
        sourceId
    } = form.getData();

    const domainInfo = {};
    const threat = {};
    const registrant = {};

    payload.domain = domain;

    if (registrar) {
        payload.registrar_id = typeof registrar === "object" ?
            registrar.value :
            registrar;
    }
    else if (registrarName) {
        payload.registrar_info = {
            name: registrarName
        }
    }

    if (domainType) {
        domainInfo.type = domainType;
    }
    if (creationDate) {
        domainInfo.creation_date_gmt = creationDate.toISOString();
    }
    if (expirationDate) {
        domainInfo.expiry_date_gmt = expirationDate.toISOString();
    }
    if (nameservers && nameservers.length > 0) {
        const orig_ns = nameservers.filter(ns => !!ns);
        if (orig_ns.length > 0) {
            domainInfo.original_name_servers = orig_ns;
        }
    }

    if (registrantEmail) {
        registrant.email = registrantEmail;
    }
    if (registrantName) {
        registrant.name = registrantName;
    }

    if (malwareId) {
        payload.threat_id = malwareId;
    }

    if (threatName) {
        threat.name = threatName;
    }
    if (threatType) {
        threat.type = threatType;
    }
    if (threatDescription) {
        threat.description = threatDescription;
    }
    if (threatActor) {
        threat.actor = threatActor;
    }
    if (evidence && evidence.length > 0) {
        const ev = evidence.filter(e => !!e);
        if (ev.length > 0) {
            threat.evidence = ev;
        }
    }

    if (Object.keys(domainInfo).length > 0) {
        payload.domain_info = domainInfo;
    }
    if (Object.keys(registrant).length > 0) {
        payload.registrant_info = registrant;
    }
    if (Object.keys(threat).length > 0) {
        payload.threat_info = threat;
    }

    if (sourceId) {
        payload.source_id = sourceId;
    }

    return payload;
}

async function saveReport(form) {

    const payload = getQueuePayload(form);
    const report = form.get("report");
    if (report) {
        await supabase.from("hunt_report_queue")
            .update(payload)
            .eq("domain", report.domain);
    }
    else {
        await supabase.from("hunt_report_queue")
            .insert(payload);
    }
}


async function importReport(form, report) {

    /*if (report.registrar_id) {
        const { data } = await supabase.from("registrar").select().eq("id", report.registrar_id);
        if (data && data.length > 0) {
            registrar = data[0];
        }
    }*/

    form.batch(() => {
        form.set("mode", "edit-queue");
        form.set("report", report);
        form.set("domain", report.domain);
        form.set("malwareId", report.threat_id || null);
        form.set("registrar", report.registrar_id || null);
        form.set("sourceId", report.source_id || null);

        if (report.domain_info) {
            form.set("domainType", report.domain_info.type);
            form.set("nameservers", report.domain_info.original_name_servers || []);
            form.set("creationDate", report.domain_info.creation_date_gmt);
            form.set("expirationDate", report.domain_info.expiry_date_gmt);
        }

        if (report.threat_info) {
            form.set("evidence", report.threat_info.evidence || []);
            form.set("threatName", report.threat_info.name || "");
            form.set("threatType", report.threat_info.type || "");
            form.set("threatDescription", report.threat_info.description || "");
            form.set("threatActor", report.threat_info.actor || "");
        }

        if (report.registrar_info) {
            form.set("registrarName", report.registrar_info.name);
        }

        if (report.registrant_info) {
            form.set("registrantName", report.registrant_info.name || "");
            form.set("registrantEmail", report.registrant_info.email || "");
        }
    })

}



function DomainPage() {

    const form = useForm();
    const reportId = useKey("id", form);
    const [domainChecking, setDomainChecking] = useState(false);
    const [checkerCount, setCheckerCount] = useState(0);
    const { domain, domainError, domainChange,
        registrar, registrarError, registrarChange,
        registrarName, registrarNameChange,
        nameservers, nameserversChange,
        domainType, domainTypeChange,
        creationDate, creationDateChange,
        expirationDate, expirationDateChange,
        registrantEmailChange, registrantNameChange,
        malwareId, malwareIdChange } = useFormFields(
            "domain", "nameservers", "creationDate", "expirationDate", "registrar",
            "registrarName", "domainType", "registrantName", "registrantEmail",
            "malwareId"
        );

    const [domainChecked, setDomainChecked] = useState(false);

    const ref = useDictRef({ checkerCount, domain });

    const inputProps = useCallback(
        (inx) => {
            return {
                placeholder: "Nameserver " + (inx + 1)
            }
        },
        []
    );

    const onDomainChange = useThrottledCallback(
        async () => {

            setDomainChecking(true);
            setCheckerCount(prev => prev + 1);
            form.set("checkingDomain", true);
            const valid = await form.validate("domain", true);
            if (valid) {
                const domain = await loadDomainData(form.get("domain"));
                if (domain) {
                    if (domain.registrant?.name) {
                        registrantNameChange(domain.registrant.name || "");
                    }
                    if (domain.registrant?.email) {
                        registrantEmailChange(domain.registrant.email || "");
                    }
                    if (domain.creation_date) {
                        creationDateChange(dayjs(domain.creation_date));
                    }
                    if (domain.expiration_date) {
                        expirationDateChange(dayjs(domain.expiration_date));
                    }

                    form.set("sourceId", domain.source);

                    const registrar = domain.registrar_id ?
                        await loadRegistrarDataById(domain.registrar_id) :
                        await loadRegistrarDataByName(domain.registrar);

                    if (registrar) {
                        registrarChange({
                            value: registrar.id,
                            label: registrar.name
                        });
                    }
                    else {
                        registrarNameChange(domain.registrar);
                    }
                }

                if (!malwareId) {
                    const { malware, evidence } = await loadThreatByDomain(form.get("domain"));
                    if (malware) {
                        malwareIdChange(malware.id);
                        form.set("malware", malware);
                    }
                    if (evidence) {
                        const exEvidence = form.get("evidence");
                        let newEvidence = [...exEvidence, ...evidence];
                        newEvidence = newEvidence
                            .filter(e => !!e)
                            .filter((e, inx, self) => self.indexOf(e) === inx);
                        form.set("evidence", newEvidence);
                    }
                }
            }
            setCheckerCount(prev => prev - 1);

            if (ref.checkerCount - 1 === 0) {
                setDomainChecked(valid);
                setDomainChecking(false);
                form.set("checkingDomain", false);
            }
        },
        [],
        100,
        {
            trailing: true
        }
    );

    useOn(form, "domain", onDomainChange);

    return (
        <Flex vertical gap="small">
            <InputContainer
                error={domainError}
                placeholder="Domain"
                value={domain}
                onChange={domainChange}
                suffix={<InputLoadingIndicator loading={domainChecking} />} />

            {(domainChecked || reportId) &&
                <Fragment>
                    <Flex gap="small">
                        <InputContainer
                            containerStyle={{ flex: 1 }}
                            style={{ width: "100%" }}
                            value={registrar}
                            allowClear
                            InputComponent={RegistrarSelect}
                            placeholder="Registrar"
                            onChange={registrarChange}
                            error={registrarError} />
                        {!registrar &&
                            <InputContainer
                                showTooltip="right"
                                placeholder="Custom registrar"
                                value={registrarName}
                                onChange={registrarNameChange} />}
                    </Flex>


                    <InputContainer
                        placeholder="Domain type (C2, Payload, etc)"
                        value={domainType}
                        onChange={domainTypeChange} />
                    <InputContainer
                        placeholder="Creation date (GMT)"
                        showTime
                        bordered
                        changeOnBlur
                        showNow={false}
                        InputComponent={DatePicker}
                        value={creationDate}
                        allowClear
                        onChange={creationDateChange} />
                    <InputContainer
                        placeholder="Expiration date (GMT)"
                        showTime
                        bordered
                        changeOnBlur
                        showNow={false}
                        InputComponent={DatePicker}
                        value={expirationDate}
                        allowClear
                        onChange={expirationDateChange} />
                    <InputArray
                        InputComponent={InputContainer}
                        containerStyle={{ flex: 1 }}
                        values={nameservers}
                        placeholder="Nameserver"
                        onChange={nameserversChange}
                        inputProps={inputProps}
                        maxItems={5} />
                </Fragment>}
        </Flex>
    )
}


function RegistrantPage() {

    const {
        registrantName, registrantNameChange,
        registrantEmail, registrantEmailChange
    } = useFormFields("registrantName", "registrantEmail");

    return (
        <Flex vertical gap="small">
            <InputContainer
                placeholder="Registrant name"
                value={registrantName}
                onChange={registrantNameChange} />
            <InputContainer
                placeholder="Registrant email"
                value={registrantEmail}
                onChange={registrantEmailChange} />
        </Flex>
    )
}


function MalwarePage() {

    const form = useForm();
    const { malwareId, malwareIdChange,
        evidence, evidenceChange,
        threatName, threatNameChange,
        threatType, threatTypeChange,
        threatDescription, threatDescriptionChange,
        threatActor, threatActorChange } = useFormFields([
            "malwareId", "evidence",
            "threatName", "threatType",
            "threatDescription", "threatActor"
        ]);
    const [showDialog, setShowDialog] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [onMalwareCreateSuccess, setOnMalwareCreateSuccess] = useState(null);
    const [malware, setMalware] = useState(form.get("malware"));

    const ref = useDictRef({ malware });

    const onShowClick = useCallback(
        () => setShowDialog(true),
        []
    );

    const onCreateMalwareClick = useCallback(
        ({ onSuccess }) => {
            setOnMalwareCreateSuccess(() => onSuccess);
            setShowForm(true);
        },
        []
    );

    const onMalwareChange = useCallback(
        (m) => {
            malwareIdChange(m.id);
            setMalware(m);
        },
        // eslint-disable-next-line
        []
    );

    const onMalwareSelect = useCallback(
        (m) => {
            form.set("malware", m[0]);
        },
        // eslint-disable-next-line
        []
    );

    const onClearMalwareClick = useCallback(
        () => {
            malwareIdChange(null);
            setMalware(null);
        },
        // eslint-disable-next-line
        []
    );

    const onClose = useCallback(
        () => setShowDialog(false),
        []
    );

    const onFormClose = useCallback(
        () => {
            setShowForm(false);
            setOnMalwareCreateSuccess(null);
        },
        // eslint-disable-next-line
        []
    );

    const loadMalware = useCallback(
        async (id) => {
            const { data } = await supabase.from("threat").select().eq("id", id);
            if (data && data.length > 0) {
                //setMalware(data[0]);
                form.set("malware", data[0]);
            }
        },
        // eslint-disable-next-line
        []
    );

    const items = useMemo(
        () => {
            return [
                {
                    key: "id",
                    label: "ID",
                    children: malware?.id
                },
                {
                    key: "name",
                    label: "Name",
                    children: malware?.name
                },
                {
                    key: "type",
                    label: "Type",
                    children: malware?.type
                },
                {
                    key: "description",
                    label: "Description",
                    children: (
                        <Typography.Paragraph
                            ellipsis={{
                                rows: 5,
                                expandable: true,
                                symbol: "..."
                            }}>
                            {malware?.description}
                        </Typography.Paragraph>
                    )
                }
            ]
        },
        [malware]
    );

    useUpdateEffect(
        () => {
            if (!malwareId || (ref.malware && malwareId !== ref.malware.id)) {
                setMalware(null);
            }
            else if (malwareId && !ref.malware) {
                loadMalware(malwareId);
            }
        },
        [malwareId]
    );

    const evidenceInputProps = useCallback(
        (inx, value) => {
            if (value) {
                return {
                    suffix: (
                        <UrlButton
                            url={value}
                            size="small"
                            type="text"
                            icon={<ExportOutlined />} />
                    )
                }
            }
            return null;
        },
        []
    );

    useOn(form, "malware", onMalwareChange);


    return (
        <div className="scrollable" style={{ maxHeight: 400 }}>
            <Flex vertical gap="small">
                <Flex gap="small">
                    <Button
                        style={{ flex: 1 }}
                        type="dashed"
                        children="Select malware"
                        onClick={onShowClick} />
                    {malware &&
                        <Button
                            type="dashed"
                            children="Clear"
                            onClick={onClearMalwareClick} />}
                </Flex>
                {malware &&
                    <Descriptions
                        bordered
                        items={items}
                        column={1}
                        size="small" />}

                {!malware &&
                    <InputContainer
                        placeholder="Threat name"
                        value={threatName}
                        onChange={threatNameChange} />}
                <InputContainer
                    placeholder="Threat type"
                    value={threatType}
                    onChange={threatTypeChange} />
                <InputContainer
                    placeholder="Threat description"
                    value={threatDescription}
                    onChange={threatDescriptionChange} />
                <InputContainer
                    placeholder="Threat actor"
                    value={threatActor}
                    onChange={threatActorChange} />
                <InputArray
                    InputComponent={InputContainer}
                    containerStyle={{ flex: 1 }}
                    values={evidence}
                    inputProps={evidenceInputProps}
                    placeholder="Evidence (URL)"
                    onChange={evidenceChange} />

            </Flex>
            <MalwareDialog
                multiple={false}
                open={showDialog}
                onClose={onClose}
                onCreate={onCreateMalwareClick}
                onSelect={onMalwareSelect}
                returnObjects />
            <MalwareFormDialog
                onCreate={onMalwareCreateSuccess}
                open={showForm}
                onClose={onFormClose} />
        </div>
    )
}

function HuntReportForm({ onCancel, onSave, report, initialData }) {

    const form = useMemo(() => new Form(fields), []);
    const [submitting, setSubmitting] = useState(false);
    const checkingDomain = useKey("checkingDomain", form);

    const onSubmitClick = useCallback(
        async () => {

            if (submitting) {
                return;
            }

            setSubmitting(true);
            const valid = await form.validateAll();

            if (valid) {
                await saveReport(form);
                onSave && onSave();
            }

            setSubmitting(false);
        },
        [onSave, form, submitting]
    );

    const onCancelClick = useCallback(
        () => {
            form.resetAll();
            onCancel && onCancel();
        },
        [onCancel, form]
    );

    useOn(form, "submit", onSubmitClick);

    const items = useMemo(
        () => {
            return [
                {
                    key: "domain",
                    label: "Domain",
                    children: <DomainPage />
                },
                {
                    key: "malware",
                    label: "Threat",
                    children: <MalwarePage />
                },
                {
                    key: "registrant",
                    label: "Registrant",
                    children: <RegistrantPage />
                }
            ]
        },
        []
    );

    useEffect(
        () => {
            if (initialData) {
                form.set(initialData);
            }
            else if (report) {
                importReport(form, report);
            }
        },
        // eslint-disable-next-line
        []
    )

    return (
        <FormContext.Provider value={form}>
            <Flex vertical gap="middle">
                <Tabs items={items} />
                <Flex justify="right" gap="small">
                    <Button
                        type="text"
                        children="Cancel"
                        disabled={submitting}
                        onClick={onCancelClick} />
                    <Button
                        children="Save"
                        type="primary"
                        loading={submitting}
                        disabled={submitting || checkingDomain}
                        onClick={onSubmitClick} />
                </Flex>
            </Flex>
        </FormContext.Provider>
    )
}

function HuntReportFormDialog({ open, onClose, onSave, report, initialData }) {

    const onFormSave = useCallback(
        () => {
            onClose && onClose();
            onSave && onSave();
        },
        [onClose, onSave]
    );
    const onFormCancel = useCallback(
        () => {
            onClose && onClose();
        },
        [onClose]
    );

    return (
        <Modal
            title="Hunt report"
            open={open}
            closable={false}
            centered
            maskClosable={false}
            destroyOnClose
            footer={null}>
            <HuntReportForm
                report={report}
                onCancel={onFormCancel}
                onSave={onFormSave}
                initialData={initialData} />
        </Modal>
    )
}

export default HuntReportFormDialog