import { useCallback, useMemo, useState } from "react"
import { Table } from "antd"

import useQuery from "hooks/useQuery"
import { loadIpGeo, columns } from "api/ipGeo"

const PER_PAGE = 10;


function IpGeo({
    showPagination = true,
    onLoad,
    initialParams = {} }) {

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(PER_PAGE);

    const { data, isLoading, extraData, count } = useQuery(
        loadIpGeo,
        [page, pageSize, initialParams],
        {
            rowIdKey: "cidr",
            params: { page, pageSize, ...initialParams },
            initialData: [],
            onLoad
        }
    );
    const fetchedColumns = extraData?.columns || [];

    const onPaginationChange = useCallback(
        (page, pageSize) => {
            setPage(page - 1);
            setPageSize(pageSize);
        },
        []
    );

    const pagination = useMemo(
        () => {
            if (!showPagination || count <= PER_PAGE) {
                return false;
            }
            return {
                position: ["bottomCenter"],
                total: count,
                defaultPageSize: PER_PAGE,
                onChange: onPaginationChange,
                pageSize
            }
        },
        [count, onPaginationChange, showPagination, pageSize]
    )

    return (
        <div className="table-ip-geo">
            <Table
                sticky
                size="small"
                bordered
                loading={isLoading}
                dataSource={data}
                columns={columns.filter(c => (fetchedColumns || []).indexOf(c.dataIndex) !== -1)}
                rowKey="cidr"
                pagination={pagination} />
        </div>
    )
}

export default IpGeo