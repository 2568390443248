

import supabase from "lib/supabase/main"


export const columns = [
    {
        dataIndex: "id",
        key: "id",
        title: "ID",
    },
    {
        dataIndex: "name",
        key: "name",
        title: "Name"
    },
    {
        dataIndex: "type",
        key: "type",
        title: "Type"
    },
];


export async function loadThreat(options) {

    const {
        page = 0,
        pageSize = 20,
        queryBy,
        query
    } = options;

    const req = supabase.from("threat")
        .select("*", { count: "estimated" })
        .order("id", { ascending: true })
        .range(page * pageSize, (page * pageSize) + pageSize - 1);

    if (queryBy && query) {
        if (queryBy === "name") {
            req.or(`name.ilike.%${query}%, alias.ilike.%${query}%, id.eq.${query}`);
        }
        else if (queryBy === "description") {
            req.ilike("description", `%${query}%`);
        }
    }

    const { data, error, count } = await req;

    return {
        data,
        columns: columns.map(c => c.dataIndex),
        error,
        count
    };

}