

function color2decimal(color) {
    color = color.replace('#', "");
    if (color.length === 3) {
        color = color[0] + color[0] +
            color[1] + color[1] +
            color[2] + color[2];
    }
    let r = parseInt(color.substring(0, 2), 16);
    let g = parseInt(color.substring(2, 4), 16);
    let b = parseInt(color.substring(4, 6), 16);
    return [r, g, b];
}

function decimal2color(dec) {
    let r = dec[0].toString(16);
    let g = dec[1].toString(16);
    let b = dec[2].toString(16);
    if (r.length === 1) {
        r = '0' + r;
    }
    if (g.length === 1) {
        g = '0' + g;
    }
    if (b.length === 1) {
        b = '0' + b;
    }
    return r + g + b;
}

function colorBetween(startColor = "#000", endColor = "#fff", position = 0.0) {

    const w = position * 2 - 1;
    const w2 = (w + 1) / 2.0;
    const w1 = 1 - w2;

    const startDec = color2decimal(startColor);
    const endDec = color2decimal(endColor);

    if (position === 0) {
        return decimal2color(startDec);
    }
    else if (position === 1) {
        return decimal2color(endDec);
    }

    const resDec = [
        parseInt(startDec[0] * w1 + endDec[0] * w2),
        parseInt(startDec[1] * w1 + endDec[1] * w2),
        parseInt(startDec[2] * w1 + endDec[2] * w2)
    ];

    return decimal2color(resDec);
}

window.colorBetween = colorBetween;

export default colorBetween