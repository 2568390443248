
import Page from "components/Page"
import List from "components/watchlist/List"

import useAppKey from "hooks/useAppKey"


function Watchlists() {
    const userProfile = useAppKey("userProfile");

    return (
        <Page className="page-watchlists">
            <List
                userId={userProfile?.id}
                toolbarPortal="header"
                toolbarSize="large"
                stateMode="both"
                selectable />
        </Page>
    )
}

export default Watchlists