import { useCallback, useState } from "react"
import { Input, Button, Typography } from "antd"
import { SearchOutlined } from "@ant-design/icons"

import Page from "components/Page"
import RandomData from "components/RandomData"
import MapPoint from "components/MapPoint"

import useDictRef from "hooks/useDictRef"
import useDualState from "hooks/useDualState"
import supabase from "lib/supabase/main"
import loadOtxData, { keyNames } from "api/otx"
import { Portal } from "components/Portal"

const { Title } = Typography;


const params = [
    {
        name: "query"
    }
];


async function loadIocData(query) {
    const { data } = await supabase.from("threatfox_ioc").select().eq("ioc", query);
    return data;
}

async function loadRegistrarData(query) {
    const { data } = await supabase.from("domain").select().eq("domain", query);
    return data;
}

function renderBefore(path, data) {

    if (path === "geo" && data.longitude && data.latitude) {
        return (
            <MapPoint longitude={data.longitude} latitude={data.latitude} />
        )
    }

    return null;
}

function Otx() {

    const [data, setData] = useState(null);
    const { query, setQuery, apply } = useDualState({ params, mode: "both" })
    const [isLoading, setIsLoading] = useState(false);
    const ref = useDictRef({ query });

    const check = useCallback(
        async () => {
            setIsLoading(true);

            const [otxData, iocData, regData] = await Promise.all([
                loadOtxData(ref.query),
                loadIocData(ref.query),
                loadRegistrarData(ref.query)
            ]);

            const data = {
                ...otxData,
            };

            if (iocData && iocData.length > 0) {
                data.ioc = iocData;
            }
            if (regData && regData.length > 0) {
                data.registrar = regData;
            }

            setData(data);
            setIsLoading(false);
        },
        //eslint-disable-next-line
        []
    );

    const onQueryChange = useCallback(
        (e) => setQuery(e.target.value),
        [setQuery]
    );

    const onCheckClick = useCallback(
        () => {
            apply();
            check();
        },
        [check, apply]
    );

    const onKeyDown = useCallback(
        (e) => {
            if (e.key === "Enter") {
                onCheckClick();
            }
        },
        [onCheckClick]
    );

    return (
        <Page>
            <Portal host="header">
                <Title level={1}>Mythic Intelligence</Title>
                <div className="toolbar">
                    <Input
                        size="large"
                        allowClear
                        onKeyDown={onKeyDown}
                        prefix={<SearchOutlined />}
                        onChange={onQueryChange}
                        value={query} />
                    <Button
                        size="large"
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={onCheckClick}
                        children="Check" />
                </div>
            </Portal>
            <RandomData
                data={data}
                loading={isLoading}
                renderBefore={renderBefore}
                keyNames={keyNames} />
        </Page>
    )
}

export default Otx