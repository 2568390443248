import Page from "components/Page"
import ThreatFoxIoc from "components/table/ThreatFoxIoc"
import { Typography } from "antd"

const { Title } = Typography;

function ThreatFoxIocPage() {

    return (
        <Page className="page-threatfox-ioc">
            <ThreatFoxIoc
                before={
                    <Title level={1}>IOC Search</Title>
                }
                toolbarPortal="header"
                toolbarSize="large"
                stateMode="both" />
        </Page>
    )

}

export default ThreatFoxIocPage