import supabase from "./supabase/main"

export async function countWatchlistNotifications(app) {
    const userId = app.get("userId");
    if (userId) {

        const { data } = await supabase
            .from('watchlist_notification')
            .select('watchlist_id')
            .eq("user_id", userId)
            .eq("active", true)
            .eq("disabled", false);

        const count = data.length;

        app.set("watchlistNotificationsCount", count);
    }
}

export async function countUserNotifications(app) {
    const userId = app.get("userId");
    if (userId) {

        const { data } = await supabase
            .from('user_notification')
            .select('id, type')
            .eq("user_id", userId)
            .eq("active", true);

        const count = data.length;
        const expiredDomainsCount = data.filter(n => n.type === "domain_expired").length;

        app.set("userNotificationsCount", count);
        app.set("expiredDomainsNotificationsCount", expiredDomainsCount);
    }
}