import isScalar from "is-scalar"


function arrayFilter(data, filter, path) {
    return data.filter((item, index) => {

        if (isScalar(item)) {
            return filter(path + "/*", item, (newItem) => {
                data[index] = newItem;
            });
        }

        if (Array.isArray(item)) {
            item = arrayFilter(item, filter, path + "/*");
        }
        else {
            item = objectFilter(item, filter, path + "/*");
        }

        return filter(path + "/*", item, (newItem) => {
            data[index] = newItem;
        });
    });
}

function objectFilter(data, filter, path = "") {

    const keys = Object.keys(data);
    const newData = {};

    keys.forEach(key => {

        let value;

        if (isScalar(data[key])) {
            value = data[key];
        }
        else if (Array.isArray(data[key])) {
            value = arrayFilter(data[key], filter, path + "/" + key);
        }
        else {
            value = objectFilter(data[key], filter, path + "/" + key);
        }

        let replace = false;

        const replacer = (newValue) => {
            replace = true;
            newData[key] = newValue;
        };

        if (filter(path + "/" + key, value, replacer)) {
            if (!replace) {
                newData[key] = value;
            }
        }
    });

    return newData;
}

export default objectFilter