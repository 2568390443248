import { useContext, useCallback, useState } from "react"

import useAppKey from "./useAppKey"
import AppContext from "context/AppContext"
import supabase from "lib/supabase/main"
import useDictRef from "./useDictRef";


function useSettings() {
    const userSettings = useAppKey("userSettings");
    const app = useContext(AppContext);
    const [saving, setSaving] = useState(false);

    const ref = useDictRef({ userSettings, app });

    const updateSettings = useCallback(
        async (updates) => {

            if (!ref.userSettings?.id) {
                console.error("userSettings.id is missing");
                return;
            }

            setSaving(true);

            await supabase
                .from("user_settings")
                .update(updates)
                .eq("id", ref.userSettings.id);


            ref.app.once("settings/loaded", () => setSaving(false));
            ref.app.trigger("settings/update");
        },
        // eslint-disable-next-line
        []
    );

    return { userSettings, saving, updateSettings };
}

export default useSettings