
import prepareImgInfo from "./info"

function preloadImages(srcs, resolveWithInfo = true) {
    let promises = [];

    srcs.forEach(function (src) {
        promises.push(new Promise((resolve, reject) => {
            let img = new Image();
            img.onload = () => resolve(resolveWithInfo ? prepareImgInfo(img) : null);
            img.onerror = () => resolve(null);
            img.src = src;
        }));
    });

    return Promise.allSettled(promises)
        // remove failed images
        .then(results => results.map(r => r.value))
        .then(imgs => imgs.filter(img => !!img));
}

export default preloadImages