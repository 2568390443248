import Page from "components/Page"
import TarpitLogTable from "components/table/TarpitLog"
import { Typography } from "antd"

const { Title } = Typography;

const params = {
    matchesDns: true,
    withCompany: true,
    withPayload: true,
    withHeaders: true,
    withThreat: true
}


function TarpitPlusDns() {
    return (
        <Page className="page-dns-log">
            <TarpitLogTable
                before={<Title level={1}>Tarpit log + DNS</Title>}
                toolbarPortal="header"
                toolbarSize="large"
                initialParams={params}
                stateMode="both" />
        </Page>
    )
}

export default TarpitPlusDns