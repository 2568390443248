import supabase from "./supabase/main";

const backendUrl = `https://yg6jnmgn67.execute-api.us-east-1.amazonaws.com/live`;

const backend = {

    async get(path, options = {}) {
        const { data } = await supabase.auth.getSession();
        const jwt = data?.session?.access_token;
        if (!jwt) {
            throw new Error("User session is empty");
        }
        const url = new URL(`${backendUrl}${path}`);
        if (options.searchParams) {
            Object.keys(options.searchParams).forEach(key => {
                url.searchParams.append(key, options.searchParams[key]);
            })
            delete options.searchParams;
        }
        return fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${jwt}`
            },
            ...options
        }).then(res => res.json());
    },

    async post(path, options = {}) {
        const { data } = await supabase.auth.getSession();
        const jwt = data?.session?.access_token;
        if (!jwt) {
            throw new Error("User session is empty");
        }
        const headers = {
            "Authorization": `Bearer ${jwt}`
        }
        if (options.body && typeof options.body !== "string") {
            options.body = JSON.stringify(options.body);
            headers["Content-type"] = "application/json";
        }
        return fetch(`${backendUrl}${path}`, {
            method: "POST",
            headers,
            ...options
        }).then(res => res.json());
    }
}

const aws = {
    backend
};

window.aws = aws;

export default aws;