import { Table, Typography, Spin } from "antd";
import ThreatName from "components/ThreatName";
import useQuery from "hooks/useQuery";
import formatNumber from "lib/format/number";
import supabase from "lib/supabase/main";

const columns = [
    {
        key: "threat",
        dataIndex: "threat",
        title: "Threat",
        render: (threat) => {
            return (
                <ThreatName id={threat.id} name={threat.name} />
            )
        }
    },
    {
        key: "dns_log_counter",
        dataIndex: "dns_log_counter",
        title: "Dns requests",
        render: formatNumber
    },
    {
        key: "tarpit_log_counter",
        dataIndex: "tarpit_log_counter",
        title: "Data requests",
        render: formatNumber
    }
];

async function loadTopThreats({ limit = 10 }) {
    const { data: { rows, columns = [] }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "top_threats",
            options: {
                limit
            },
            replica: true
        },
        method: "POST"
    });

    const threatIds = rows.map(r => r.threat_id);
    const { data: threats } = await supabase.from("threat")
        .select("*")
        .in("id", threatIds);

    rows.forEach(row => {
        const threat = threats.find(t => t.id === row.threat_id);
        if (threat) {
            row.threat = threat;
        }
    });

    return {
        data: rows,
        columns,
        error
    };
}

function TopThreats({ title = "Top threats", showToolbar = true }) {

    const { data, isLoading } = useQuery(
        loadTopThreats,
        [],
        {}
    );

    return (
        <div className="small-report report-top-threat-domains">
            {showToolbar &&
                <div className="toolbar">
                    {title && <Typography.Title level={4}>{title}</Typography.Title>}
                </div>}
            <Spin spinning={isLoading}>
                <Table
                    className="table-transparent-header"
                    sticky
                    size="small"
                    columns={columns}
                    dataSource={data}
                    loading={isLoading}
                    rowKey="threat_id"
                    pagination={false} />
            </Spin>
        </div>
    );

}

export default TopThreats