import moment from 'moment';
import { extendMoment } from "moment-range"
const mrange = extendMoment(moment);

function getDateRange(period) {
    let start;
    let end;

    switch (period) {
        case "last7": {
            start = moment().subtract(7, 'days');
            end = moment();
            break;
        }
        case "last30": {
            start = moment().subtract(30, 'days');
            end = moment();
            break;
        }
        case "thisMonth": {
            start = moment().startOf("month");
            end = moment().endOf("month");
            break;
        }
        case "lastMonth": {
            start = moment().subtract(1, "month").startOf("month");
            end = moment().subtract(1, "month").endOf("month");
            break;
        }
        default: {
            return [];
        }
    }

    return Array.from(mrange.range(start.toDate(), end.toDate()).by("day"));
}

function fillDates(period, data, field, format, empty) {
    const range = getDateRange(period).map(d => format(d));
    return range.map(d => data.find(row => format(moment(row[field])) === d) || { ...empty, [field]: d });
}

export default fillDates