import supabase from "lib/supabase/main"

import CompanyLink from "components/link/Company"

import renderGMT from "lib/renderGMT"
import { data2csv } from "lib/csv"
import formatNumber from "lib/format/number"

export const columns = [
    /*{
        dataIndex: "ip_start",
        key: "ip_start",
        title: "IP Start"
    },
    {
        dataIndex: "ip_end",
        key: "ip_end",
        title: "IP End"
    },
    {
        dataIndex: "asn",
        key: "asn",
        title: "ASN"
    },*/
    {
        dataIndex: "name",
        key: "name",
        title: "Name",
        render: (name, row) => <CompanyLink id={row.id} name={name} />
    },
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain"
    },
    {
        dataIndex: "type",
        key: "type",
        title: "Type"
    },
    {
        dataIndex: "cidrs",
        key: "cidrs",
        title: "CIDR",
        renderCsv: value => {
            if (!value || value.length === 0) {
                return "";
            }
            return value.map(c => c.cidr).join(", ")
        },
        render: (value, row) => {
            if (!value || value.length === 0) {
                return "";
            }
            const len = row.cidrs_count;
            return value[0].cidr + (len > 1 ? " ...+" + (len - 1) : "")
        }
    },
    {
        dataIndex: "cidrs_count",
        key: "cidrs_count",
        title: "CIDRs"
    },
    {
        dataIndex: "dns_log_last_match",
        key: "dns_log_last_match",
        title: "Last dns match (GMT)",
        render: time => renderGMT(time)
    },
    {
        dataIndex: "dns_log_counter",
        key: "dns_log_counter",
        title: "Dns matches",
        render: formatNumber
    },
    {
        dataIndex: "tarpit_log_last_match",
        key: "tarpit_log_last_match",
        title: "Last tarpit match (GMT)",
        render: time => renderGMT(time)
    },
    {
        dataIndex: "tarpit_log_counter",
        key: "tarpit_log_counter",
        title: "Tarpit matches",
        render: formatNumber
    },
]

export async function loadIpRegistryLog(options = {}) {

    const {
        queryBy,
        query,
        withCidrs = false,
        withCidrsCount = false,
        //withCount = false,
        withCounters = false,
        cidr,
        ip,
        id,
        page = 0,
        pageSize = 20,
        csv = false,
        single = false
    } = options;

    const params = {
        offset: csv ? 0 : page * pageSize,
        limit: csv ? 1000 : ((page + 1) * pageSize),
        queryBy,
        query,
        withCidrs: withCidrs === true || (withCidrsCount === true && csv === true),
        withCidrsCount,
        //withCount,
        withCounters,
        cidr,
        ip,
        id
    };

    const { data: { rows, columns: dataColumns, count = 0 }, error } = await supabase.functions.invoke("query", {
        body: {
            table: "entity",
            options: params,
            replica: true
        },
        method: "POST"
    });

    const hasMore = rows.length >= pageSize;

    if (csv) {
        const text = data2csv(
            dataColumns.filter(c => c !== "id").map(key => columns.find(c => c.dataIndex === key)),
            rows
        );
        return text;
    }

    if (single) {
        return rows[0];
    }
    return { data: rows, columns: dataColumns, count: parseInt(count), error, hasMore };
}