
const b64 = (e, resolve) => {
    let mime,
        dataUrl = e.target.result,
        data = dataUrl.replace(/data:(image\/[^;]+);base64,/i,
            function (match, m) {
                mime = m;
                return "";
            });
    resolve({ mime, data });
};


function readInputFile(file, mode = "base64") {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onerror = reject;

        if (mode === "base64") {
            reader.onload = e => b64(e, resolve);
            reader.readAsDataURL(file);
        }
        else if (mode === "buffer") {
            reader.onload = () => resolve(reader.result);
            reader.readAsArrayBuffer(file);
        }
    });
}

export default readInputFile