
import renderGMT from "lib/renderGMT"
import supabase from "lib/supabase/main"

import { Link } from "react-router-dom"
import IpLink from "components/link/Ip"
import { Fragment } from "react";
import ThreatName from "components/ThreatName";

const PER_PAGE = 20;

export const columns = [
    {
        dataIndex: "ioc",
        key: "ioc",
        title: "IOC",
        render: (value, row) => {
            switch (row.ioc_type) {
                case "ip:port": {
                    const ip = value.split(":").shift();
                    return (
                        <IpLink ip={ip} children={value} />
                    )
                }
                case "domain": {
                    return (
                        <Link to={`/reports/domain/${value}`} children={value} />
                    )
                }
                default: {
                    return value;
                }
            }
        }
    },
    {
        dataIndex: "ioc_type",
        key: "ioc_type",
        title: "IOC type"
    },
    {
        dataIndex: "threat_type",
        key: "threat_type",
        title: "Threat type"
    },
    {
        dataIndex: "malware_printable",
        key: "malware_printable",
        title: "Malware",
        render: (value, row) => {
            return (
                <Link
                    to={`/admin/tools/ioc?queryBy=malware&query=${value}`}
                    children={<ThreatName id={row.malware} name={value} />} />
            )
        }
    },
    {
        dataIndex: "confidence_level",
        key: "confidence_level",
        title: "Confidence"
    },
    {
        dataIndex: "first_seen_at",
        key: "first_seen_at",
        title: "First seen at (GMT)",
        render: (value) => value ? renderGMT(value) : ""
    },
];

export const expandedColumns = [
    {
        dataIndex: "tags",
        key: "tags",
        title: "Tags",
        render: (value) => {
            const tags = (value || "").split(",").map(t => t.trim());
            if (tags.length > 0) {
                return (
                    <>
                        {tags.map((t, inx) => (
                            <Fragment key={inx}>
                                {inx > 0 && ", "}
                                <Link
                                    to={`/admin/tools/ioc?queryBy=tags&query=${t}`}
                                    children={t} />
                            </Fragment>
                        ))}
                    </>
                )
            }
            return "";
        }
    },
    {
        dataIndex: "reporter",
        key: "reporter",
        title: "Reporter"
    },
    {
        dataIndex: "reference",
        key: "reference",
        title: "Reference",
        render: (link) => {
            if (link) {
                return (
                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
                )
            }
            return "";
        }
    },

    {
        dataIndex: "last_seen_at",
        key: "last_seen_at",
        title: "Last seen at  (GMT)",
        render: (value) => value ? renderGMT(value) : ""
    }
]

export async function loadThreatFoxIoc(options) {

    const {
        page = 0,
        iocType,
        threatType,
        queryBy,
        query,
        domain,
        ip
    } = options;

    const req = supabase.from("threatfox_ioc")
        .select("*", { count: "estimated" })
        .order("first_seen_at", { ascending: false })
        .range(page * PER_PAGE, (page * PER_PAGE) + PER_PAGE - 1);

    if (iocType) {
        req.eq("ioc_type", iocType);
    }
    if (threatType) {
        req.eq("threat_type", threatType);
    }
    if (domain) {
        req.eq("domain", domain);
    }
    if (ip) {
        req.eq("ip", ip);
    }
    if (queryBy && query) {
        if (queryBy === "ioc") {
            req.ilike("ioc", `%${query}%`);
        }
        else if (queryBy === "malware") {
            req.ilike("malware_printable", `%${query}%`);
        }
        else if (queryBy === "tags") {
            req.ilike("tags", `%${query}%`);
        }
    }

    const { data, error, count } = await req;

    return {
        data,
        columns: columns.map(c => c.dataIndex),
        error,
        count,
        hasMore: (page * PER_PAGE + PER_PAGE) < count
    };

}