import { useState, useEffect, useCallback } from "react"
import { Typography, Alert, Input, Button, Spin } from "antd"

import Page from "components/Page"

import supabase from "lib/supabase/main"
import useSwallowEventCallback from "hooks/useSwallowEventCallback";


async function removeUnverified() {
    const { data, error } = await supabase.auth.mfa.listFactors();
    if (error) {
        console.error(error);
        return;
    }

    for (const f of data.all) {
        if (f.status === "unverified") {
            try {
                await supabase.auth.mfa.unenroll({ factorId: f.id })
            }
            catch (err) {
                console.error(err);
            }
        }
    }
}


function MFAEnroll({ onEnrolled }) {
    const [factorId, setFactorId] = useState('');
    const [qr, setQR] = useState(''); // holds the QR code image SVG
    const [secret, setSecret] = useState('');
    const [showSecret, setShowSecret] = useState(false);
    const [verifyCode, setVerifyCode] = useState(''); // contains the code entered by the user
    const [error, setError] = useState(''); // holds an error message
    const [loading, setLoading] = useState(false);

    const onEnableClick = useCallback(
        async () => {
            setError("");
            setLoading(true);
            const challenge = await supabase.auth.mfa.challenge({ factorId });

            if (challenge.error) {
                setError(challenge.error.message);
                setLoading(false);
                return;
            }

            const challengeId = challenge.data.id;

            const verify = await supabase.auth.mfa.verify({
                factorId,
                challengeId,
                code: verifyCode,
            });

            if (verify.error) {
                setError(verify.error.message);
                setLoading(false);
                return;
            }

            supabase.auth.refreshSession();
            setLoading(false);
            onEnrolled && onEnrolled();
        },
        [onEnrolled, factorId, verifyCode]
    );

    const init = useCallback(
        async () => {
            setLoading(true);
            await removeUnverified();

            const { data, error } = await supabase.auth.mfa.enroll({ factorType: 'totp', })

            if (error) {
                setError(error);
                return;
            }

            setFactorId(data.id);

            // Supabase Auth returns an SVG QR code which you can convert into a data
            // URL that you can place in an <img> tag.
            setQR(data.totp.qr_code);
            setSecret(data.totp.secret);
            setLoading(false);
        },
        []
    );

    const handleInputChange = useCallback(
        (e) => setVerifyCode(e.target.value.trim()),
        []
    );

    const onShowSecretClick = useSwallowEventCallback(
        () => setShowSecret(true),
        []
    );

    useEffect(
        () => {
            init();
        },
        // eslint-disable-next-line
        []
    );

    return (
        <Page className="page-mfa-enroll">
            <Typography.Title level={1}>Setup MFA</Typography.Title>
            {error ?
                <Alert
                    message={error}
                    type="error"
                    showIcon /> :
                <Alert
                    message="Scan QR code with your Authenticator App and enter the code."
                    type="info"
                    showIcon />}

            <Spin spinning={!qr}>
                <div className="page-mfa-enroll-qr-container">
                    {qr && <img
                        alt="MFA QR"
                        src={qr}
                        className="page-mfa-enroll-qr" />}
                </div>
                {secret &&
                    <div className="page-mfa-enroll-secret">
                        {showSecret ?
                            secret :
                            <a onClick={onShowSecretClick} href="/#">Show secret</a>}
                    </div>}
            </Spin>

            <Input
                size="large"
                disabled={!qr}
                value={verifyCode}
                onChange={handleInputChange} />

            <Button
                size="large"
                loading={loading}
                disabled={loading || !qr}
                onClick={onEnableClick}
                children="Enable" />
        </Page>
    )
}

export default MFAEnroll