import { Link } from "react-router-dom"
import { Flex, Tooltip } from "antd"

import IpLink from "components/link/Ip"
import { CheckOutlined, SafetyOutlined } from "@ant-design/icons"

import renderGMT from "lib/renderGMT"
import supabase from "lib/supabase/main"
import { data2csv } from "lib/csv"
import CountryName from "components/CountryName"
import ThreatName from "components/ThreatName"


const PER_PAGE = 50;

export const columns = [
    {
        dataIndex: "domain",
        key: "domain",
        title: "Domain",
        render: (domain, row) => (
            <Flex gap="small">
                <Link to={`/reports/domain/${domain}`} children={domain} />
                {row.exists_in_queue ?
                    <Tooltip title="Submitted to queue"><CheckOutlined /></Tooltip> :
                    row.exists_in_reports ?
                        <Tooltip title="Hunted down"><SafetyOutlined /></Tooltip> :
                        null}
            </Flex>
        )
    },
    {
        dataIndex: "source",
        key: "source",
        title: "Source"
    },
    {
        dataIndex: "ioc_type",
        key: "ioc_type",
        title: "IOC type"
    },
    {
        dataIndex: "threat_type",
        key: "threat_type",
        title: "Threat type"
    },
    {
        dataIndex: "malware_printable",
        key: "malware_printable",
        title: "Malware",
        render: (name, row) => {
            return (
                <ThreatName id={row.malware} name={name} />
            )
        }
    },
    {
        dataIndex: "confidence_level",
        key: "confidence_level",
        title: "Confidence"
    },
    {
        dataIndex: "country_code",
        key: "country_code",
        title: "Country",
        render: (code) => <CountryName country={code} />
    },
    {
        dataIndex: "first_seen_at",
        key: "first_seen_at",
        title: "First seen at (GMT)",
        render: renderGMT
    },
];

export const expandedColumns = [
    {
        dataIndex: "tags",
        key: "tags",
        title: "Tags"
    },
    {
        dataIndex: "registrar",
        key: "registrar",
        title: "Registrar"
    },
    {
        dataIndex: "status",
        key: "status",
        title: "Current status"
    },
    {
        dataIndex: "registration_ip",
        key: "registration_ip",
        title: "Registration ip",
        render: value => <IpLink ip={value} />
    },

    {
        dataIndex: "reference",
        key: "reference",
        title: "Reference",
        render: (link) => {
            if (link) {
                return (
                    <a href={link} target="_blank" rel="noreferrer">{link}</a>
                )
            }
            return "";
        }
    },
    {
        dataIndex: "creation_date_gmt",
        key: "creation_date_gmt",
        title: "Registration date (GMT)",
        render: renderGMT
    },

    {
        dataIndex: "first_seen_delay",
        key: "first_seen_delay",
        title: "Days since registration"
    },
]

export async function loadRegistrarHunt(options) {

    const {
        page = 0,
        pageSize = PER_PAGE,
        threatType,
        country,
        queryBy,
        query,
        source,
        csv = false,
    } = options;

    const params = {
        withCount: true,
        offset: csv ? 0 : page * pageSize,
        limit: pageSize !== false ? csv ? 1000 : pageSize : null,//((page + 1) * pageSize) : pageSize,
        threatType,
        country,
        queryBy,
        source,
        query: query ? query.trim() : null
    };

    const { data: { rows, columns = [], count = 0 }, error } = await supabase.functions.invoke("query", {
        body: {
            report: "registrar_hunt",
            options: params
        },
        method: "POST"
    });

    columns.push("exists_in_queue");
    columns.push("exists_in_reports");

    if (rows && rows.length > 0) {
        const domains = rows.map(r => r.domain);
        if (domains.length > 0) {
            const [{ data: queue }, { data: reports }] = await Promise.all([
                await supabase.from("hunt_report_queue").select("domain").in("domain", domains),
                await supabase.from("hunt_report_log").select("domain").in("domain", domains)
            ]);

            if (queue && queue.length > 0) {
                queue.forEach(q => {
                    const row = rows.find(row => row.domain === q.domain);
                    if (row) {
                        row.exists_in_queue = true;
                    }
                })
            }
            if (reports && reports.length > 0) {
                reports.forEach(r => {
                    const row = rows.find(row => row.domain === r.domain);
                    if (row) {
                        row.exists_in_reports = true;
                    }
                })
            }
        }
    }

    if (csv) {
        const csvColumns = columns.filter(c => c !== "id" && c !== "registrant" && c !== "customer");
        if (rows.length > 0) {
            const registrant = rows[0].registrant;
            const customer = rows[0].customer;
            Object.keys(registrant).forEach(key => {
                csvColumns.push("registrant_" + key);
            });
            Object.keys(customer).forEach(key => {
                csvColumns.push("customer_" + key);
            });
            rows.forEach(row => {
                Object.keys(row.registrant).forEach(key => {
                    row["registrant_" + key] = row.registrant[key];
                })
                Object.keys(row.customer).forEach(key => {
                    row["customer_" + key] = row.customer[key];
                })
            });
        }
        const text = data2csv(csvColumns, rows);
        return text;
    }

    return {
        data: rows,
        columns,
        error,
        count,
        hasMore: (page * PER_PAGE + PER_PAGE) < count
    };

}