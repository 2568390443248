import { Button, Popover } from "antd"
import AppContext from "context/AppContext";
import { useCallback, useContext, useMemo } from "react"
import { Link } from "react-router-dom"


function IpLink({ ip, companyId = null, children }) {

    const app = useContext(AppContext);

    const onAddToWatchlist = useCallback(
        () => {
            app.trigger("add-ips-to-watchlist", [ip]);
        },
        [app, ip]
    );

    const to = useMemo(
        () => {
            if (companyId) {
                return `/company/${companyId}/ip/${ip}`
            }
            return `/reports/ip/${ip}`
        },
        [ip, companyId]
    );

    return (
        <Popover
            mouseEnterDelay={0.5}
            content={<Button children="Add to watchlist" onClick={onAddToWatchlist} />}>
            <Link to={to}>{children || ip}</Link>
        </Popover>
    )
}

export default IpLink