import { useCallback } from "react";
import { useSearchParams } from "react-router-dom"

function useSearchParam(name, defaultValue = undefined) {

    const [searchParams, setSearchParams] = useSearchParams();
    const setParam = useCallback(
        (value) => {
            const sp = new URLSearchParams(searchParams);
            sp.set(name, value);
            setSearchParams(sp);
        },
        [searchParams, name, setSearchParams]
    );

    const value = searchParams.get(name);
    return [
        value === undefined || value === null ? defaultValue : value,
        setParam
    ]
}

export default useSearchParam