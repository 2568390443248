import { Auth } from "@supabase/auth-ui-react"
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useCallback, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { Checkbox } from "antd"

import { ReactComponent as Logo } from "svg/logo.svg"

import supabase from "lib/supabase/main"
import useAntdTheme from "hooks/useAntdTheme"
import useHtmlClass from "hooks/useHtmlClass"

function AuthView() {

    useHtmlClass("html-auth-view");
    const location = useLocation();
    const antdTheme = useAntdTheme();
    const [tcOk, setTcOk] = useState(false);

    const theme = useMemo(
        () => {
            return {
                default: {
                    fonts: {
                        bodyFontFamily: "Titillium Web",
                        buttonFontFamily: "Titillium Web",
                        inputFontFamily: "Titillium Web",
                        labelFontFamily: "Titillium Web"
                    },
                    colors: {
                        brand: antdTheme.colorPrimary,
                        brandAccent: antdTheme.colorPrimaryHover,
                        brandButtonText: "white",
                        defaultButtonBackground: antdTheme.colorPrimary,
                        defaultButtonBackgroundHover: antdTheme.colorPrimaryHover,
                        defaultButtonText: "white",
                        inputText: "white"
                    }
                }
            }
        },
        [antdTheme]
    );

    const onTcChange = useCallback(
        (e) => setTcOk(e.target.checked),
        []
    );

    const appearance = useMemo(
        () => ({
            theme: ThemeSupa,
            variables: theme
        }),
        [theme]
    );

    return (
        <div className={"auth-view " + (!tcOk ? "auth-tc-not-ok" : "")}>
            <Logo />
            <Auth
                redirectTo={window.location.origin + location.pathname}
                socialLayout="1"
                supabaseClient={supabase}
                appearance={appearance}
                providers={[]}
                children={
                    <Checkbox
                        checked={tcOk}
                        onChange={onTcChange}>
                        By checking this box, you are agreeing to our &nbsp;
                        <a href="https://www.vigilocity.com/terms-conditions"
                            target="_blank"
                            rel="noreferrer">
                            terms of service
                        </a> &nbsp;and&nbsp;
                        <a href="https://www.vigilocity.com/privacy-policy"
                            target="_blank"
                            rel="noreferrer">
                            privacy policy</a>.
                    </Checkbox>
                } />
        </div>
    )
}

export default AuthView